import { Box, Grid2, Stack, Typography } from '@mui/material'
import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Buttons from './button';
import { formatAmount } from '../utils/format-amount';
import dayjs from 'dayjs';
import { t } from 'i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import theme from '../theme';


function CompleteBooking({bookingDetails,confirmBooking,confirmBookingLoader}) {
  const Date = bookingDetails?.selectedDate;
  const selectedDate = dayjs(Date).format('DD/MM-YY');
  const time = bookingDetails?.selectedSlot.replace(':','.');


  return (
    <Box flex={1} sx={{pl:0, pr:0, ml:0, mr:0,mt:0}}>
  
      <Typography
                sx={{ fontWeight: 'bold', color: 'primary.text', paddingBottom: '30px',  textAlign: 'left',  fontSize:{xs:'1.1rem'}}}>
                {t('Bookingdetails')}
            </Typography>

    <Stack flex={1} flexDirection={'column'} justifyContent={'flex-start'} >

    
        <Typography ><strong>{t('Service')} </strong>{bookingDetails?.selectedService?.name} </Typography>
      
        <Typography sx={{mt:1}}><strong>{t('Employee')} </strong>{bookingDetails?.selectedEmployee?.name} </Typography>
      
        <Typography sx={{mt:1}}><strong>{t('Booking time')} </strong>{selectedDate} (kl. {time}) </Typography>
        <Typography sx={{mt:4, mb:4}}><strong>{t('Total amount')} </strong>{(bookingDetails?.selectedEmployee?.id === 0 || bookingDetails?.selectedEmployee?.id === null ||bookingDetails?.selectedEmployee?.id === undefined )? formatAmount(bookingDetails?.selectedService?.price, false) : formatAmount(bookingDetails?.selectedEmployee?.price, false)} </Typography>
        
        {/* <Buttons variant={'contained'} disabled={confirmBookingLoader} loading={confirmBookingLoader} children={t('Confirmbooking')} onclick={() => confirmBooking()} borderRadius={'12px'} fontWeight={'bold'} gap={1}  width={{ xs: 'auto', md: 'auto' }} endIcon={<ArrowForwardIosIcon />} /> */}
     
        <LoadingButton
        disabled={confirmBookingLoader}
        type="submit"
        variant="contained"
        loading={confirmBookingLoader}
        sx={{
            mt: 1,
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'primary',
            borderRadius:'12px',
            fontWeight:'bold',
            gap:1,
            width:250,
            '&:hover': {
              bgcolor: theme.palette.primary.main,
          },

        }}
         loadingPosition="end"
         endIcon={<ArrowForwardIosIcon sx={{color: confirmBookingLoader ? 'black' :'white'}}/>}
        onClick={() => confirmBooking()}

      
    >
       <Typography sx={{mt:0.2, color: confirmBookingLoader ? 'black' : 'white', textTransform:'initial'}}> {t('ConfirmBooking')} </Typography>
       
    </LoadingButton>


    </Stack>
  </Box>
  )
}

export default CompleteBooking
