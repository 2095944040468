import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Slide } from '@mui/material';

export default function CustomizedSnackbars({ open, message, onClose }) {
    function TransitionUp(props) {
        return <Slide {...props} direction="down" />;
      }
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={onClose} TransitionComponent={TransitionUp} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={onClose} severity="error" variant="filled" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
