import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { t } from 'i18next';

// Step connector styling
const DashedConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopStyle: 'dashed',
    borderTopWidth: 1, // Thinner dashes
    borderColor: theme.palette.primary.main,
    borderImage: `repeating-linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 7px, transparent 6px, transparent 12px)`, // Longer dashes
    borderImageSlice: 1,
  },
}));

// Custom Step Icon styling
const CustomStepIconRoot = styled('div')(({ theme, completed }) => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  backgroundColor: completed ? theme.palette.primary.main : 'white',
  border: `2px solid ${completed ? theme.palette.primary.main : theme.palette.primary.main}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function CustomStepIcon(props) {
  const { completed, className } = props;
  return <CustomStepIconRoot completed={completed} className={className} />;
}

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

// Define the steps and routes
// const steps = [t('Serviceee'), t('Employees'), t('Time'), t('Information'), t('Finished')];
const steps = ['Service', 'Employees', 'Time', 'Information', 'Finished'];


export default function CustomizedSteppers({selectedStep,setSelectedStep,web_store_name,storeDetails}) {
  const [activeStep, setActiveStep] = React.useState(0);
  
  console.log('selectedStep', selectedStep);
  
  // Sync stepper with the current route
  React.useEffect(() => {
    switch (selectedStep) {
      case 'category':
        // setSelectedStep('category');
        setActiveStep(1);
        break;
        case 'category1':
          // setSelectedStep('category');
          setActiveStep(1);
          break;
      case 'service':
        // setSelectedStep('category');
        setActiveStep(2);
        break;
      case 'employee':
        // setSelectedStep('service');
        setActiveStep(3);
        break;
      case 'calendar':
        // setSelectedStep('employee');
        setActiveStep(4);
        break;
      case 'information':
        // setSelectedStep('calendar');
        setActiveStep(5);
        break;
      case 'otpverification':
        // setSelectedStep('information');
        setActiveStep(6);
        break;
      case 'confirmbooking':
        // setSelectedStep('otpverification');
        setActiveStep(6);
        break;
      case 'bookingconfirmed':
        // setSelectedStep('confirmbooking');
        setActiveStep(6);
        break;
      case 'bookingconfirmed':
        // setSelectedStep('bookingconfirmed');
        setActiveStep(6);
        break;
     
    }
  }, [selectedStep]);

  return (

    <Stack sx={{ width: '70%', alignSelf:'center', mt:1 }} spacing={6}>
      <Stepper  alternativeLabel activeStep={activeStep} connector={<DashedConnector />}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={CustomStepIcon} >
              <Typography variant='subtitle1' >

              {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  ); 
}
