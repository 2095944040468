import { Modal, Box, Typography, Button, styled } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import theme from '../theme';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs:'95%' ,lg:'30%'},
    maxHeight: {xs:'60%' ,md:'80%'},
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    //border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '25px',
    scrollbarWidth: 'none', 
    msOverflowStyle: 'none',
};


const StyledModal = styled(Modal)({
    '& .MuiPaper-root': {
      outline: 'none',  
      boxShadow: 'none',
      borderRadius: '25px',
    },
    '& .MuiModal-root': {
      outline: 'none',  
      borderRadius: '25px',
    },
    '& *:focus': {
      outline: 'none',  
    },
    borderRadius: '25px',
  });
  

function ConfirmationPolicy({ handleClose, policy, open }) {
    return (
        <div >
            <StyledModal
                open={open}
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
            >
                <Box sx={style} onClick={(e) => e.stopPropagation()} >
                    <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 4 }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {t('policies')}
                        </Typography>
                        <Typography  whiteSpace="pre-line"  sx={{ mt: 2 }}>
                            {policy}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'sticky',
                            bottom: -32,
                            bgcolor: 'background.paper',
                            paddingTop:'10px',
                            paddingBottom:'10px',
                            
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClose}
                            sx={{color:'white', textTransform:'initial', width:{xs:'100%' , md:'auto'} , borderRadius:{xs:'10px' , md:''}}}
                        >
                           {t('iAcceptPolicies')}
                        </Button>
                    </Box>
                </Box>
            </StyledModal>
        </div>
    );
}

export default ConfirmationPolicy;
