import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import logo from '../assets/logo/App-icon1.png'

const StyledLogo = styled('img')`
  width: 60px;
  height: 40px;
`;

const Logo = ({ src, variant, ...other }) => {
  return (
    <StyledLogo
      src={src || logo} 
      alt="Logo"
      {...other}
    />
  );
};

Logo.defaultProps = {
  variant: 'primary',
};

Logo.propTypes = {
  src: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'primary']),
};

export default Logo;
