// src/theme.js
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: '#f7bd98',
      text:'#545454' ,
      divider:'#dde0f4',
      socialmedia: '#1f1f1f',
      icon:'#000000',
    },
  },
  typography: {
    fontFamily: ['DMSans', 'serif'].join(','),
    color:'#545454',
    h5:{
      color:'#1F1F1F',
      fontWeight:700
     
    },
    subtitle1:{
      color:'#545454'
    },
    h6:{
      color:'#545454',
      fontWeight:'bold'
    },
    subtitle2:{
      color:'#545454'
    },
    body2:{
      color:'#545454'
    },
    body1:{
      color:'#545454'
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: ${['DMSans', 'serif'].join(',')};
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    },
  }
});

export default theme;
