import { Avatar, Box, Chip, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { t } from 'i18next';
import image1 from '../assets/icons/location.jpg'

function StoreInfo({ storeDetails }) {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:601px) and (max-width:900px)');
    const imgPath = storeDetails?.profile_image;
    const img1 = `https://fiind-dev.s3.amazonaws.com/${imgPath}`
    // const currentDay = new Date().toLocaleString('en-US', { weekday: 'long' });
    // const todaySchedule = storeDetails.schedules.find(schedule => schedule.day === currentDay);

    const categories = storeDetails?.categories


    return (
        <Stack flexDirection={'column'} flex={1} justifyContent={'flex-start'}>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-start',
                    // padding: { xs: '40px', sm: '30px', md: '40px' },
                    // ml:{xs:2, md:4},
                    // mr:{xs:2, md:4},

                    px: { xs: 1.7, md: 4 },
                    mt: 4,
                    mb: 2
                }}
            >


                {(imgPath === null) || (imgPath === '') || !isMobile && (
                    <img
                        style={{
                            height: '90px',
                            width: '90px',
                            border: '2px solid #545454',
                            borderRadius: '30px',
                            objectFit: 'cover',
                        }}
                        src={img1}
                        alt="Store"
                    />
                )}

                <Box flex={1} flexDirection={'row'} justifyContent={'flex-start'} sx={{ ml: { xs: 0, md: 2 } }}>




                    <Stack flex={1} flexDirection={'row'} justifyContent={'space-between'} sx={{ mb: 1 }}>
                        <Typography
                            variant='h5'
                            sx={{ fontWeight: 700, fontSize: '1.3rem' }}
                        >
                            {storeDetails?.name}
                        </Typography>
                        <Typography
                            variant='h6'
                            sx={{
                                // fontSize: (isMobile || isTablet) ? '18px': '35px',
                                color: storeDetails?.open ? '#00a518' : 'red',
                                marginTop: { xs: '0px', sm: '0px', md: '0' },
                                marginLeft: 'auto',
                                fontWeight: 700
                            }}
                        >
                            {!storeDetails.open ? t('Close') : t('Open')}
                        </Typography>
                    </Stack>


                    {categories.map((data, i) => (
                        <Chip
                            size='small'
                            sx={{
                                border: '1px solid #A79C92',
                                color: '#A79C92',
                                bgcolor: '#ffffff',
                                borderRadius: 2,
                                mr: 1,
                                ml: 0
                            }}
                            label={<Typography variant='caption' sx={{ fontSize: '0.8rem' }}>{data.category}</Typography>}
                        />
                    ))}


                </Box>



                {/* {!(isMobile || isTablet) && (
                <Typography variant='h5' sx={{ color: todaySchedule.is_closed ? 'red' : 'green', marginTop: { xs: '10px', sm: '5px', md: '0' } }}>
                    {todaySchedule.is_closed ? t('Close') : t('Open')}
                </Typography>
            )} */}
            </Box>

            <Stack flex={1} alignItems={'center'} flexDirection={'row'} gap={'0.2rem'} sx={{
                ml: imgPath ? { xs: 1.3, md: 16.5 } : { xs: 1.3, md: 6 },
                mt: 0,
            }}>

                <img src={image1} style={{ mr: 0.2, height: '1.2rem' }} />
                <Typography variant='body2'>{storeDetails?.address}</Typography>
            </Stack>

        </Stack>

    )
}

export default StoreInfo
