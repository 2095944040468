import styled from '@emotion/styled';
import { Box, Grid2, Paper, Typography, useMediaQuery, Radio, Button, Chip, Divider, Stack, Card, CardContent, CardMedia, Tooltip } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../theme';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { t } from 'i18next';
import { format, isToday, isTomorrow, parseISO, differenceInSeconds } from 'date-fns';
import { formatAmount } from '../utils/format-amount';
import moment from 'moment';

function ServiceOfferCardNew(props) {
  const { type, onchange, checked, specialprice, cancelationprice, countdownSeconds, cancellation_offer_slot_start, cancellation_offer_slot_end, service } = props;




  const isMobile = useMediaQuery('(max-width:600px)');

  const [showMore, setShowMore] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [cancellationTimeLeft, setCancellationTimeLeft] = useState(0);
  const [timeLeftString, setTimeLeftString] = useState('');
  const [cancelationDate, setCancellationDate] = useState('');

  const [textColor, setTextColor] = useState(theme.palette.primary.main);
  const [borderColor, setBorderColor] = useState(theme.palette.primary.main);
  const [backgroundColor, setBackgroundColor] = useState('white');



  useEffect(() => {
    const calculateTimeLeft = () => {
      const createdAt = new Date(service.created_at + 'Z');
      const now = new Date();
      const diff = createdAt.getTime() + 24 * 60 * 60 * 1000 - now.getTime();

      return diff > 0 ? diff : 0;
    };



    const updateTimeLeft = () => {
      const time = calculateTimeLeft();
      setTimeLeft(time);
      if (time < 3600 * 1000) {
        setTextColor('#ff0000');
        setBorderColor('transparent');
        setBackgroundColor('#ffeff1');
      } else {
        setTextColor(theme.palette.primary.main);
        setBorderColor(theme.palette.primary.main);
        setBackgroundColor('white')
      }
    };

    updateTimeLeft();

    const timer = setInterval(updateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [service.created_at]);


  const formatTime = (time) => {
    // const hours = Math.floor(time / 3600);
    // const minutes = Math.floor((time % 3600) / 60);
    // const seconds = time % 60;

    const totalSeconds = Math.floor(time / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;


    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };


  // console.log('asd',moment(service?.created_at).clone().add(24,'hours').diff(moment(), 'seconds'));

  // console.log('createdAt',moment(service?.created_at).clone().add(24,'hours') );
  // console.log('current time',moment(), );


  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: '#fff',
  //   ...theme.typography.body2,
  //   padding: isMobile ? theme.spacing(1): theme.spacing(2),

  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  //   boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  //   borderRadius: '10px',
  //   cursor: 'pointer',
  // }));



  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };


  // console.log('countdownSeconds',countdownSeconds);


  // useEffect(() => {
  //   if (type === 'Cancellation Offers') {
  //     const startTime = new Date(cancellation_offer_slot_start);
  //     const endTime = new Date(cancellation_offer_slot_end);
  //     const now = new Date();

  //     const remainingTime = Math.max(0, differenceInSeconds(endTime, now));
  //     setCancellationTimeLeft(remainingTime);

  //     // Formatting the date and time for display
  //     if (remainingTime > 0) {
  //       const displayDate = format(endTime, 'eeee');
  //       const displayTime = format(endTime, 'HH:mm');

  //       if (isToday(endTime)) {
  //         setTimeLeftString(`Today at ${displayTime}`);
  //       } else if (isTomorrow(endTime)) {
  //         setTimeLeftString(`Tomorrow at ${displayTime}`);
  //       } else {
  //         setTimeLeftString(`${displayDate} at ${displayTime}`);
  //       }
  //     } else {
  //       setTimeLeftString('Offer has expired');
  //     }
  //   }
  // }, [type, cancellation_offer_slot_start, cancellation_offer_slot_end]);

  // useEffect(() => {
  //   const storedData = localStorage.getItem('serviceTimer');
  //   const now = Date.now();

  //   if (storedData) {
  //     const { startTime, duration } = JSON.parse(storedData);
  //     const endTime = startTime + duration * 1000;
  //     const remainingTime = Math.max(0, Math.floor((endTime - now) / 1000));

  //     setTimeLeft(remainingTime);
  //   } else {
  //     const newStartTime = Date.now();
  //     localStorage.setItem('serviceTimer', JSON.stringify({ startTime: newStartTime, duration: countdownSeconds }));
  //     setTimeLeft(countdownSeconds);
  //   }
  // }, [countdownSeconds]);

  useEffect(() => {
    if (type == 'specialOffers') {

      const timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(timerInterval);
            // setIsVisible(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, []);

  useEffect(() => {
    if (type === 'cancellationOffers') {
      const startTime = new Date(service.cancellation_offer_slot_start);
      const endTime = new Date(service.cancellation_offer_slot_end);
      const now = new Date();

      const remainingTime = Math.max(0, differenceInSeconds(endTime, now));
      setCancellationTimeLeft(remainingTime);

      // Formatting the date and time for display
      if (remainingTime > 0) {
        const displayDate = format(endTime, 'dd/mm');
        const displayTime = format(endTime, 'HH:mm');

        if (isToday(endTime)) {
          setCancellationDate(`Today at`)
          setTimeLeftString(`kl. ${displayTime}`);
        } else if (isTomorrow(endTime)) {
          setCancellationDate(`Tomorrow`)

          setTimeLeftString(`kl. ${displayTime}`);
        } else {
          setCancellationDate(displayDate)

          setTimeLeftString(`kl. ${displayTime}`);
        }
      } else {
        setTimeLeftString('Offer has expired');
      }
    }
  }, [type, service]);

  if (!isVisible) return null; // Return null if the card is not visible


  if (isMobile) {

    return (
      <Stack
        key={service?.id}
        // sx={{backgroundColor:'pink'}}
        onClick={onchange}
      >

        <Stack sx={{
          display: 'flex',
          justifyContent: 'space-between',
          border: `3px solid ${theme.palette.primary.main}`,
          borderRadius: '20px',
        }}>
          <Stack sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

            <Stack flexDirection={'row'} flex={1} justifyContent={'flex-start'} alignItems={'self-start'} >

              <Radio
                sx={{ color: 'primary.main', justifyContent: 'end', alignItems: 'center' }}

                checked={checked}
                value={`service${service.id}`}
                name="service-radio"
              />

              <Tooltip title={service?.name} arrow>
                <Typography variant='body1' sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  fontWeight: 700,
                  color: 'primary.text',
                  mt: 1, mb: 1,
                  width: '85%',
                }}>
                  {service?.name}
                </Typography>
              </Tooltip>

              {/* <Typography component="div" variant="body1"   >
         {service.duration_text}
         </Typography> */}
            </Stack>

            <Stack flexDirection={'row'} flex={1} justifyContent={'flex-start'} alignItems={'center'} sx={{ borderBottom: '2px solid #f7bd98', ml: 0 }}>


              <Typography variant='body1' sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'primary.text',
                flexDirection: 'row',
                ml: 5,
                mb: 1
              }}>
                {type === 'specialOffers' ? `${t('Expireson')}` : `${t('Booking')}:`}
                {
                  type === 'specialOffers' ? <Chip label={formatTime(timeLeft)} sx={{ ml: 1, backgroundColor: backgroundColor, color: textColor, borderRadius: '10px', fontWeight: 'bold', }} />
                    :
                    <Stack flex={1} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                      <Typography sx={{ display: 'flex', flexWrap: 'wrap', fontWeight: 'bold', color: 'green', ml: 1, mr: 1 }}>{cancelationDate}</Typography>

                      <Typography sx={{ display: 'flex', flexWrap: 'wrap', fontWeight: 'bold', color: 'green' }}>{timeLeftString}</Typography>
                    </Stack>
                }

              </Typography>
              {/* <Typography
                     sx={{
                       display: 'flex',
                       flexWrap: 'wrap',
                       gap: '10px',
                       color: 'green',
                       flexDirection: 'column',
                       whiteSpace: 'nowrap',
                       fontWeight: 'bold',
                       marginTop: '10px',
                       maxHeight: type === 'Special Services' || type === 'Cancellation Offers' ? '130%' : '100%',
                       paddingTop: type === 'Special Services' || type === 'Cancellation Offers' ? '2px' : '0',
                       paddingBottom: type === 'Special Services' || type === 'Cancellation Offers' ? '2px' : '0',
                     }}
                   >
  
          <Typography sx={{ color: 'primary.text', textDecoration: 'line-through'}}>
          {formatAmount(service?.price, false) }
          </Typography>
                   {type === 'specialOffers' ? formatAmount(service?.special_price, false) :  formatAmount(service?.cancellation_offer_price, false) }
                   <Typography sx={{
                     display: 'flex',
                     alignItems: 'center',
                     color: 'primary.text',
                     flexDirection: 'column',
                   }}>
                     {type === 'specialOffers' ? `${t('Expireson')}` : `${t('Booking')}:`}
                     {
                       type === 'specialOffers' ? <Chip label={formatTime(timeLeft)} sx={{ backgroundColor: (countdownSeconds <= 300) ? 'rgba(255, 0, 0, 0.1)' : '', color: (countdownSeconds <= 300) ? 'red' : 'primary.text', borderRadius: '10px', fontWeight: 'bold', }} />
                         :
                         <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                         <Typography sx={{ display: 'flex', flexWrap: 'wrap', fontWeight: 'bold', color: 'green' }}>{cancelationDate}</Typography>
                          
                         <Typography sx={{ display: 'flex', flexWrap: 'wrap',  fontWeight: 'bold', color: 'green' }}>{timeLeftString}</Typography>
                         </Stack>
                      }
                   
                    </Typography>
                    </Typography> */}
            </Stack>

            <Stack flexDirection={'row'} flex={1} justifyContent={'space-between'} alignItems={'center'} sx={{ ml: 1, mr: 1 }}>

              <Typography component="div" variant="body1" sx={{ color: 'text.secondary' }}  >
                {service.duration_text}
              </Typography>

              <Typography
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px',
                  color: 'green',
                  flexDirection: 'row',
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                  marginTop: '10px',

                }}
              >

                <Typography variant='body1' component="div" sx={{ color: 'text.secondary', textDecoration: 'line-through' }}>
                  {formatAmount(service?.price, false)}
                </Typography>
                {type === 'specialOffers' ? formatAmount(service?.special_price, false) : formatAmount(service?.cancellation_offer_price, false)}


              </Typography>
            </Stack>
            <Typography
              variant="body1"
              component="div"
              sx={{ color: 'text.secondary', ml: 1, mr: 1, mb: 1 }}
            >
              {service?.description}
            </Typography>

          </Stack>
          {/* <Stack flex={1}  flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}   sx={{mt:{xs:1,md:0, p:0, m:0},height:'auto',mb:2  }}>
  
  
      <Stack justifyContent={'center'} alignItems={'center'} flex={1} flexDirection={'row'} sx={{height:'100%'}} >
               
               
       <Typography
                     sx={{
                       display: 'flex',
                       flexWrap: 'wrap',
                       gap: '10px',
                       color: 'green',
                       flexDirection: 'column',
                       whiteSpace: 'nowrap',
                       fontWeight: 'bold',
                       marginTop: '10px',
                       maxHeight: type === 'Special Services' || type === 'Cancellation Offers' ? '130%' : '100%',
                       paddingTop: type === 'Special Services' || type === 'Cancellation Offers' ? '2px' : '0',
                       paddingBottom: type === 'Special Services' || type === 'Cancellation Offers' ? '2px' : '0',
                     }}
                   >
  
          <Typography sx={{ color: 'primary.text', textDecoration: 'line-through'}}>
          {formatAmount(service?.price, false) }
          </Typography>
                   {type === 'specialOffers' ? formatAmount(service?.special_price, false) :  formatAmount(service?.cancellation_offer_price, false) }
                   <Typography sx={{
                     display: 'flex',
                     alignItems: 'center',
                     color: 'primary.text',
                     flexDirection: 'column',
                   }}>
                     {type === 'specialOffers' ? `${t('Expireson')}` : `${t('Booking')}:`}
                     {
                       type === 'specialOffers' ? <Chip label={formatTime(timeLeft)} sx={{ backgroundColor: (countdownSeconds <= 300) ? 'rgba(255, 0, 0, 0.1)' : '', color: (countdownSeconds <= 300) ? 'red' : 'primary.text', borderRadius: '10px', fontWeight: 'bold', }} />
                         :
                         <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                         <Typography sx={{ display: 'flex', flexWrap: 'wrap', fontWeight: 'bold', color: 'green' }}>{cancelationDate}</Typography>
                          
                         <Typography sx={{ display: 'flex', flexWrap: 'wrap',  fontWeight: 'bold', color: 'green' }}>{timeLeftString}</Typography>
                         </Stack>
                      }
                   
                    </Typography>
                    </Typography>
               
            
            
            </Stack>
  
        <Stack justifyContent={'center'} width={40} flexDirection={'column'}  >
      <Radio
        sx={{ color: 'primary.main',   display:  {md: 'flex', xs:'none', sm:'none' }, justifyContent: 'end', alignItems:'center'}}
    
        checked={checked}
        value={`service${service.id}`}
        name="service-radio"
        />
        </Stack>
  
  
     </Stack> */}
        </Stack>



      </Stack>
    )
  }

  return (


    <Stack
      key={service?.id}
      // sx={{backgroundColor:'pink'}}
      onClick={onchange}
    >

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        border: `3px solid ${theme.palette.primary.main}`,
        borderRadius: '20px',
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 0.8, borderRight: '2px solid #f7bd98', marginTop: 2, marginBottom: 2 }}>
          <CardContent sx={{ flex: '1 0 auto', marginTop: -1 }}>
            <Stack flexDirection={'row'} flex={1} justifyContent={'space-between'} alignItems={'center'}>

              <Typography sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                fontWeight: 'bold',
                color: 'primary.text',
                width: '85%'
              }}>
                {service?.name}
              </Typography>

              <Typography component="div" variant="body1">
                {service.duration_text}
              </Typography>
            </Stack>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                color: 'text.secondary',
                display: showMore ? 'block' : '-webkit-box',
                WebkitLineClamp: isMobile ? 6 : '',
                WebkitBoxOrient: 'vertical',
                overflow: showMore ? 'visible' : 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {service?.description}
            </Typography>
            {isMobile && service?.description?.length > 100 && (
              <Typography
                onClick={(event) => { event.stopPropagation(); toggleShowMore(); }}
                sx={{
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                }}
              >
                {showMore ? t('ShowLess') : t('ReadMore')}
              </Typography>
            )}
          </CardContent>

        </Box>
        <Stack flex={0.2} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'} sx={{ mt: { xs: 1, md: 0, p: 0, m: 0 }, height: 'auto', mb: 2 }}>


          <Stack justifyContent={'center'} alignItems={'center'} flex={1} flexDirection={'row'} sx={{ height: '100%' }} >


            <Typography
              justifyContent={'center'} flex={1} flexDirection={'column'} alignItems={'center'}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
                color: 'green',
                flexDirection: 'column',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                marginTop: 2,
                justifyContent: 'center',

              }}
            >

              <Typography sx={{ color: 'primary.text', textDecoration: 'line-through', mb: -1 }}>
                {formatAmount(service?.price, false)}
              </Typography>
              {type === 'specialOffers' ? formatAmount(service?.special_price, false) : formatAmount(service?.cancellation_offer_price, false)}
              <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'primary.text',
                flexDirection: 'column',
              }}>
                {type === 'specialOffers' ? `${t('Expireson')}` : `${t('Booking')}:`}
                {
                  type === 'specialOffers' ? <Chip label={formatTime(timeLeft)} sx={{ backgroundColor: (countdownSeconds <= 300) ? 'rgba(255, 0, 0, 0.1)' : '', color: (countdownSeconds <= 300) ? 'red' : 'primary.text', borderRadius: '10px', fontWeight: 'bold', }} />
                    :
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                      <Typography sx={{ display: 'flex', flexWrap: 'wrap', fontWeight: 'bold', color: 'green' }}>{cancelationDate}</Typography>

                      <Typography sx={{ display: 'flex', flexWrap: 'wrap', fontWeight: 'bold', color: 'green' }}>{timeLeftString}</Typography>
                    </Stack>
                }

              </Typography>
            </Typography>



          </Stack>

          <Stack justifyContent={'center'} width={40} flexDirection={'column'}  >
            <Radio
              sx={{ color: 'primary.main', display: { md: 'flex', xs: 'none', sm: 'none' }, justifyContent: 'end', alignItems: 'center' }}

              checked={checked}
              value={`service${service.id}`}
              name="service-radio"
            />
          </Stack>


        </Stack>
      </Box>



    </Stack>




  );
}

export default ServiceOfferCardNew;
