import { Box, Grid2, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Buttons from './button';
// import { useNavigate, useOutletContext } from 'react-router-dom';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { t } from 'i18next';
import img1 from '../assets/icons/image.png';
import img2 from '../assets/icons/specialoffer2.svg'

function Category({ handleCategorySelect, storeDetails }) {
    // const { storeDetails } = useOutletContext();   

    // const finalCategories= storeDetails.services.filter((obj)=>obj.services.length > 0)
    const finalCategories = storeDetails.services.filter((obj) => obj?.services?.length > 0)

    const hasSpecialOrCancellation = finalCategories.some(
        (group) => group?.group === 'Special offers' || group?.group === 'Cancellation offers'
    );

    // const specialServices = storeDetails.services.find(service => service.group === 'Special Services');
    // const cancellationOffers = storeDetails.services.find(service => service.group === 'Cancellation Offers');    

    return (
        <Box sx={{ pl: 0, pr: 0, ml: 0, mr: 0, mt: 0, width:'100%' }}>
            <Typography variant={'h6'}
                sx={{ paddingBottom: '30px', textAlign: 'left', fontSize: { xs: '1.1rem' } }}>
                {t('SelectCategory')}
            </Typography>

            {/* <Box sx={{ width: '100%', marginBottom: '20px'}}> */}
            {/* <Grid2 gap={5} sx={{ display: 'flex', flexDirection: 'column' }}> */}
            {/* <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12, lg: 6 }}>
                        <Grid2 gap={2} size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
                            <Buttons
                                type='Special Offers'
                                variant="outlined"
                                borderRadius={'15px'}
                                width={{ xs: '90%' }}
                                name={'Hardcoded'}
                                borderWidth={'3px'}
                                onclick={() => {handleCategorySelect(specialServices); console.log('button clicked');
                                }}
                                endIcon={
                                    <Box sx={{ display: 'flex', alignItems: 'end' }}>
                                        <ArrowForwardIosIcon />
                                    </Box>
                                }
                                display={'flex'}
                                justifyContent={'space-between'}

                                children={
                                    <Typography sx={{
                                        display: 'flex',
                                        gap: '5px',
                                        flexDirection: 'row',
                                        textAlign: 'center',
                                        fontSize: { xs: '14px', sm: '16px', md: '18px' },
                                    }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <LocalOfferIcon sx={{ color: '#c74141', rotate: '90deg' }} />
                                        </Box>
                                        {specialServices.group}
                                    </Typography>}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
                            <Buttons
                                variant="outlined"
                                type='Cancellation Offer'
                                onclick={() => handleCategorySelect(cancellationOffers)}
                                borderWidth={'3px'}
                                display={'flex'}
                                justifyContent={'space-between'}
                                children={
                                    <Typography sx={{
                                        display: 'flex',
                                        gap: '5px',
                                        flexDirection: 'row',
                                        textAlign: 'center',
                                        fontSize: { xs: '14px', sm: '16px', md: '18px' },
                                    }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                            <EventBusyIcon sx={{ color: '#9a8571' }} />
                                        </Box>
                                        {cancellationOffers.group}
                                    </Typography>}
                                endIcon={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <ArrowForwardIosIcon />
                                    </Box>}
                                borderRadius={'15px'}
                                width={{ xs: '90%' }}
                                name={'HardCoded'}
                            />
                        </Grid2>
                    </Grid2> */}

            {hasSpecialOrCancellation && (
                <Grid2 container spacing={{ xs: 2, md: 1 }} columnSpacing={{ xs: 2, md: 3 }}  columns={{ xs: 12, sm: 12, md: 12, lg: 6 }} >
                    {finalCategories
                        .filter(
                            (group) => group?.group === 'Special offers' || group?.group === 'Cancellation offers'
                        )
                        .map((group, index) => (
                            <Grid2 key={index} size={{ xs: 12, sm: 12, md: 12, lg: 3 }}>
                                <Buttons
                                    type={group.group}
                                    variant="outlined"
                                    borderRadius="15px"
                                    width="100%"
                                    minWidth="100%"
                                    name={group.group}
                                    borderWidth="3px"
                                    justifyContent="space-between"
                                    onclick={() => handleCategorySelect(group)}
                                    endIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <ArrowForwardIosIcon sx={{ color: '#545454', height: 15 }} />
                                        </Box>
                                    }
                                    startIcon={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {group?.group === 'Cancellation offers' ? (
                                                <img src={img1} style={{ height: '1.4rem' }} />
                                            ) : group?.group === 'Special offers' ? (
                                                <img src={img2} style={{ height: '1.2rem' }} />
                                            ) : null}
                                            
                                            <Tooltip title={group.group} arrow>
                                                <Typography
                                                    sx={{
                                                        flexGrow: 1,
                                                        textAlign: 'center',
                                                        ml: 1,
                                                        color: '#545454',
                                                        WebkitLineClamp: 1,
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                    variant="subtitle1"
                                                >
                                                    {group.group}
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                    }
                                />
                            </Grid2>
                        ))}
                </Grid2>
            )}

            <Grid2 container spacing={{ xs: 2, md: 1 }} columnSpacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12, lg: 6 }} marginTop={{xs:4}} >

                {/* {storeDetails.services?.filter(group => group.group !== 'Special Services' && group.group !== 'Cancellation Offers').map((group, index) => ( */}
                {finalCategories.filter(
                    (group) => group?.group !== 'Special offers' && group?.group !== 'Cancellation offers'
                ).map((group, index) => (
                    <Grid2 key={index} size={{ xs: 12, sm: 12, md: 12, lg: 3 }} >
                        <Buttons
                            type={group.group}
                            variant="outlined"
                            borderRadius={'15px'}
                            width='100%'
                            minWidth="100%"
                            name={group.group}
                            // margin={ '0 0 0 0' }
                            borderWidth={'3px'}
                            // display={'flex'}
                            justifyContent={'space-between'}
                            onclick={() => handleCategorySelect(group)}
                            endIcon={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ArrowForwardIosIcon sx={{ color: '#545454', height: 15 }} />
                                </Box>
                            }

                            startIcon={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                    {group?.group == 'Cancellation offers' ?
                                        (
                                            // Display the custom image for 'Cancellation offers'
                                            <img src={img1} style={{ height: '1.4rem' }} />
                                        )
                                        : group?.group == 'Special offers' ? <img src={img2} style={{ height: '1.2rem' }} /> : null
                                    }

                                    <Tooltip title={group.group} arrow>
                                        <Typography
                                            sx={{
                                                flexGrow: 1,
                                                textAlign: 'left',
                                                ml: 1,
                                                color: '#545454',
                                                display: '-webkit-box', 
                                                overflow: 'hidden', 
                                                WebkitBoxOrient: 'vertical', 
                                                WebkitLineClamp: 1, 
                                                textOverflow: 'ellipsis', 
                                                width: '100%' ,
                                                maxWidth:'90%',
                                                minWidth:'100%'
                                            }}
                                            variant="subtitle1"
                                        >
                                            {group.group}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                            }
                        // children={
                        //     // <Typography sx={{
                        //     //     flexGrow: 1,
                        //     //     textAlign: 'center',
                        //     //     fontSize: { xs: '14px', sm: '16px', md: '18px' },
                        //     // }}>
                        //         group.group
                        //     // </Typography>
                        // }
                        />
                    </Grid2>

                ))}
            </Grid2>
            {/* </Grid2> */}
            {/* </Box> */}
        </Box>
    );
}

export default Category;
