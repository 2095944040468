import React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import img1 from '../assets/images/page_404.jpg';
import { t } from 'i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function Page404() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));   
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));  
  const isLaptop = useMediaQuery(theme.breakpoints.up('md'));  

  
  const imageSize = {
    height: isMobile ? '20%' : isTablet ? '30%' : '40%',
    width: isMobile ? '35%' : isTablet ? '40%' : '20%',
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        backgroundColor: '#eeeeee',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Card
        sx={{
          backgroundColor: 'white',
          width: '70%',
          height: '70%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '5%',
          alignItems: 'center',
        }}
      >
        <img style={imageSize} src={img1} alt="404" />
        <Typography sx={{ fontSize: { xs: '16px', sm: '18px', md: '20px' } }}>
          {t('BusinessNotFound')}
        </Typography>
        <Typography sx={{ fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>
          {t('LookForOtherBusiness')}
        </Typography>
      </Card>
    </div>
  );
}

export default Page404;
