// InfoWindowContent.js
import React from 'react';
import { Box, Card, Typography, useMediaQuery } from '@mui/material';
import theme from '../theme';
import { Rating } from 'react-simple-star-rating';
import locationIcon from '../assets/images/location.png';
import { t } from 'i18next';

const InfoWindowContent = ({ categoryImageUrl, name, rating, review, location, category, categoryCount, status, onClick }) => {

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Card sx={{ width: isSmallScreen ? 200 : 280 ,boxShadow:'none' }} onClick={onClick}>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Box sx={{p:0, m:0 }}>
                    <img
                        src={categoryImageUrl}
                        alt={name}
                        style={{ width: 60, height: 60, borderRadius: '30%', marginRight: 10, objectFit: 'cover', border: '2px solid #545454' }}
                    />
                </Box>
                <Box>
                    <Typography variant="body1" noWrap sx={{
                        fontWeight: 'bold', overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '0.85rem',
                        pr: 0,
                        color: '#000000'
                    }}>
                        {name}
                    </Typography>

                    <Rating initialValue={rating} fillColor='#f1a545' readonly={true} size={16} />
                    {rating > 0 && (
                        <Typography variant="caption" sx={{ ml: 0.5 }}>
                            {rating.toFixed(1)}
                        </Typography>
                    )}

                    {
                        review === 0 ? (
                            null
                        ) : (
                            <Typography variant="caption" sx={{ ml: 0.5 }}>
                                {`(${review} ${review > 1 ? 'reviews' : 'review'})`}
                            </Typography>
                        )
                    }

                </Box>
            </Box>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%', paddingTop: 5, paddingBottom: 5 }}>
                <Box
                    component="img"
                    src={locationIcon}
                    alt="service Image"
                    sx={{
                        width: '16px',
                        height: '16px',
                        objectFit: 'contain',
                        objectPosition: 'center',
                        display: 'block',
                        // marginLeft: 1
                    }}
                />
                <Typography
                    variant="caption"
                    noWrap
                    sx={{
                        paddingLeft: 0.5,
                        width: '90%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'block',
                        color: '#545454'
                    }}
                >
                    {location}
                </Typography>
            </div>

            <Box display="flex" justifyContent="space-between" flexDirection={isSmallScreen ? 'column' : 'row'} >

                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box
                        sx={{
                            width: "auto",
                            maxWidth: '100%',
                            height: "auto",
                            border: '1px solid #A79C92',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 2,
                            marginRight: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <Typography
                            variant="caption"
                            sx={{
                                paddingRight: 1,
                                paddingLeft: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: '#a89c91',
                                height: 20,
                                alignContent:'center'
                            }}
                        >
                            {categoryCount === 1 ? (
                                category
                            ) : categoryCount > 1 ? (
                                category
                            ) : (
                                'No Categories'
                            )}
                        </Typography>
                    </Box>

                    {categoryCount > 1 && (
                        <Box
                            sx={{
                                width: "auto",
                                height: "auto",
                                border: '1px solid #A79C92',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 2,
                                marginRight: 1,
                            }}
                        >
                            <Typography variant="caption" sx={{ paddingRight: 1, paddingLeft: 1, color: '#a89c91' }}>
                                {`+${categoryCount - 1}`}
                            </Typography>
                        </Box>
                    )}
                </Box>

                <Typography variant="body2" noWrap sx={{
                    maxWidth: '100%', overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    color: status ? '#00a518' : 'red',
                    paddingTop: isSmallScreen ? 0.5 : null,
                    paddingLeft: isSmallScreen ? 0.5 : null
                }}>
                    {status ? `${t('Open')}` : `${t('Close')}`}
                </Typography>

            </Box>

        </Card>
    );
};

export default InfoWindowContent;
