import { Box, Container, Divider, Grid2, Stack, Tabs, Typography, useMediaQuery } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../components/header';
import StoreInfo from '../components/storeInfo';
import Navigator from '../components/navigator';
import { webCustomerBookingApi } from '../apis/web-customerBooking';
import { SplashScreen } from '../components/splash-screen';
import ConfirmationPolicy from '../components/confirmationPolicy';
import { useMounted } from '../hooks/use-mounted';
import Hours from '../components/hours';
import CustomizedSnackbars from '../components/customizedSnackbars';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { t } from 'i18next';
import Page404 from '../components/page404';
import AboutUsNew from './aboutUsNew';
import Pictures from './pictures';
import Booking from './booking';
import { styled } from '@mui/material/styles';
import theme from '../theme';


function StoreHomePage() {

    const { state } = useLocation();
    const navigate = useNavigate();
    const { web_store_name } = useParams();
    const isMounted = useMounted();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [tabValue, setTabValue] = useState("1");
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [policy, setPolicy] = useState('');
    const [loading, setLoading] = useState(true);
    const [storeDetails, setStoreDetails] = useState(null);
    const [selectedStep, setSelectedStep] = useState('category');

    const [locationLat, setLocationLat] = useState(null);
    const [locationLong, setLocationLong] = useState(null);
    const [error, setError] = useState(null);
    const [outletId, setOutletId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [categorySelected, setSelectedCategory] = useState(null)
    const [serviceSelected, setSelectedService] = useState(null)

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (web_store_name) {
            getStoreDetails(web_store_name, state);
        }
    }, [web_store_name]);


    console.log('state', state);


    const getStoreDetails = useCallback(async (storeName, state) => {
        // setLoading(true);
        try {
            const response = await webCustomerBookingApi.getWebCustomerBookingStoreDetails(storeName);
            const storeDetails = response?.data;
            setLoading(false);
            if (isMounted()) {

                setOutletId(storeDetails?.id);
                if (state?.service) {
                    const { group_id, id } = state.service
                    const { services } = storeDetails


                    const foundGroup = services.find((groupObj) => groupObj?.id == group_id)
                    const foundService = foundGroup.services.find((serviceObj) => serviceObj?.id == id)

                    console.log('foundCategory', foundGroup);
                    console.log('foundservice'), foundService;


                    setSelectedCategory(foundGroup ?? null)
                    setSelectedService(foundService ?? null)
                }
                setStoreDetails(storeDetails);

                const [longitude, latitude] = storeDetails?.geo_location
                    .replace("POINT (", "")
                    .replace(")", "")
                    .split(" ")
                    .map((coord) => parseFloat(coord));

                setLocationLat(latitude);
                setLocationLong(longitude);

                if (storeDetails?.policy && storeDetails?.policy.length > 0) {
                    setPolicy(storeDetails?.policy);
                    setOpen(true);
                } else {
                    //setPolicy('');
                    setPolicy(null);
                    setOpen(false);
                };

                if (state?.service) {
                    setSelectedStep('category1')
                }

                if (location.pathname.endsWith('/about')) {
                    setTabValue("2");
                } else if (location.pathname.endsWith('/picture')) {
                    setTabValue("3");
                } else if (location.pathname.endsWith(`/klinik/${web_store_name}`)) {
                    setTabValue("1");
                }

                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setSnackbarOpen(true);
            //setError('Failed to load store details.');
            console.error('Error fetching store details:', err);
        }
    }, [isMounted]);

    if (loading) return <SplashScreen />;
    if (error) return <Typography>{error}</Typography>;

    if (!storeDetails) {
        return <Page404 />
    }

    const placeID = storeDetails.google_placeid;
    console.log("placeId>>>>>>>", placeID);

    const handleRevies = (place) => {
        console.log('hello,', place);
        window.open(`https://search.google.com/local/reviews?placeid=${place}`, "_blank")
    }


    const handleChange = (event, newValue) => {
        if (newValue === "4") {
            handleRevies(placeID);
        } else {
            setSelectedStep('category')
            setTabValue(newValue);
            // if (newValue === "1") navigate(`/klinik/${web_store_name}`);
            // if (newValue === "2") navigate(`/klinik/${web_store_name}/about`);
            // if (newValue === "3") navigate(`/klinik/${web_store_name}/picture`);
        }
    };

    const StyledTabs = styled(({ className, ...other }) => {
        return (
            <Tabs
                {...other}
                classes={{
                    root: className,
                    flexContainer: "flexContainer",
                    indicator: "indicator"
                }}
                variant="fullWidth"
                TabIndicatorProps={{ children: <span /> }}
                centered
            />
        );
    })({
        "& .indicator": {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            height: "4px",

            "& > span": {
                maxWidth: 120,
                width: "100%",
                backgroundColor: theme.palette.primary.main,
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
            }
        },
        "& .flexContainer": {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
            // width:'fit-content'
        },
        '&:hover': { color: 'primary.main' },
        '& .Mui-focused': { color: 'primary.main' },
    });


    return (
        <Container maxWidth='100%' disableGutters sx={{ height: '100vh', pl: 0, pr: 0, mb: 10 }}>

            <CustomizedSnackbars
                open={snackbarOpen}
                message={"Failed to load Data..."}
                onClose={handleSnackbarClose}
            />



            {storeDetails && (
                <>
                    <Header storeDetails={storeDetails} from={state?.from ?? null} />
                    <StoreInfo storeDetails={storeDetails} />
                    <TabContext value={tabValue}  >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', }} ml={0} mr={0} pl={0} pr={0} mt={1}>
                            {/* <TabList variant='fullWidth' centered onChange={handleChange} aria-label="Tabs with routing"> */}
                            <StyledTabs value={tabValue} onChange={handleChange} aria-label="Tabs with routing">
                                <Tab label="Booking" value="1" disableRipple={true} sx={{
                                    textTransform: 'none', fontSize: { xs: '0.90rem', md: '1.1rem' }, mx: { sm: 3, md: 8, lg: 13 }, fontWeight: tabValue === "1" ? 700 : 400, ml: 0, mr: 0,
                                }} />
                                <Tab label="About Us" value="2" disableRipple={true} sx={{ textTransform: 'none', fontSize: { xs: '0.90rem', md: '1.1rem' }, mx: { sm: 3, md: 8, lg: 13 }, fontWeight: tabValue === "2" ? 700 : 400, ml: 0, mr: 0 }} />
                                <Tab label="Pictures" value="3" disableRipple={true} sx={{ textTransform: 'none', fontSize: { xs: '0.90rem', md: '1.1rem' }, mx: { sm: 3, md: 8, lg: 13 }, fontWeight: tabValue === "3" ? 700 : 400, ml: 0, mr: 0 }} />
                                {placeID === null || placeID === undefined ? null : (
                                    <Tab label="Reviews" value="4" disableRipple={true} sx={{ textTransform: 'none', fontSize: { xs: '0.90rem', md: '1.1rem' }, mx: { sm: 3, md: 8, lg: 13 }, fontWeight: tabValue === "4" ? 700 : 400, ml: 0, mr: 0 }} />
                                )}
                            </StyledTabs>
                            {/* </TabList> */}
                        </Box>

                    </TabContext>


                    <>
                        <Grid2 container sx={{ display: 'flex', flexDirection: 'row', pl: { xs: 2, md: 8 }, pr: { xs: 2, md: 8 }, mb: '160px' }}>
                            <Grid2 item size={{ xs: 12, lg: 8 }} sx={{ display: 'flex', flexDirection: 'row', }} >
                                {

                                    tabValue == 1 &&
                                    <Booking
                                        web_store_name={web_store_name}
                                        selectedStep={selectedStep}
                                        setSelectedStep={setSelectedStep}
                                        outletId={outletId}
                                        setOutletId={setOutletId}
                                        locationLat={locationLat}
                                        setLocationLat={setLocationLat}
                                        locationLong={locationLong}
                                        setLocationLong={setLocationLong}
                                        storeDetails={storeDetails}
                                        open={open}
                                        setOpen={setOpen}
                                        categorySelected={categorySelected}
                                        serviceSelected={serviceSelected}

                                    // context={{
                                    //     web_store_name,
                                    //     selectedStep,
                                    //     setSelectedStep,
                                    //     outletId,
                                    //     setOutletId,
                                    //     locationLat,
                                    //     setLocationLat,
                                    //     locationLong,
                                    //     setLocationLong,
                                    //     storeDetails,
                                    //     open,
                                    //     setOpen,
                                    //     categorySelected,
                                    //     serviceSelected
                                    // }}
                                    />
                                }
                                {
                                    tabValue == 2 &&
                                    <AboutUsNew storeDetails={storeDetails} />
                                }
                                {
                                    tabValue == 3 &&
                                    <Pictures storeDetails={storeDetails} />
                                }
                            </Grid2>
                            <Grid2 item size={{ xs: 0, lg: 4 }} sx={{ display: { xs: 'none', lg: 'flex' }, flexDirection: 'row', justifyContent: 'flex-end', alignContent: 'center', marginTop: 10 }}>
                                {!isMobile &&
                                    <Hours storeDetails={storeDetails} />
                                }
                            </Grid2>
                        </Grid2>
                    </>

                    {
                        open && <ConfirmationPolicy open={open} handleClose={handleClose} policy={policy} />
                    }
                </>
            )}


        </Container>
    );
}

export default StoreHomePage;
