import { Box, Divider, Typography, Grid2 } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';


function Navigator({ web_store_name, storeDetails }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedTab, setSelectedTab] = useState(location.pathname);

    const placeID = storeDetails.google_placeid;
    console.log("placeId" , placeID);
    

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        navigate(tab);
    };

    const handleRevies = (place) => {
        console.log('hello,', place);
        console.log('selectedTab',selectedTab);
    
            window.open(`https://search.google.com/local/reviews?placeid=${place}`, "_blank")
        
    }
    
    return (
   
            <Box
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: { xs: 3, sm: 10, md: 25 },
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: { xs: '0 0 -10px 0', md: '-25px 0 -10px 0' },
                }}
            >

                <Grid2 xs={3}>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: { xs: '14px', sm: '18px', md: '20px' },
                            color: selectedTab === `/business/${web_store_name}` ? 'primary.main' : 'primary.text',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleTabChange(`/business/${web_store_name}`)}
                    >
                        {t('Booking')}
                    </Typography>
                    {selectedTab === `/business/${web_store_name}` && (
                        <Divider
                            orientation="horizontal"
                            sx={{
                                borderColor: 'primary.main',
                                borderTopWidth: '2px',
                                margin: { xs: '0 -5px', sm: '0 -10px', md: '0 -15px' },
                                borderRadius: '5px 5px 0 0',
                            }}
                            variant="fullWidth"
                            flexItem
                        />
                    )}
                </Grid2>


                <Grid2 xs={3}>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: { xs: '14px', sm: '18px', md: '20px' },
                            color: selectedTab === `/business/${web_store_name}/about` ? 'primary.main' : 'primary.text',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleTabChange(`/business/${web_store_name}/about`)}
                    >
                        {t('AboutUs')}
                    </Typography>
                    {selectedTab === `/business/${web_store_name}/about` && (
                        <Divider
                            orientation="horizontal"
                            sx={{
                                borderColor: 'primary.main',
                                borderTopWidth: '2px',
                                margin: { xs: '0 -5px', sm: '0 -10px', md: '0 -15px' },
                                borderRadius: '5px 5px 0 0',
                            }}
                            variant="fullWidth"
                            flexItem
                        />
                    )}
                </Grid2>


                <Grid2 xs={3}>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: { xs: '14px', sm: '18px', md: '20px' },
                            color: selectedTab === `/business/${web_store_name}/picture` ? 'primary.main' : 'primary.text',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleTabChange(`/business/${web_store_name}/picture`)}
                    >
                        {t('Pictures')}
                    </Typography>
                    {selectedTab === `/business/${web_store_name}/picture` && (
                        <Divider
                            orientation="horizontal"
                            sx={{
                                borderColor: 'primary.main',
                                borderTopWidth: '2px',
                                margin: { xs: '0 -5px', sm: '0 -10px', md: '0 -15px' },
                                borderRadius: '5px 5px 0 0',
                            }}
                            variant="fullWidth"
                            flexItem
                        />
                    )}
                </Grid2>


               { placeID === null || placeID === undefined ? null :
                <Grid2 xs={3}>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: { xs: '14px', sm: '18px', md: '20px' },
                            color: selectedTab === `/business/${web_store_name}/review` ? 'primary.main' : 'primary.text',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleRevies(placeID)}
                    >
                        {t('Reviews')}
                    </Typography>
                    {selectedTab === `/business/${web_store_name}/review` && (
                        <Divider
                            orientation="horizontal"
                            sx={{
                                borderColor: 'primary.main',
                                borderTopWidth: '2px',
                                margin: { xs: '0 -5px', sm: '0 -10px', md: '0 -15px' },
                                borderRadius: '5px 5px 0 0',
                            }}
                            variant="fullWidth"
                            flexItem
                        />
                    )}
                </Grid2>}
            </Box>
       
    )
}

export default Navigator
