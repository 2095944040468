import React, { useEffect, useState } from 'react';
import { Grid2, TextField, Typography, Box, InputAdornment, Divider, FormGroup, FormControlLabel, Checkbox, FormHelperText, Link } from '@mui/material';
import Buttons from './button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Autocomplete from 'react-google-autocomplete';
import { LoadScript } from '@react-google-maps/api';
import { webCustomerBookingApi } from '../apis/web-customerBooking';
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import { t } from 'i18next';
import CustomizedSnackbars from '../components/customizedSnackbars';
import { useMediaQuery } from '@mui/material';

function Information({ storeDetails, handleBackPress, handleotpPage, customerData, setCustomerData, setOpen, otpStatus, setOtpStatus, selectedService }) {

  const [customerFirstName, setCustomerFirstName] = useState(customerData.customerFirstName);
  const [customerLastName, setCustomerLastName] = useState(customerData.customerLastName);
  const [customerEmail, setCustomerEmail] = useState(customerData.customerEmail);
  const [isValid, setIsValid] = useState(true);
  const [isTouched, setIsTouched] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [customerAddress, setCustomerAddress] = useState(customerData.customerAddress);
  const [phoneNumber, setPhoneNumber] = useState(customerData.phoneNumber);
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [zipCode, setZipCode] = useState(customerData.zipCode);
  const [city, setCity] = useState(customerData.city);
  const [numberEntered, setNumberEntered] = useState(false);
  const [requestOtpLoader, setRequesstOtpLoader] = useState(false);
  const [checkedState, setCheckedState] = useState({
    cancelPolicies: false,
    marketingPermission: false,
    confirmationPolicies: false,
  });
  const [alerts, setAlerts] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:900px)');


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Information
  const handleCustomerFirstNameChange = (event) => {
    const newValue = event.target.value;
    setCustomerFirstName(newValue);
    setCustomerData((prev) => ({ ...prev, customerFirstName: newValue }));
  };

  const handleCustomerLastNameChange = (event) => {
    const newValue = event.target.value;
    setCustomerLastName(newValue);
    setCustomerData((prev) => ({ ...prev, customerLastName: newValue }));
  };

  const handleCustomerAddressChange = (place) => {
    console.log('place', place);

    if (place) {
      const newValue = place.formatted_address

      setCustomerData((prev) => ({ ...prev, customerAddress: newValue }));

      const addressComponents = place?.address_components ?? [];
      let newCity = '';
      let newZipCode = '';
      let route = ''

      addressComponents.forEach(component => {
        if (component.types.includes('route')) {
          route = component?.long_name
          setCustomerAddress(component?.long_name);
        }

        if (component.types.includes('locality') || component.types.includes('administrative_area_level_1')) {
          newCity = component.long_name; // Get the city
        }
        if (component.types.includes('postal_code')) {
          newZipCode = component.long_name; // Get the zip code
        }
      });

      // Set the city and zip code directly
      setCity(newCity);
      setZipCode(newZipCode);
      setCustomerData((prev) => ({
        ...prev,
        customerAddress: route,
        city: newCity,
        zipCode: newZipCode,
      }));
    }
  }

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setCustomerAddress(newValue);
    setCustomerData((prev) => ({ ...prev, customerAddress: newValue }));

    // Clear city and zip code if address is empty
    if (newValue === '') {
      setCity('');
      setZipCode('');
      setCustomerData((prev) => ({
        ...prev,
        city: '',
        zipCode: '',
      }));
    }
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setCustomerData((prev) => ({ ...prev, phoneNumber: value }));
  };

  const checkPhoneNumber = (number) => {
    const digitsOnly = String(number).replace(/\D/g, '');
    return digitsOnly.length <= 8 ? digitsOnly : digitsOnly.slice(0, 8);
  };

  useEffect(() => {
    setIsValidNumber(checkPhoneNumber(phoneNumber));
  }, [phoneNumber]);

  const handleZipCodeChange = (event) => {
    const newValue = event.target.value;
    setZipCode(newValue);
    setCustomerData((prev) => ({ ...prev, zipCode: newValue }));
  };

  const handleCityChange = (event) => {
    const newValue = event.target.value;
    setCity(newValue);
    setCustomerData((prev) => ({ ...prev, city: newValue }));
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validateEmail = (email) => {
    if (email === '') {
      setIsValid(true);
      setHelperText('');
    } else if (emailRegex.test(email)) {
      setIsValid(true);
      setHelperText('');
    } else {
      setIsValid(false);
      setHelperText(t('EnterAVlidEmailAddress'));
    }
  };
  const handleCustomerEmailChange = (event) => {
    const email = event.target.value;
    setCustomerEmail(email);
    setCustomerData((prev) => ({ ...prev, customerEmail: email }));

    if (isTouched) {
      validateEmail(email);
    }
  };
  const handleBlur = () => {
    setIsTouched(true);
    validateEmail(customerEmail);
  };

  const addAlert = (severity, message) => {
    const newAlert = { id: new Date().getTime(), severity, message };
    setAlerts([newAlert]);
  };


  const requestOtp = async () => {
    const name = customerFirstName;
    const country_code = "+45";
    const phone_number = phoneNumber.slice(2);
    const email = customerEmail;
    const address = customerAddress;
    const marketing_permission = checkedState.marketingPermission;

    setRequesstOtpLoader(true);
    try {
      const response = await webCustomerBookingApi.requestOTP(name, country_code, phone_number, email, address, marketing_permission);
      console.log('HERE IS THE RESPONCE ', response);
      addAlert('success', t('OTPSentToYourMobileNumber'));
      setNumberEntered(true);
      setOtpStatus('success');
      setRequesstOtpLoader(false);
      return { success: true };
    } catch (err) {
      addAlert('error', 'failed to send otp');
      setNumberEntered(false);
      setOtpStatus('failure');
      setSnackbarOpen(true);
      //toast.error(t('FailedToSentOTP'));
      setRequesstOtpLoader(false);
      return { success: false };
    }
  };

  const handleCheckboxChangePolicy = (event) => {
    const updatedCheckedState = {
      ...checkedState,
      [event.target.name]: event.target.checked,
    };

    setCheckedState(updatedCheckedState);

    setCustomerData((prev) => ({ ...prev, checkedState: updatedCheckedState }));
  };


  return (
    <Box sx={{ pl: 0, pr: 0, ml: 0, mr: 0, mt: 0, flex: 1 }} flexDirection={'column'} flex={1} justifyContent={'center'}>
      <CustomizedSnackbars
        open={snackbarOpen}
        message={"Failed to send OTP..."}
        onClose={handleSnackbarClose}
      />


      <Buttons variant={'outlined'} borderRadius={6} borderWidth={3} startIcon={<ArrowBackIosIcon />} onclick={handleBackPress} children={selectedService?.cancellation_offer_booking_source_id !== null ? t('Backtoservices') : t('Backtotime')} />

      <Typography
        sx={{ fontWeight: 'bold', color: 'primary.text', paddingBottom: '30px', textAlign: 'left', mt: 4, fontSize: { xs: '1.1rem' } }}>
        {t('Information')}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', backgroundColor: 'white', }}>
        <Grid2 gap={3} sx={{ display: 'flex', flexDirection: 'column', '& .MuiOutlinedInput-root': { borderRadius: { xs: '20px', md: '15px' }, '& fieldset': { border: '2px solid #f7bd98' }, '&:hover fieldset': { border: '2px solid #f7bd98' }, '&.Mui-focused fieldset': { border: '2px solid #f7bd98' } }, '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, width: '100%' }}>

          {/* First Name */}
          <Grid2 size={{ xs: 12, md: 4 }}>
            <Typography color='primary.text'>{t('Firstname')}</Typography>
            <TextField
              autocomplete="given-name"
              placeholder={t('Firstname')}
              value={customerFirstName}
              onChange={handleCustomerFirstNameChange}
              size="small"

              sx={{ '& input::placeholder': { color: '#747474', fontSize: '1rem', opacity: 1 }, width: { xs: '80%', md: '100%' }, fontSize: '1rem' }}
            />
          </Grid2>

          {/* Last Name */}
          <Grid2 size={{ xs: 12, md: 4 }}>
            <Typography color='primary.text'>{t('Lastname')}</Typography>
            <TextField
              autocomplete="family-name"
              placeholder={t('Lastname')}
              value={customerLastName}
              onChange={handleCustomerLastNameChange}
              size="small"
              sx={{ '& input::placeholder': { color: '#747474', fontSize: '1rem', opacity: 1 }, width: { xs: '80%', md: '100%' }, fontSize: '1rem' }}
            />
          </Grid2>

          {/* Address and City/Zip Code */}
          <Grid2 item size={12} gap={3} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <Grid2 item size={{ xs: 12, md: 4 }} >
              <Typography color='primary.text'>{t('Address')}</Typography>
          
               
                  <Autocomplete
                  name="address"
                  apiKey={'AIzaSyCI2u5iSXIqE6wnNPAIjXGpT-SGc2c22SI'}
                  onPlaceSelected={handleCustomerAddressChange}
                  options={{
                    types: ['address'],
                    componentRestrictions: { country: 'dk' }
                  }}
                  placeholder="Address"
                  value={customerAddress}
                  onChange={handleInputChange}
                  style={{
                    width: '100%',
                    height: '40px',
                    paddingLeft: '16px',
                    borderRadius: '15px',
                    border: '2px solid #ffbc98',
                    outline: 'none',
                    fontSize: '1rem'
                  }}
                  autoComplete="address-line1"
                />
                
            
            </Grid2>
            <Grid2 item size={{ xs: 12, md: 6 }} gap={2} sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'row' } }}>
              <Grid2 item size={{ xs: 3, md: 2 }}>
                <Typography color='primary.text'>{t('ZipCode')}</Typography>
                <TextField
                  name="postcode"
                  // disabled={true}
                  autocomplete="postal-code"
                  value={zipCode}
                  onChange={handleZipCodeChange}
                  placeholder={t('ZipCode')}
                  size="small"
                  sx={{ '& input::placeholder': { color: '#747474', fontSize: '1rem', opacity: 1 }, width: { xs: '100%', md: '100%' }, fontSize: '1rem' }}
                />
              </Grid2>
              <Grid2 item size={{ xs: 6, md: 4 }}>
                <Typography color='primary.text'>{t('City')}</Typography>
                <TextField
                  name="city"
                  autocomplete="address-level2"
                  // disabled={true}
                  value={city}
                  onChange={handleCityChange}
                  placeholder={t('City')}
                  size="small"
                  sx={{ '& input::placeholder': { color: '#747474', fontSize: '1rem', opacity: 1 }, width: { xs: '100%', md: '100%' }, fontSize: '1rem' }}
                />
              </Grid2>
            </Grid2>
          </Grid2>

          {/* Phone Number and Email */}
          <Grid2 item size={12} gap={3} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <Grid2 item size={{ xs: 12, md: 4 }}>
              <Typography variant='body1' color='primary.text'>{t('Phonenr')}</Typography>
              <PhoneInput
                country={'dk'}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                specialLabel={null}
                inputStyle={{
                  width: isMobile ? '80%' : '100%',
                  height: '40px',
                  borderRadius: '15px',
                  border: '2px solid #ffbc98',
                  outline: 'none',
                  boxShadow: 'none',
                  padding: '10px',
                  fontSize: '1rem'
                }}
                dropdownStyle={{
                  borderRadius: '4px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
                }}
                containerStyle={{
                  justifyContent: 'center',
                  alignSelf: 'center',
                  borderRadius: '10px',
                  overflow: 'hidden',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <span style={{ userSelect: 'none', color: '#747474' }}>+45</span>
                        <Divider orientation="vertical" flexItem sx={{ borderColor: '#f7bd98', height: '38px', margin: '-7px 0 -10px -4px' }} />
                      </Box>
                    </InputAdornment>
                  ),
                }}
                disableDropdown={true}
                placeholder="+45 12 34 56 78"
              />
            </Grid2>
            <Grid2 item size={{ xs: 12, md: 4 }} >
              <Typography color='primary.text'>{t('Email')}</Typography>
              <TextField

                placeholder={t('Email')}
                value={customerEmail}
                onChange={handleCustomerEmailChange}
                onBlur={handleBlur}
                size="small"
                sx={{ '& input::placeholder': { color: '#747474', fontSize: '1rem', opacity: 1 }, width: { xs: '80%', md: '100%' }, fontSize: '1rem' }}
              />
              <div style={{ minHeight: '35px' }}>
                {isTouched && !isValid && customerEmail && (
                  <FormHelperText error>
                    {helperText}
                  </FormHelperText>
                )}
              </div>
            </Grid2>
          </Grid2>

          {/* Checkbox Policies */}
          <Grid2 item xs={12} align="left" sx={{ paddingRight: '20px' }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox name="cancelPolicies" checked={checkedState.cancelPolicies} onChange={handleCheckboxChangePolicy} />}
                label={<Typography variant="body2">{t('IHaveReadAndAcceptedThe')} <Link style={{ color: 'blue' }} href="https://fiind.app/" target="_blank" rel="noopener">{t('conditions')}</Link></Typography>}
              />
              <FormControlLabel
                control={<Checkbox name="marketingPermission" checked={checkedState.marketingPermission} onChange={handleCheckboxChangePolicy} />}
                label={<Typography variant="body2">{t('IWishToReceiveRelevantInformationViaEMailAndSMS')}</Typography>}
              />
              {storeDetails?.policy && (
                <FormControlLabel
                  control={<Checkbox name="confirmationPolicies" checked={checkedState.confirmationPolicies} onChange={handleCheckboxChangePolicy} />}
                  label={<Typography variant="body2">{t('CancellationPolicy')} <span onClick={(event) => { event.stopPropagation(); setOpen(true) }} style={{ color: 'blue' }}>{t('SeeCondition')}</span></Typography>}
                />
              )}
            </FormGroup>
          </Grid2>

          {/* Next Button */}
          <Grid2 item xs={12} align="left" sx={{ marginTop: 0 }}>
            <Buttons
              variant="contained"
              onclick={() => {
                if (phoneNumber && customerFirstName) {
                  requestOtp();
                  handleotpPage();
                } else {
                  setNumberEntered(false);
                }
              }}
              display={'flex'}
              justifyContent={{ xs: 'center', md: 'space-between' }}
              borderRadius={{ xs: '10px', md: '15px' }}
              width={{ xs: '92vw', md: 110 }}
              loading={requestOtpLoader}
              disabled={!isValidNumber || !isValid || !customerFirstName || !customerLastName || !customerEmail || !customerAddress || !zipCode || !city || !phoneNumber || !checkedState.cancelPolicies || !(storeDetails.policy ? checkedState.confirmationPolicies : true)}
              endIcon={isMobile || isTablet ? null : (
                <Box sx={{ display: 'flex', alignItems: 'end' }}>
                  <ArrowForwardIosIcon sx={{ fontSize: 'large' }} />
                </Box>
              )}
              fontcolor={!isValidNumber || !isValid || !customerFirstName || !customerLastName || !customerEmail || !customerAddress || !zipCode || !city || !phoneNumber || !checkedState.cancelPolicies || !(storeDetails.policy ? 'black' : 'white')}
              children={t('Next')}
            />
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
}

export default Information;
