import { Box, Grid, Grid2, Skeleton } from "@mui/material";


const SkeletonCard = () => 
 ( <Grid2 container size={12} spacing={{xs:1,md:2}} >
       
            {Array.from({ length: 40 }).map((_, index) => (
                 <Grid2 item size={{xs:2, md:2}} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: {xs:'16px' ,sm:'16px' ,md:'12px'}}} elevation={3}>
                <Skeleton key={index} variant="rectangular" height={50} width={60} sx={{ borderRadius: '15px', marginBottom: 1 }}   />
                </Grid2>
            ))}
     
    </Grid2>

        )
//     {

// for (let step = 0; step < 100; step++) {
  
//         return  (<Grid  size = {{xs:2, md:2}}  sx={{ display: 'flex', justifyContent: 'center',  fontWeight: 'bold',fontSize: '7px', height: '50px',  borderRadius: '15px' }}>
//           <Skeleton variant="rectangular" height={50} width={50} sx={{ borderRadius: '15px', marginBottom: 1 }}  />
//           </Grid>)
     
//     }

// }

export default SkeletonCard;