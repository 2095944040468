import React, { useCallback, useEffect, useState } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';


import { Box, Divider, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Timeslot from './timeslot';
import Buttons from './button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import theme from '../theme';
import dayjs from 'dayjs';
import { useMounted } from '../hooks/use-mounted';
import { webCustomerBookingApi } from '../apis/web-customerBooking';
import { t } from 'i18next';
import CustomizedSnackbars from '../components/customizedSnackbars';
import moment from 'moment';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/en-gb';

dayjs.extend(isoWeek);
dayjs.locale('en-gb');

function Calendar({ handleDateSelect, bookingDetails, handlenextPage, handleSlotSelect, handleBackPress, storeDetails ,schedule,setschedule, selectedSlot, selectedService}) {

    console.log('bookingDetails',bookingDetails);
    
    const isMounted = useMounted();
    const [timeSlotsLoading, setTimeSlotsLoading] = useState(true);
    const [timeSchedule, setTimeSchedule] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('Failed to load Timeslots...');
    const [scheduleData, setScheduleData] = useState([]);
    const [isStoreClosed, setIsStoreClosed] = useState(false)

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    console.log('bookingDetails selected service', selectedService);

    // Calendar
    const dayMap = {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    };

    const shouldDisableDate = useCallback((date) => {
        if (!storeDetails || !storeDetails.schedules) return true;
        if (date.isBefore(dayjs(), 'day')) return true;
        console.log('day', moment(date).format('dddd'));
        

        const dayName = dayMap[date.day()];
        const schedule = storeDetails.schedules.find((sch) => sch.day === dayName);

      


        return schedule?.is_closed || false;
    }, [storeDetails]);

    function generateTimeSlots(startHour , startMinute, endHour, endMinute , storeTiming){
        // console.log('storeTiming', storeTiming);
        
            const timeSlots = [];
            for (let hour = startHour; hour <= endHour; hour++) {
                const minuteStart = hour === startHour ? startMinute : 0;
                const minuteEnd = hour === endHour ? endMinute : 55;
                
                let data = []
        
                for (let minute = minuteStart; minute <= minuteEnd; minute += 5) {
                    const formattedMinute = minute < 10 ? `0${minute}` : minute;
    
                    // we will not add for last slot i.e when store close
                    // if(storeTiming){
                      const formattedHour = hour < 10 ? `0${hour}` : hour;
                      if(storeTiming?.close_time != `${formattedHour}:${formattedMinute}:00` ){
                        data.push(`${formattedHour}:${formattedMinute}`);
    
                      }
                    // }
                }
    
                if(data.length){
                  timeSlots.push(  {
                    title: hour,
                    data,
                  })
                }
              
            }
            console.log('timeSlots', timeSlots);
            setScheduleData(timeSlots)
            return timeSlots;
        };

    useEffect(() => {
        const fetchTimingDetails = async () => {
            setIsStoreClosed(false)
            if (bookingDetails.outletId && bookingDetails.selectedService  && bookingDetails.selectedDate) {
                setTimeSlotsLoading(true);
                const formattedDate = dayjs(bookingDetails.selectedDate).format('YYYY-MM-DD');
                try {
                    const timingDetails = await webCustomerBookingApi.getTiming(bookingDetails.selectedService.id, bookingDetails?.selectedEmployee?.id , formattedDate, bookingDetails.outletId);
                    if (isMounted()) {
                        const timeList = timingDetails?.data.map((slotTime)=>slotTime.split(' - ')[0])
                        setTimeSchedule(timeList);
                        setschedule(timingDetails.data);
                        setTimeSlotsLoading(false);
                    }
                    setIsStoreClosed(false)
                } catch (err) {
                    setIsStoreClosed(true)
                    const{detail} = err
                    setSnackbarMsg(detail)
                    setSnackbarOpen(true);
                    console.error(err);
                }
            }
        };
        if(storeDetails?.schedules){
            const formattedDate = dayjs(bookingDetails.selectedDate).format('YYYY-MM-DD');
            console.log('date',moment(formattedDate, 'YYYY-MM-DD').format('dddd'));

            const selectedDay = moment(formattedDate, 'YYYY-MM-DD').format('dddd')
            
            const storeOpeningObject =   storeDetails?.schedules.find((scheduleObj)=> selectedDay == scheduleObj?.day)
            console.log('storeOpeningObject',storeOpeningObject);
            if(storeOpeningObject){
              if(!storeOpeningObject?.is_closed){
                console.log('storeOpeningObject',storeOpeningObject?.open_time.split(':')[0]);
                const startHour =  parseInt(storeOpeningObject?.open_time.split(':')[0]);
                const startMin = parseInt(storeOpeningObject?.open_time.split(':')[1]);
    
                const closeHour = parseInt(storeOpeningObject?.close_time.split(':')[0]);
                const closeMin = parseInt(storeOpeningObject?.close_time.split(':')[1]);
                
                // setStoreTimming(storeOpeningObject)
                generateTimeSlots(startHour,0, closeHour,0, storeOpeningObject)
               
              }else{
                generateTimeSlots(6,0, 21,55, null)
             
    
              }
            }
            
          }


        fetchTimingDetails();
    }, [bookingDetails.outletId, bookingDetails.selectedService, bookingDetails.selectedEmployee.id, bookingDetails.selectedDate, isMounted]);

    return (
        <Box sx={{pl:0, pr:0, ml:0, mr:0,mt:0,flex:1}} flexDirection={'column'} flex={1} justifyContent={'center'}>
             <CustomizedSnackbars
                open={snackbarOpen}
                message={snackbarMsg}
                onClose={handleSnackbarClose}
            />
        

            <Buttons variant={'outlined'} borderRadius={6} borderWidth={3}  startIcon={<ArrowBackIosIcon />} onclick={handleBackPress} children={selectedService .is_equipment_service ? t('Backtoservices') :  t('Backtoemployee')} />
     
             <Typography
               sx={{ fontWeight: 'bold', color: 'primary.text', paddingBottom: '30px',  textAlign: 'left',mt:4,  fontSize:{xs:'1.1rem'}}}>
               {t('Selectdateandtime')}
           </Typography>

            <Box sx={{
                  
                  display: 'flex',
                  flexDirection: 'column',
               
                  justifyContent:'center',
                  alignItems:'center',
                  maxWidth: { xs: '100%', md: '90%' },
            }}
              
            >
                <Box  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: `3px solid ${theme.palette.primary.main}`,
                    borderRadius: '15px',
                    
                    // padding: '4px',
                    backgroundColor: 'white',
                    flex:1,
                    mb:4,
                    // marginLeft: { xs: '10px', md: '20px' },
                }} >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <Box sx={{ display: 'flex', justifyContent: 'center' , alignItems:'center'}}>
                        <DateCalendar
                            defaultValue={dayjs()}
                            value={bookingDetails?.selectedDate}
                            shouldDisableDate={shouldDisableDate}
                            sx={{
                                width: { xs: '320px' },
                                mt:2,
                                '& .Mui-selected': {
                                    backgroundColor: 'primary.main',
                                    color: 'white',
                                    fontWeight: 'bold',
                                   
                                },
                                '&:hover': { color: 'primary.main' },
                                '& .Mui-focused': { color: 'primary.main' },
                            }}
                            onChange={(date) => {
                                handleDateSelect(date);
                            }}
                        />
                    </Box>
                </LocalizationProvider>

                <Divider
                    orientation={{ xs: 'horizontal', md: 'vertical' }}
                    sx={{
                        borderColor: grey,
                        borderRightWidth: { md: '2px' },
                        borderTopWidth: { xs: '2px', md: 0 },
                        borderRadius: '10px',
                    }}
                    variant="middle"
                    flexItem
                />

                   

                  { isStoreClosed? 
                    <Stack width={{xs :  370 , md:300}} justifyContent={'center'} alignItems={'center'}>

                    <Typography variant='body1' sx={{color:'text.secondary'}}>{t('clicnic_is_closed')}</Typography>
                    </Stack>
                    :
                    <Timeslot availableSlotTimeList={timeSchedule} schedules={scheduleData} isLoading={timeSlotsLoading} bookingDetails={bookingDetails} storeDetails={storeDetails} handleSlotSelect={handleSlotSelect} />


                    } 
      
                    </Box>
           
                    

                <Buttons
                    variant={'contained'}
                    onclick={handlenextPage}
                    children={t('Next')}
                    disabled={selectedSlot ? false : true}
                    width={ {xs: '92vw',md:300 }}
                    borderRadius={2}
                    fontcolor = {selectedSlot ? 'white' : 'black'}
                    // margin={{ xs: '20 0 0 0', md: '20 0 0 100px' }}
                    fontWeight={'bold'}
                />
                 </Box>
        </Box>
    );
}

export default Calendar;
