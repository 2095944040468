import React, { useEffect, useState } from 'react';
import { Box, Container, Grid2, ImageList, ImageListItem, Portal } from '@mui/material';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";



function Pictures({storeDetails}) {


  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const [storeImages, setStoreImages] = useState( [])

  const {images} = storeDetails

  useEffect(()=>{
    let imgArray = []
    images.map((imageObj)=>{
      imgArray.push({src:  `https://fiind-dev.s3.amazonaws.com/${imageObj?.image}`})
    })

    // setStoreImages([  {
    //   src: 'https://images.unsplash.com/photo-1549388604-817d15aa0110',
    //   title: 'Bed',
    // },
    // {
    //   src: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
    //   title: 'Books',
    // },
    // {
    //   src: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
    //   title: 'Sink',
    // },
    // {
    //   src: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
    //   title: 'Kitchen',
    // },
    // {
    //   src: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
    //   title: 'Blinds',
    // },
    // {
    //   src: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
    //   title: 'Chairs',
    // },
    // {
    //   src: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
    //   title: 'Laptop',
    // },
    // {
    //   src: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61',
    //   title: 'Doors',
    // },
    // {
    //   src: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7',
    //   title: 'Coffee',
    // },
    // {
    //   src: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee',
    //   title: 'Storage',
    // },
    // {
    //   src: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
    //   title: 'Candle',
    // },
    // {
    //   src: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
    //   title: 'Coffee table',
    // },])

    setStoreImages(imgArray)
  },[images])

  console.log('storeImages' ,storeImages);
  

  return (

  <>
      <Container sx={{pl:0, pr:0}}>
    <Box sx={{
      display: 'flex',
     
    
      marginBottom: '20px',
      alignItems: { xs: 'left', md: 'center' },
      flex:0.3,
      ml:0,
      mr:0,
      mt:{xs:2,md:8},
  
      // marginLeft: { xs: '20px', sm: '30px', md: '0px' },
      // padding: { xs: '40px', sm: '30px', md: '40px' },
    }}>
      {/* <Grid2 justifyContent="center"> */}


      <Box sx={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
      <ImageList variant="masonry" cols={3} gap={8}>
        {storeImages.map((item, index) => (
          <ImageListItem key={item.src} >
            <img
              onClick={()=>{setPhotoIndex(index), setIsOpen(true)}}
              srcSet={`${item.src}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.src}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>

        {/* <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
          style={{ display: 'flex', flexDirection: { xs: 'column', xl: 'row' }, gap: '10px', marginLeft: { md: '10px', xl: '30px' }, marginRight: { md: '10px', xl: '40px' } }}
        >
          {storeImages.map((image, index) => (
            <Box
              key={index}
              onClick={()=>{setPhotoIndex(1), setIsOpen(true)}}
              component="img"
              src={image?.src}
              alt={`img-${index}`}
              sx={{ width: '100%', marginBottom: '16px', borderRadius: '10px', cursor: 'pointer' }}
            />
          ))}
        </Masonry> */}
      {/* </Grid2> */}
    </Box>
    </Container>
    {isOpen &&
    <Lightbox
    index={photoIndex}
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={storeImages}
        plugins={[Zoom]}
        />
    }
</>
  );
}

export default Pictures;
