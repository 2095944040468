import { Box, Container, Grid2, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { t } from 'i18next';
import { FiGlobe, FiFacebook, FiPhone, FiInstagram } from "react-icons/fi";


function AboutUsNew({storeDetails}) {

  const websiteUrl = storeDetails?.website.startsWith('http')
    ? storeDetails.website
    : `https://${storeDetails?.website}`;

    const instagramUrl = storeDetails?.instagram;
    const instagramUsername = instagramUrl ? instagramUrl.split('/').pop() : '';
    
  return (
    <Container sx={{pl:0, pr:0}}>
   
      <Box sx={{
        display: 'flex',
        flexDirection: {xs:'column-reverse', md:'column'},
        justifyContent: { xs: 'space-between', md: 'space-between' },
        // marginBottom: '20px',
        alignItems: { xs: 'left', md: 'flex-start' },
     
        mt:{xs:2,md:8},
        height:'100%',
        // marginLeft: { xs: '20px', sm: '30px', md: '0px' },
        // padding: { xs: '40px', sm: '30px', md: '40px' },
      }}>
     
  
          <Stack>
            <Typography
            variant='h6'
                sx={{ fontWeight: 'bold',  textAlign: 'left'}}>
                {t('AboutUs')}
            </Typography>

            <Typography whiteSpace="pre-line" variant='body1' sx={{color:'#545454', mt:2}}>
            {storeDetails?.about} 
            </Typography>
            </Stack>
          
          
          {/* <Stack sx={{mb:6}} > */}
            <Box width={'100%'} sx={{mb:6}}>
            <Typography
              variant='h6'
                sx={{ fontWeight: 'bold', display:{xs:'flex', md:'none'},  textAlign: 'left', fontSize: { xs: '18px', md: '20px' } , mb:2}}>
                {t('ContactInformation')}
            </Typography>
            <Grid2 container size={{xs:12, md:12}} >
              {
          storeDetails?.website &&
                <Grid2  size={{xs:12, md: 4}} >
                  <Link href={websiteUrl} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'primary.socialmedia' }}>
                    <Stack sx={{flex:1 , flexDirection: 'row ', alignItems: 'center' }}>
                      <FiGlobe color='primary.socialmedia' style={{ fontSize: '25px', }} />
                      <Typography sx={{ fontWeight: 'bold',  pl:2 }} variant='subtitle1'>{storeDetails?.website}</Typography>
                    </Stack>
                  </Link>
            </Grid2>
            }
            {
              storeDetails?.phone_number &&
              <Grid2  size={{xs:12, md: 4}}>
                  <Link href={`tel:${storeDetails?.country_code}${storeDetails?.phone_number}`} sx={{ textDecoration: 'none', color: 'primary.socialmedia' }}>
                    <Stack sx={{flex:1 , flexDirection: 'row ', alignItems: 'center' }}>
                      <FiPhone color='primary.socialmedia' style={{ fontSize: '25px' }} />
                      <Typography sx={{ color: 'primary.socialmedia', fontWeight: 'bold',  pl:2 }}  variant='subtitle1'>{storeDetails?.phone_number}</Typography>
                    </Stack>
                  </Link>
              </Grid2>
            }
            {
        instagramUsername &&
              <Grid2  size={{xs:12, md: 4}}>
                  <Link href={storeDetails?.instagram} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'primary.socialmedia' }}>
                    <Stack sx={{flex:1 , flexDirection: 'row ', alignItems: 'center' }}>
                      <FiInstagram color='primary.socialmedia' style={{ fontSize: '25px' }} />
                      <Typography sx={{ color: 'primary.socialmedia', fontWeight: 'bold',  pl:2 }}  variant='subtitle1'>{instagramUsername}</Typography>
                    </Stack>
                  </Link>
                  </Grid2>
              }
          </Grid2>
          </Box>
      </Box>

    </Container>
  );
}

export default AboutUsNew;
