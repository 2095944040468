import React from 'react'
import { useNavigate } from 'react-router-dom';
import Buttons from '../components/button'
import Calendar from '../components/calendar'
import Hours from '../components/hours'
import CustomizedSteppers from '../components/Stepper'
import Category from '../components/category'
import EmployeeHorizontalList from '../components/employeeHorizontalList'
import CompleteBooking from '../components/completeBooking'

function Home() {

  const navigate = useNavigate();

  return (
    <div>
      {/* <h1 style={{textAlign:'center' }}>Home page</h1> */}
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
        <Buttons variant="outlined" children={"Open map"} onclick={() => navigate('/klinik')} />

        {/* <Buttons variant="contained" children={"contained"}  onclick={()=>{console.log('button clicked');}}/>
        <Buttons variant="outlined" children={"outlined"} />
        <Calendar />
        <Hours /> */}
        {/* <EmployeeHorizontalList /> */}
        {/* <br/>
        <CustomizedSteppers />
        <br/><br/><br/><br/> */}
        {/* <Category /> */}
        {/* <CompleteBooking/> */}
      </div>
    </div>
  )
}

export default Home
