import { createBrowserRouter, Navigate, redirect } from 'react-router-dom';
import App from './App';
import Home from './pages/home';
import AboutUs from './pages/aboutUsNew';
import Booking from './pages/booking';
import Pictures from './pages/pictures';
import Reviews from './pages/reviews';
import Category from './components/category';
import Services from './components/services';
import Employee from './components/employee';
import Calendar from './components/calendar';
import Information from './components/information';
import OtpVerification from './components/otpVerification';
import CompleteBooking from './components/completeBooking';
import StoreMap from './pages/storeMap';
import StoreHomePage from './pages/storeHomePage';
import BookingConfirmed from './components/bookingConfirmed';
import Page404 from './components/page404';



const router = createBrowserRouter([
  {
    path: "/",
    element:<StoreMap />,
  },
  {
    path: "/klinik/:web_store_name",
    element: <StoreHomePage />,
  
  },
  {
    path: "/:web_store_name",
    loader: async ({ params }) => {
      return redirect(`/klinik/${params.web_store_name}`);
    },
  },
  // {
  //   path: "/booking",
  //   element: <Booking />,
  // },
  // {
  //   path: "/pictures",
  //   element: <Pictures />,
  // },
  // {
  //   path: "/reviews",
  //   element: <Reviews />
  // },
  {
    path: '/404',
    element: <Page404 />
  },
  {
    path: '*',
    element: <Page404 />
  }
]);

export default router;
