import React, { useEffect } from 'react';
import theme from '../theme';
import { Paper, Box, Typography, useMediaQuery, MenuItem, Select, FormControl, Stack, Grid2, IconButton, InputAdornment } from '@mui/material';
import { t } from 'i18next';
import MenuIcon from '@mui/icons-material/Menu';
import SortIcon from '../assets/images/sortingIcon.png';

const ClinicServiceTabs = ({ onTabChange, isExtendedSearch, count, title, selectedTab, sorting, distanceSorting, setSorting, setDistanceSorting, tabLoading }) => {

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const [value, setValue] = React.useState(0);


    useEffect(() => {
        setValue(selectedTab);
    }, [selectedTab]);

    const countFinal = selectedTab === 0 ? count?.total_outlets : count?.total_services

    // const sortData = (criteria) => {
    //     let sortedStores = [...stores];
    //     let sortedServices = [...services];

    //     if (criteria === 'distance') {
    //         sortedStores.sort((a, b) => a.distance - b.distance);
    //         sortedServices.sort((a, b) => a.distance - b.distance);
    //     } else if (criteria === 'priceLowToHigh') {
    //         sortedServices.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    //     } else if (criteria === 'priceHighToLow') {
    //         sortedServices.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    //     }

    //     onSort(sortedStores, sortedServices);
    // };

    // const sortData = (criteria) => {
    //     let sortedStores = [...stores];
    //     let sortedServices = [...services];

    //     if (criteria === 'distance') {
    //         if (value === 0) {
    //             sortedStores.sort((a, b) => a.distance - b.distance);
    //             onSort(sortedStores, sortedServices);
    //         } else {
    //             sortedServices.sort((a, b) => a.distance - b.distance);
    //             onSort(sortedStores, sortedServices);
    //         }
    //     } else if (criteria === 'priceLowToHigh') {
    //         sortedServices.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    //         onSort(sortedStores, sortedServices);
    //     } else if (criteria === 'priceHighToLow') {
    //         sortedServices.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    //         onSort(sortedStores, sortedServices);
    //     }
    // };

    const handleChangeFilter = (event) => {
        const selectedSorting = event.target.value;
        setSorting(selectedSorting);
        // sortData(selectedSorting);
    };

    const handleChangeDistanceFilter = (event) => {
        const selectedDistanceSorting = event.target.value;
        setDistanceSorting(selectedDistanceSorting);
    };

    const handleChange = (index) => {
        setSorting('');
        setValue(index);
        if (onTabChange) {
            onTabChange(tabs[index].index);
        }
    };

    const tabs = [
        { name: t('Clinic'), index: 0 },
        { name: t('Servicees'), index: 1 },
    ];

    return (
        <>
            <Stack
                width={'100%'}
                flexDirection={'row'}
                flex={1}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ mt: 1, paddingInline: 1 }}
                display={isSmallScreen ? 'flex' : 'none'}
            >
                {selectedTab === 0 ?
                    <div style={{ flexDirection: 'row' }}>
                        <Typography variant="body2" sx={{ color: '#000000' }} textAlign="left">{'Sort by'}</Typography>
                        <FormControl fullWidth sx={{ mr: 0 }}>
                            <Select
                                id="demo-simple-select"
                                size='small'
                                value={sorting}
                                onChange={handleChangeFilter}
                                startAdornment={
                                    <InputAdornment position="start" sx={{ marginLeft: -1 }}>
                                        <img
                                            src={SortIcon}
                                            alt="IconOne"
                                            style={{
                                                width: '15px',
                                                height: '15px',
                                            }}
                                        />
                                    </InputAdornment>
                                }
                                IconComponent={null}
                                sx={{
                                    width: '140px',
                                    width: 'auto',
                                    borderRadius: '10px',
                                    fontSize: '0.9rem',
                                    border: `2px solid ${theme.palette.primary.main}`,
                                    '& .MuiSelect-select': {
                                        paddingTop: '4px',
                                        paddingBottom: ' 4px',
                                        paddingRight: '10px !important',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: `2px solid ${theme.palette.primary.main}`
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: `2px solid ${theme.palette.primary.main}`
                                    },
                                }}
                                displayEmpty
                            >
                                <MenuItem value={''} sx={{ fontSize: '0.9rem' }}>{'Choose sort'}</MenuItem>
                                <MenuItem value={'distance'} sx={{ fontSize: '0.9rem' }} >{t('distance')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    :
                    <div style={{ flexDirection: 'row' }}>

                        <Typography variant="body2" sx={{ color: '#000000' }} textAlign="left">{'Sort by'}</Typography>

                        <FormControl fullWidth sx={{ mr: 0 }}>
                            <Select
                                id="demo-simple-select"
                                size='small'
                                value={sorting}
                                onChange={handleChangeFilter}
                                startAdornment={
                                    <InputAdornment position="start" sx={{ marginLeft: -1 }}>
                                        <img
                                            src={SortIcon}
                                            alt="IconOne"
                                            style={{
                                                width: '15px',
                                                height: '15px',
                                            }}
                                        />
                                    </InputAdornment>
                                }
                                IconComponent={null}
                                sx={{
                                    //width: '140px',
                                    width:'auto',
                                    fontSize: '0.9rem',
                                    borderRadius: '10px',
                                    border: `2px solid ${theme.palette.primary.main}`,
                                    '& .MuiSelect-select': {
                                        paddingTop: '4px',
                                        paddingBottom: ' 4px',
                                        paddingRight: '10px !important',
                                        // paddingLeft: '8px'

                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: `2px solid ${theme.palette.primary.main}`
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: `2px solid ${theme.palette.primary.main}`
                                    },
                                }}
                                displayEmpty
                            >
                                <MenuItem value={''} sx={{ fontSize: '0.9rem' }} >{'Choose sort'}</MenuItem>
                                <MenuItem value={'distance'} sx={{ fontSize: '0.9rem' }} >{t('distance')}</MenuItem>
                                <MenuItem value={'low_to_high'} sx={{ fontSize: '0.9rem' }}>{t('priceLtH')}</MenuItem>
                                <MenuItem value={'high_to_low'} sx={{ fontSize: '0.9rem' }} >{t('priceHtL')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                }

                <div style={{ flexDirection: 'row' }}>
                    <Typography variant="body2" sx={{ color: '#000000', ml: 1 }} textAlign="left">{'Search within'}</Typography>
                    <FormControl fullWidth>
                        <Select
                            id="demo-simple-select"
                            size='small'
                            value={distanceSorting}
                            onChange={handleChangeDistanceFilter}
                            sx={{
                                width: '140px',
                                ml: 1,
                                fontSize: '0.9rem',
                                borderRadius: '10px',
                                border: `2px solid ${theme.palette.primary.main}`,
                                '& .MuiSelect-select': {
                                    padding: '4px',
                                    paddingLeft: '8px'

                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: `2px solid ${theme.palette.primary.main}`
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: `2px solid ${theme.palette.primary.main}`
                                },
                            }}
                            displayEmpty
                        >
                            <MenuItem value={'2'} sx={{ fontSize: '0.9rem' }} >{'2 km'}</MenuItem>
                            <MenuItem value={'5'} sx={{ fontSize: '0.9rem' }}>{'5 km'}</MenuItem>
                            <MenuItem value={'10'} sx={{ fontSize: '0.9rem' }} >{'10 km'}</MenuItem>
                            <MenuItem value={'20'} sx={{ fontSize: '0.9rem' }} >{'20 km'}</MenuItem>
                            <MenuItem value={'50'} sx={{ fontSize: '0.9rem' }} >{'50 km'}</MenuItem>
                            <MenuItem value={'100'} sx={{ fontSize: '0.9rem' }} >{'100 km'}</MenuItem>
                            <MenuItem value={'500'} sx={{ fontSize: '0.85rem' }}>Hele Danmark</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </Stack>

            <Box size={12} sx={{ display: 'flex', flex: 1, flexDirection: 'row', paddingInline: 3, justifyContent: "space-between", alignItems: 'center', width: '100%', height: 80, minHeight: isExtendedSearch ? 90 : 80, maxHeight: 90 }}>

                <Grid2 container size={12} sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Grid2 size={4} sx={{ flex: 1, display: { xs: 'none', md: 'flex' }, flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {(isExtendedSearch && !tabLoading) &&
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Typography variant="h6" sx={{ fontWeight: 700, color: '#000000', fontSize: '1.25rem' }}>
                                    {`${t('heading2')} ${countFinal} ${value === 0 ? t('heading2LastPart') : t('services')}`}
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: 400, color: '#6F6F6F', }}>
                                    {t('heading1')}
                                </Typography>
                            </div>
                        }
                    </Grid2>

                    <Grid2 item size={4} sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        {isExtendedSearch ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body1" textAlign={"center"} sx={{ fontWeight: "bold", color: '#000000' }}>
                                    {t('chooseSearch')}
                                </Typography>

                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 1 }}>
                                    {tabs.map((item) => (
                                        <Paper
                                            key={item.id}
                                            elevation={0}
                                            sx={{
                                                cursor: 'pointer',
                                                pt: 0.3,
                                                pb: 0.3,
                                                paddingRight: 1,
                                                paddingLeft: 1,
                                                borderRadius: 4,
                                                backgroundColor: value === item.index ? theme.palette.primary.main : 'white',
                                                border: value === item.index ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.main}`,
                                                color: value === item.index ? 'white' : theme.palette.primary.main,
                                                flex: '1 1 calc(33.33% - 10px)',
                                                minWidth: 120,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            onClick={() => handleChange(item.index)}
                                        >
                                            <Typography variant="body2" sx={{ color: value === item.index ? 'white' : theme.palette.primary.main, fontWeight: 700 }} textAlign="center">{item.name}</Typography>
                                        </Paper>
                                    ))}
                                </Box>
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', width: '15%' }} />
                        }

                        {!isExtendedSearch &&
                            <Typography variant="h6" sx={{ fontSize: '1.25rem', color: '#000000' }} textAlign="center">{(title !== undefined) ? title : ''}</Typography>
                        }

                    </Grid2>

                    <Grid2 item size={4} sx={{ flex: 1, display: { xs: 'none', md: 'flex' }, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {selectedTab === 0 ?
                            <div style={{ flexDirection: 'row' }}>

                                <Typography variant="body2" sx={{ color: '#000000' }} textAlign="left">{'Sort by'}</Typography>

                                <FormControl fullWidth sx={{ mr: 0 }}>
                                    <Select
                                        id="demo-simple-select"
                                        size='small'
                                        value={sorting}
                                        onChange={handleChangeFilter}
                                        startAdornment={
                                            <InputAdornment position="start" sx={{ marginLeft: -1 }}>
                                                <img
                                                    src={SortIcon}
                                                    alt="IconOne"
                                                    style={{
                                                        width: '15px',
                                                        height: '15px',
                                                    }}
                                                />
                                            </InputAdornment>
                                        }
                                        IconComponent={null}
                                        sx={{
                                            width: 'auto',
                                            borderRadius: '10px',
                                            fontSize: '0.9rem',
                                            border: `2px solid ${theme.palette.primary.main}`,
                                            '& .MuiSelect-select': {
                                                paddingTop: '4px',
                                                paddingBottom: ' 4px',
                                                paddingRight: '10px !important',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: `2px solid ${theme.palette.primary.main}`
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: `2px solid ${theme.palette.primary.main}`
                                            },
                                        }}
                                        displayEmpty
                                    >
                                        <MenuItem value={''} sx={{ fontSize: '0.9rem' }}>{'Choose sort'}</MenuItem>
                                        <MenuItem value={'distance'} sx={{ fontSize: '0.9rem' }} >{t('distance')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            :
                            <div style={{ flexDirection: 'row' }}>

                                <Typography variant="body2" sx={{ color: '#000000' }} textAlign="left">{'Sort by'}</Typography>

                                <FormControl fullWidth sx={{ mr: 0 }}>
                                    <Select
                                        id="demo-simple-select"
                                        size='small'
                                        value={sorting}
                                        onChange={handleChangeFilter}
                                        startAdornment={
                                            <InputAdornment position="start" sx={{ marginLeft: -1 }}>
                                                <img
                                                    src={SortIcon}
                                                    alt="IconOne"
                                                    style={{
                                                        width: '15px',
                                                        height: '15px',
                                                    }}
                                                />
                                            </InputAdornment>
                                        }
                                        IconComponent={null}
                                        sx={{
                                            width: 'auto',
                                            // width: '200px',
                                            // minWidth:'155px',
                                            fontSize: '0.9rem',
                                            borderRadius: '10px',
                                            border: `2px solid ${theme.palette.primary.main}`,
                                            '& .MuiSelect-select': {
                                                paddingTop: '4px',
                                                paddingBottom: ' 4px',
                                                paddingRight: '10px !important',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: `2px solid ${theme.palette.primary.main}`
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: `2px solid ${theme.palette.primary.main}`
                                            },
                                        }}
                                        displayEmpty
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        edge="start"
                                                        aria-label="sort"
                                                        sx={{
                                                            color: theme.palette.primary.main,
                                                        }}
                                                    >
                                                        <SortIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    >
                                        <MenuItem value={''} sx={{ fontSize: '0.9rem' }} >{'Choose sort'}</MenuItem>
                                        <MenuItem value={'distance'} sx={{ fontSize: '0.9rem' }} >{t('distance')}</MenuItem>
                                        <MenuItem value={'low_to_high'} sx={{ fontSize: '0.9rem' }}>{t('priceLtH')}</MenuItem>
                                        <MenuItem value={'high_to_low'} sx={{ fontSize: '0.9rem' }} >{t('priceHtL')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        }

                        <div style={{ flexDirection: 'row' }}>

                            <Typography variant="body2" sx={{ color: '#000000', ml: 1 }} textAlign="left">{'Search within'}</Typography>

                            <FormControl fullWidth>
                                <Select
                                    id="demo-simple-select"
                                    size='small'
                                    // value={distanceSorting}
                                    value={distanceSorting}
                                    onChange={handleChangeDistanceFilter}
                                    sx={{
                                        width: 'auto',
                                        ml: 1,
                                        fontSize: '0.9rem',
                                        borderRadius: '10px',
                                        border: `2px solid ${theme.palette.primary.main}`,
                                        '& .MuiSelect-select': {
                                            padding: '4px',
                                            paddingLeft: '8px'

                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: `2px solid ${theme.palette.primary.main}`
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: `2px solid ${theme.palette.primary.main}`
                                        },
                                    }}
                                    displayEmpty
                                >
                                    {/* <MenuItem value={''} sx={{ fontSize: '0.9rem' }} >{'Select Distance'}</MenuItem> */}
                                    <MenuItem value={'2'} sx={{ fontSize: '0.9rem' }} >{'2 km'}</MenuItem>
                                    <MenuItem value={'5'} sx={{ fontSize: '0.9rem' }}>{'5 km'}</MenuItem>
                                    <MenuItem value={'10'} sx={{ fontSize: '0.9rem' }} >{'10 km'}</MenuItem>
                                    <MenuItem value={'20'} sx={{ fontSize: '0.9rem' }} >{'20 km'}</MenuItem>
                                    <MenuItem value={'50'} sx={{ fontSize: '0.9rem' }} >{'50 km'}</MenuItem>
                                    <MenuItem value={'100'} sx={{ fontSize: '0.9rem' }} >{'100 km'}</MenuItem>
                                    <MenuItem value={'500'} sx={{ fontSize: '0.85rem' }}>Hele Danmark</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                    </Grid2>

                </Grid2>

            </Box>
            {/* :
                null
            } */}
        </>
    );
};

export default ClinicServiceTabs;

