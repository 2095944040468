import { Button, Typography } from '@mui/material';
import React from 'react'
import theme from '../theme';


const Buttons = (props) => {

    const { variant, children,height,width,margin,borderRadius,borderWidth,startIcon,endIcon,fontWeight,onclick,minWidth,maxWidth,display,gap,name,justifyContent,disabled, fontcolor} = props
    return (
        <div>
            <Button variant={`${variant === "contained" ? "contained" : "outlined"}`} 
                sx={{
                    backgroundColor: variant === "contained" ? theme.palette.primary.main : "white",
                    color:  variant === "contained" ? "white" : "black",
                    border: variant === "outlined" ? `3px solid ${theme.palette.primary.main}` : "none",
                    borderRadius: borderRadius,
                    margin:margin,
                    height:height,
                    width:width,
                    borderWidth: borderWidth,
                    fontWeight: fontWeight,
                    minWidth: minWidth,
                    maxWidth:maxWidth,
                    display:display,
                    gap:gap,
                    justifyContent:justifyContent,
                    textTransform: 'none',   
                }}
                onClick={onclick}
                startIcon={startIcon}
                endIcon={endIcon}
                name={name}
                disabled={disabled}
            ><Typography variant='body2' color={fontcolor ? fontcolor : ( variant === "contained" ? "white" : "black")}>{children}</Typography></Button>
        </div>
    );
};

export default Buttons
