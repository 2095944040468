import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    ListItem, useTheme, useMediaQuery, Box, Skeleton, Button, List, CircularProgress,
    Container, Typography, TextField, Grid, Paper, InputAdornment, ListItemText,
    IconButton,
    Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MapComponent from '../components/map';
import StoreCard from '../components/storeCard';
import ServiceCard from '../components/serviceCardAni';
import IconImage4 from '../assets/images/tag_(2)specialoffer.png';
import IconImage5 from '../assets/images/calenderSecondImage.png';
import IconImage6 from '../assets/images/sparkIconThirdIcon.png';
import closemap from '../assets/images/closeMap.png'

import { webHomeScreenApi } from '../apis/homeScreen';
import ClinicServiceTabs from '../components/tabPanel';

import { debounce } from 'lodash';
import Navbar from '../components/navbar';
import { t } from 'i18next';
import mapSpanIconForMobile from '../assets/images/expandMap1.png';
import { SplashScreen } from '../components/splash-screen';
import AdvanceFilter from '../components/advanceFilter';
import { useRef } from 'react';
import parsedConfigs from '../conifg';
import axios from 'axios';
// import axiosInstance, { axiosController, cancelOperation, CancelToken, cancelTokenSource, handleCancelRequest, source } from '../utils/axiosInstance';
import InfiniteScroll from 'react-infinite-scroll-component';


const boxContent = [
    { id: 1, icon: IconImage4, bottomText: 'Special offers' },
    { id: 2, icon: IconImage5, bottomText: 'Cancellation offers' },
    { id: 3, icon: IconImage6, bottomText: 'Extended search' },
];

function StoreMap() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdScreen = useMediaQuery(theme.breakpoints.up('md'));
    const apiKey = 'AIzaSyCI2u5iSXIqE6wnNPAIjXGpT-SGc2c22SI';
    const [loading, setLoading] = useState(false);

    const [isLeftBoxVisible, setIsLeftBoxVisible] = useState(true);

    const [showSuggestion, setShowSuggestion] = useState(false);
    const [suggestions, setSuggestions] = useState({ services: [], outlets: [] });
    const [isLoading, setIsLoading] = useState(false);

    const [open, setOpen] = React.useState(false);

    const [originalStores, setOriginalStores] = useState([]);
    const [stores, setStores] = useState([]);
    const [services, setServices] = useState([]);

    const [categories, setCategories] = useState([]);

    // const [userLocation, setUserLocation] = useState({ lat: 55.6712398, lng: 12.5114246 });
    const [selectedTab, setSelectedTab] = useState(0);
    const [tabLoading, setTabLoading] = useState(false);
    const [extendedSearch, setExtendedSearch] = useState(false);
    const [mapComponentLoaded, setMapComponentLoaded] = useState(false)
    const [cancelToken, setCancelToken] = useState(null);
    const [cancelStoreToken, setCancelStoreToken] = useState(null);
    const [allData, setAllData] = useState([]);
    const [allOutlets, setAllOutlets] = useState([]);
    const [pageLimit, setPageLimit] = useState(30)
    const [limit, setLimit] = useState(1000)
    const [offset, setOffset] = useState(0)
    const [hasMore, setHasMore] = useState(false)
    const [totalCount, setTotalCount] = useState({
        total_outlets: 0,
        total_services: 0
    })

    const range = 500

    const [searchData, setSearchData] = useState({
        category_ids: '-1',
        selectedFirstRow: 'Standard',
        selectedSearchOption: null,
        selectedSearchedItem: { id: null, source: '' },
        selectedServiceIds: [],
        kilometer: '50',
        selectedSecondRow: null,
        fromValue: '',
        toValue: '',
        searched: '',
        sorting: (selectedTab == 0 ? 'distance' : ''),
        callApi: true,
        lat: 55.6712398, lng: 12.5114246
    })

    //console.log('SEARCHED DATA ', JSON.stringify(searchData))

    // const [username, setUsername] = useState('dev_patel')

    // let controller = new AbortController();
    // let signal = null

    const inputRef = useRef(null);

    useEffect(() => {
        // reqLocation()
        // setOffset(0)
        // setHasMore(false)
        getCategories();
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        console.log('hello');

        if (searchData?.callApi) {
            setTabLoading(true);

            if(selectedTab === 0){
                fetchAllStores(signal);
            }else{
                fetchAllServices(signal);
            }
        }
        return () => {
            // Cancel the request when the component unmounts
            controller.abort();
        };
    }, [searchData, selectedTab,]);

    const fetchAllStores = async (signal) => {
        const { selectedSearchedItem, selectedFirstRow, selectedSecondRow, searched, selectedSearchOption, selectedServiceIds, kilometer, toValue, fromValue, sorting, lat, lng } = searchData

        let special_offers = false;
        let cancellation_offers = false;
        // const { lat, lng } = userLocation;
        const { source } = selectedSearchedItem;
        let serviceIds = [];
        let new_client_offer = false;
        let consultation = false;
        let bundle_offers = false;
        let fromVal = 0;
        let toVal = 100000;
        // let km = 55;
        let km = kilometer || 500;
        // const catId = selectedCategory ? selectedCategory.id : '';
        const catId = (searchData?.category_ids !== null) ? searchData?.category_ids.id : ''
        if (selectedFirstRow === 'Cancellation offers' || selectedSearchOption?.id == 2) {
            cancellation_offers = true
        }
        if (selectedFirstRow === 'Special offers' || selectedSearchOption?.id === 1) {
            special_offers = true
        }
        if (selectedSearchOption?.id === 3) {
            new_client_offer = selectedSecondRow == 'Offer to new clients' ? true : false;
            consultation = selectedSecondRow == 'Consulation' ? true : false;
            bundle_offers = selectedSecondRow == 'Bundle offers' ? true : false;
            fromVal = fromValue == '' ? 0 : fromValue;
            toVal = toValue == '' ? 100000 : toValue;
            km = kilometer || 50;
            serviceIds = selectedServiceIds.map(service => service.id).join(', ');
        }

        try {
            const res = await axios.get(`${parsedConfigs.apiUrl}/web/marketplace/list?lat=${lat}&lng=${lng}&search=${encodeURIComponent(searched)}&search_type=${source}&category_ids=${catId}&service_type_ids=${serviceIds}&special_offers=${special_offers}&cancellation_offers=${cancellation_offers}&new_client_offer=${new_client_offer}&consultation=${consultation}&bundle_offers=${bundle_offers}&min_price=${fromVal}&max_price=${toVal}&distance_in_km=${km}&offset=${0}&limit=${limit}&data_type=outlets`, {
                signal
            });

            const allStores = res.data

            console.log("allStores111 >>>", allStores);


            const updatedData = [allStores?.data.total_outlets, allStores?.data.outlets]
            const outlets = allStores?.data.outlets;
            setAllData(updatedData);
            setAllOutlets(outlets);
            if (res.status == 200) {
                getStores(updatedData)
                setTabLoading(false);
            }
        }
        catch (err) {
            console.log('err', err);

            if (!axios.isCancel(err)) {
                console.error(err);
            }
            console.error(err);
        }
    };

    const fetchAllServices = async (signal) => {
        // if(!mapComponentLoaded){
        //     setLoading(false)
        //     return
        // }
        const { selectedSearchedItem, selectedFirstRow, selectedSecondRow, searched, selectedSearchOption, selectedServiceIds, kilometer, toValue, fromValue, sorting, lat, lng } = searchData

        let special_offers = false;
        let cancellation_offers = false;
        // const { lat, lng } = userLocation;
        const { source } = selectedSearchedItem;
        let serviceIds = [];
        let new_client_offer = false;
        let consultation = false;
        let bundle_offers = false;
        let fromVal = 0;
        let toVal = 100000;
        // let km = 55;
        let km = kilometer || 500;
        // const catId = selectedCategory ? selectedCategory.id : '';
        const catId = (searchData?.category_ids !== null) ? searchData?.category_ids.id : ''
        if (selectedFirstRow === 'Cancellation offers' || selectedSearchOption?.id == 2) {
            cancellation_offers = true
        }
        if (selectedFirstRow === 'Special offers' || selectedSearchOption?.id === 1) {
            special_offers = true
        }
        if (selectedSearchOption?.id === 3) {
            new_client_offer = selectedSecondRow == 'Offer to new clients' ? true : false;
            consultation = selectedSecondRow == 'Consulation' ? true : false;
            bundle_offers = selectedSecondRow == 'Bundle offers' ? true : false;
            fromVal = fromValue == '' ? 0 : fromValue;
            toVal = toValue == '' ? 100000 : toValue;
            km = kilometer || 50;
            serviceIds = selectedServiceIds.map(service => service.id).join(', ');
        }

        try {
            const res = await axios.get(`${parsedConfigs.apiUrl}/web/marketplace/list?lat=${lat}&lng=${lng}&search=${encodeURIComponent(searched)}&search_type=${source}&category_ids=${catId}&service_type_ids=${serviceIds}&special_offers=${special_offers}&cancellation_offers=${cancellation_offers}&new_client_offer=${new_client_offer}&consultation=${consultation}&bundle_offers=${bundle_offers}&min_price=${fromVal}&max_price=${toVal}&distance_in_km=${km}&offset=${offset * pageLimit}&limit=${pageLimit}&sorting=${sorting}&data_type=services`, {
                signal
            });

            const allServices = res.data

            console.log("allServices >>>>", allServices);


            if (res.status == 200) {
                setTabLoading(false);
            }

            setTotalCount({
                total_services: allServices?.data.total_services
            })

            setServices(prevItems => [...prevItems, ...allServices.data.services]);

            if (selectedTab == 1) {
                if (allServices.data.services.length == pageLimit) {
                    setOffset((prev) => prev + 1)
                    setHasMore(true)
                } else {
                    setHasMore(false)
                }
            } else {
                null;
            }
            // setLoading(false);
            // setTabLoading(false);
        } catch (err) {
            if (!axios.isCancel(err)) {
                console.error(err);
                setLoading(false);
                setTabLoading(false);
            }
            console.log('ERR ', err);
            console.error(err);
        }
    };

    const getStores = (allStoreData) => {
        console.log('allStores222', allStoreData);

        try {
            let allStores = allStoreData[1].slice(offset * pageLimit, (offset * pageLimit) + pageLimit)

            console.log("allStore333 >>>===", allStores);

            setTotalCount({
                total_outlets: allStoreData[0],
            })

            setStores(prevItems => [...prevItems, ...allStores]);
            setOriginalStores(prevItems => [...prevItems, ...allStores]);

            if (selectedTab == 0) {
                if (allStores.length == pageLimit) {

                    setOffset((prev) => prev + 1)
                    setHasMore(true)
                } else {
                    setHasMore(false)
                }
            } else {
                null;
            }
            // setLoading(false);
            // setTabLoading(false);
        } catch (err) {
            if (!axios.isCancel(err)) {
                console.error(err);
                setLoading(false);
                setTabLoading(false);
            }
            console.log('ERR ', err);
            console.error(err);
        }
    };


    const handleClose = () => {
        // setOffset(0)
        // setHasMore(false)
        // setStores([])
        // setOriginalStores([])
        // setServices([])
        // setSelectedTab(0)
        // setSearchData((prev) => ({
        //     ...prev,
        //     category_ids: '-1',
        //     selectedFirstRow: 'Standard',
        //     selectedSearchOption: null,
        //     selectedServiceIds: [],
        //     kilometer: range,
        //     selectedSecondRow: null,
        //     fromValue: '',
        //     toValue: '',
        //     callApi: true,
        // }));
        setOpen(false)
    };

    // useEffect(() => {
    //     // console.log('in useeffect');
    //     const controller = new AbortController();
    //     const signal = controller.signal;
    //     getStores();
    //     // setLoading(false)

    //     // if(!mapComponentLoaded){
    //     //     setLoading(false)
    //     //    return
    //     // }
    //     if (searchData?.callApi) {
    //         setTabLoading(true);

    //         fetchAllStores(signal)
    //     }
    //     return () => {
    //         // Cancel the request when the component unmounts
    //         controller.abort();
    //     };
    // }, [
    //     searchData,
    //     selectedTab,
    // ]);

    // const reqLocation = () => {
    //     if (navigator.geolocation && isSmallScreen) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 setUserLocation({
    //                     lat: position.coords.latitude,
    //                     lng: position.coords.longitude,
    //                 });
    //             },
    //             (error) => {
    //                 console.warn('Geolocation error:', error);
    //             }
    //         );
    //     }
    // }
    // console.log('tabloading', tabLoading);



    // let controller = null
    // useEffect(() => {


    //      const controller = new AbortController();
    //     const signal = controller.signal;



    //     (async () => {
    //       try {
    //         const response = await axios.get(
    //          `https://dummyjson.com/products/search?delay=3000&q=${username}`,
    //           { signal } // Method 1
    //         );

    //         if (response.status === 200) {

    //         } else {
    //           console.error(`HTTP error! Status: ${response.status}`);
    //         }
    //       } catch (error) {
    //         if (!axios.isCancel(error)) {
    //           console.error(error);
    //         }
    //       }
    //     })();

    //     return () => {
    //       // Cancel the request when the component unmounts
    //       controller.abort();
    //     };
    //   }, [username]);

    const getCategories = async () => {
        try {
            const categories = await webHomeScreenApi.categoryGeneralData();
            setCategories(categories.data.categories);
            // if (categories.data.categories.length > 0) {
            //     // setSelectedCategory(categories.data.categories[0]);
            //     setSearchData((prev) => ({ ...prev, category_ids: categories.data.categories, callApi: true }))
            // } else {
            //     // setSelectedCategory(null);
            //     setSearchData((prev) => ({ ...prev, category_ids: null, callApi: true }))
            // }
        } catch (err) {
            console.log('ERR Categories ', err);
            console.error(err);
        }
    };

    const handleToggle = () => {
        setIsLeftBoxVisible(prev => !prev);
    };

    const renderStoreCardItem = useCallback(({ item, searchData }) => {
        return <StoreCard store={item} checkOption={searchData?.selectedSearchOption} firstRow={searchData?.selectedFirstRow} />;
    }, [searchData?.selectedFirstRow]);

    const renderServiceCardItem = useCallback(({ item, searchData }) => {
        return <ServiceCard service={item} checkOption={searchData?.selectedSearchOption} generalData={categories} firstRow={searchData?.selectedFirstRow} />;
    }, [searchData?.selectedFirstRow, categories]);

    const dataToRender =
        selectedTab === 0 && searchData?.selectedSearchOption?.bottomText === 'Cancellation offers' ? services : selectedTab === 0 ? stores : services;

    const renderItem = (() => {
        if (selectedTab === 1) {
            return renderServiceCardItem;
        }
        if (selectedTab === 0 && searchData?.selectedSearchOption?.bottomText === 'Cancellation offers') {
            return renderServiceCardItem;
        }
        return renderStoreCardItem;
    })();

    const SkeletonCard = () => (
        <Box sx={{ width: 'auto', height: 'auto', padding: 0, flexDirection: 'column' }} elevation={3}>
            <Box sx={{ pt: 0 }}>
                <Skeleton height={35} sx={{ pt: 0.5, borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />
                <Skeleton height={25} width="60%" />
                <Skeleton width="70%" height={25} />
            </Box>

            <Box sx={{ pt: 0.5 }}>
                <Skeleton variant="rectangular" sx={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }} width={'auto'} height={140} />
            </Box>
        </Box>
    );


    const fetchSuggestions = debounce(async (event) => {
        const query = event.target.value;
        setShowSuggestion(true)
        if (query.trim()) {

            if (cancelToken) {
                cancelToken.cancel('Canceling previous request');
            }

            const source = axios.CancelToken.source();
            setCancelToken(source);

            setIsLoading(true);
            // try {
            // const response = await webHomeScreenApi.searchSuggestion(query);

            axios.get(`${parsedConfigs.apiUrl}/web/marketplace/searchSuggestions?search=${query}`, {
                cancelToken: source.token
            })
                .then(response => {
                    const { data } = response
                    const { service_types, outlets } = data.data;
                    //   console.log('serviceTypes', service_types);

                    setSuggestions({
                        services: service_types,
                        outlets: outlets,
                    });
                    setIsLoading(false);

                })
                .catch(thrown => {
                    if (axios.isCancel(thrown)) {
                        console.log('Request canceled:', thrown.message);
                    } else {
                        console.error('Error fetching suggestions:', thrown);

                    }
                    setIsLoading(false);
                });




            // } catch (error) {
            //     console.log("Error fetching suggestions:", error);
            //     setSuggestions({ services: [], outlets: [] });
            //     setIsLoading(false);
            // } finally {
            //     setIsLoading(false);
            // }
        } else {
            setSuggestions({ services: [], outlets: [] });
            setIsLoading(false);
        }
    }, 1);


    const handleClearSearch = () => {
        setServices([])
        setOriginalStores([])
        setStores([])
        setSelectedTab(0);
        if (inputRef.current !== null) {
            inputRef.current.value = '';
        }
        setShowSuggestion(false);
        // setSelectedSearchedItem({ id: null, source: '' });
        setSearchData((prev) => ({ ...prev, selectedSearchedItem: { id: null, source: '' }, callApi: true }))
        // handleSearch({ target: { value: '' } });
    };

    // const handleBoxClick = (item) => {
    //     setSelectedTab(0);
    //     setOffset(0)
    //     setHasMore(false)

    //     setStores([]);
    //     setOriginalStores([]);
    //     setServices([]);


    //     if (searchData?.category_ids?.id === item.id) {
    //         // setSelectedCategory(null);
    //         setSearchData((prev) => ({
    //             ...prev, category_ids: null, selectedFirstRow: 'Standard', selectedSearchOption: null,
    //             selectedServiceIds: [], kilometer: range, selectedSecondRow: null,
    //             fromValue: '', toValue: '', callApi: true
    //         }))

    //         // resetFunction();
    //     } else {
    //         // setSelectedCategory(item);
    //         if (searchData?.selectedSearchOption) {
    //             const { id } = searchData?.selectedSearchOption
    //             if (id == 3) {
    //                 setSearchData((prev) => ({
    //                     ...prev, category_ids: item, selectedFirstRow: 'Standard', selectedSearchOption: null,
    //                     selectedServiceIds: [], kilometer: range, selectedSecondRow: null,
    //                     fromValue: '', toValue: '', callApi: true
    //                 }))
    //             } else {
    //                 setSearchData((prev) => ({ ...prev, category_ids: item, callApi: true }))
    //             }
    //         } else {
    //             setSearchData((prev) => ({ ...prev, category_ids: item, callApi: true }))
    //         }


    //         // setSelectedOptionAlongWithCategory(null);
    //         // resetFunction();
    //     }
    // };

    const handleBoxClick = (item) => {
        setSelectedTab(0);
        setOffset(0);
        setHasMore(false);
        setStores([]);
        setOriginalStores([]);
        setServices([]);
        if (inputRef.current !== null) {
            inputRef.current.value = '';
        }
        if (searchData?.category_ids?.id === item.id) {
            setSearchData((prev) => ({
                ...prev,
                category_ids: '-1',
                selectedFirstRow: 'Standard',
                selectedSearchOption: null,
                selectedServiceIds: [],
                kilometer: range,
                selectedSecondRow: null,
                fromValue: '',
                toValue: '',
                callApi: true,
                selectedSearchedItem: { id: null, source: '' },
            }));
        } else {
            if (item.id === 3) {
                if (searchData?.selectedSearchOption) {
                    setSearchData((prev) => ({
                        ...prev,
                        selectedSearchOption: null,
                        category_ids: '-1',
                        callApi: true,
                        selectedSearchedItem: { id: null, source: '' },
                    }));
                } else {
                    setSearchData((prev) => ({
                        ...prev,
                        category_ids: '-1',
                        callApi: true,
                        selectedSearchedItem: { id: null, source: '' },
                    }));
                }
            } else {
                if (searchData?.selectedSearchOption) {
                    const { id } = searchData?.selectedSearchOption;
                    if (id !== 3) {
                        setSearchData((prev) => ({
                            ...prev,
                            category_ids: item,
                            selectedSearchOption: null,
                            callApi: true,
                            selectedSearchedItem: { id: null, source: '' },
                        }));
                    } else {
                        setSearchData((prev) => ({
                            ...prev,
                            category_ids: item,
                            selectedSearchOption: null,
                            callApi: true,
                            selectedSearchedItem: { id: null, source: '' },
                        }));
                    }
                } else {
                    setSearchData((prev) => ({
                        ...prev,
                        category_ids: item,
                        callApi: true,
                        selectedSearchedItem: { id: null, source: '' },
                    }));
                }
            }
        }
    };

    // const selectedOption = (item) => {
    //     console.log('item --------- ', item);
    //     // console.log('searchData', searchData?.selectedSearchOption?.id);

    //     if (searchData?.selectedSearchOption?.id === item.id) {
    //         setSelectedTab(0);
    //         setOffset(0)
    //         setHasMore(false)
    //         setStores([])
    //         setServices([])
    //         setOriginalStores([])

    //         if (item.id == 3) {
    //             setSearchData((prev) => ({ ...prev, selectedSearchOption: null, callApi: true }))
    //         } else {
    //             setSearchData((prev) => ({
    //                 ...prev, selectedSearchOption: null,
    //                 selectedFirstRow: 'Standard',
    //                 selectedServiceIds: [], kilometer: range, selectedSecondRow: null,
    //                 fromValue: '', toValue: '',
    //                 callApi: true

    //             }))
    //         }
    //         // setSelectedOptionAlongWithCategory(null);
    //     } else {
    //         // resetFunction();
    //         if (item.id == 3) {
    //             setSearchData((prev) => ({ ...prev, selectedSearchOption: item, callApi: false }))
    //             setOpen(true);
    //         } else {
    //             setSelectedTab(0);
    //             setOffset(0)
    //             setHasMore(false)
    //             setStores([])
    //             setServices([])
    //             setOriginalStores([])
    //             setSearchData((prev) => ({
    //                 ...prev, selectedSearchOption: item, selectedFirstRow: 'Standard',
    //                 selectedServiceIds: [], kilometer: range, selectedSecondRow: null,
    //                 fromValue: '', toValue: '',
    //                 callApi: true
    //             }))

    //         }
    //     }
    // };

    const selectedOption = (item) => {
        if (searchData?.selectedSearchOption?.id === item.id) {
            console.log('in if');

            setSelectedTab(0);
            setOffset(0);
            setHasMore(false);
            setStores([]);
            setServices([]);
            setOriginalStores([]);
            if (inputRef.current !== null) {
                inputRef.current.value = '';
            }
            if (item.id === 3) {
                setSearchData((prev) => ({
                    ...prev,
                    selectedSearchOption: null,
                    category_ids: '-1',
                    callApi: true,
                    selectedSearchedItem: { id: null, source: '' },
                }));
            } else {
                setSearchData((prev) => ({
                    ...prev,
                    selectedSearchOption: null,
                    selectedFirstRow: 'Standard',
                    selectedServiceIds: [],
                    kilometer: range,
                    selectedSecondRow: null,
                    fromValue: '',
                    toValue: '',
                    callApi: true,
                    selectedSearchedItem: { id: null, source: '' },
                }));
            }
        } else {
            console.log('in else');

            if (item.id === 3) {
                // setSearchData((prev) => ({
                //     ...prev,
                //     selectedSearchOption: item,
                //     category_ids: '-1',
                //     callApi: false,
                // }));

                setOpen(true);
            } else {

                setOffset(0);
                setHasMore(false);
                setStores([]);
                setServices([]);
                setOriginalStores([]);


                setSearchData((prev) => ({
                    ...prev,
                    selectedSearchOption: item,
                    category_ids: '-1',
                    selectedFirstRow: 'Standard',
                    selectedServiceIds: [],
                    kilometer: range,
                    selectedSecondRow: null,
                    fromValue: '',
                    toValue: '',
                    callApi: true,
                    selectedSearchedItem: { id: null, source: '' },
                }));
            }
        }
    };

    // const resetFunction = () => {
    //     setExtendedSearch(false);
    // };

    const handleSelectChange = (data, source) => {
        let searchedValue = ''
        if (source == 'ServiceType') {

            if (inputRef.current !== null) {
                inputRef.current.value = data.service_type
            }
            searchedValue = data.service_type

            // setSearched(data.service_type)
        } else {

            if (inputRef.current !== null) {
                inputRef.current.value = data.name
            }

            searchedValue = data.name

            // setSearched(data.name)
        }
        const { selectedSearchedItem } = searchData

        setOffset(0)
        setHasMore(false)
        setServices([])
        setStores([])
        setOriginalStores([])

        if (selectedSearchedItem.id === data.id && selectedSearchedItem.source === source) {
            // setSelectedSearchedItem({ id: null, source: '' });
            setSearchData((prev) => ({ ...prev, selectedSearchedItem: { id: null, source: '' }, searched: searchedValue, callApi: true }))

            // handleSearch({ target: { value: '' } });
            setShowSuggestion(false);
        } else {
            // setSelectedSearchedItem({ id: data.id, source });
            setSearchData((prev) => ({ ...prev, category_ids: '-1', selectedSearchOption: null, kilometer: range, selectedSearchedItem: { id: data.id, source: source }, searched: searchedValue, callApi: true }))

            // handleSearch({ target: { value: '' } });
            setShowSuggestion(false);
        }
    };

    const handleTabChange = (tabIndex) => {
        setTabLoading(true);
        setOffset(0)
        setHasMore(false)
        setStores([])
        setServices([])
        setOriginalStores([])
        setSelectedTab(tabIndex);
        setSearchData((prev) => ({ ...prev, callApi: true }))
    };

    const handleSort = (e) => {
        setOffset(0)
        setHasMore(false)
        setStores([]);
        setOriginalStores([]);
        setServices([]);
        setSearchData((prev) => ({ ...prev, sorting: e, callApi: true }))
    };

    const handleDistanceSort = (e) => {
        setOffset(0)
        setHasMore(false)
        setStores([]);
        setOriginalStores([]);
        setServices([]);
        setSearchData((prev) => ({ ...prev, kilometer: e, callApi: true }))
    };

    // const fetchData = async () => {
    //     setIsLoading(true);
    //     setError(null);

    //     try {
    //       const response = await fetch(`https://api.example.com/items?page=${page}`);
    //       const data = await response.json();

    //       setItems(prevItems => [...prevItems, ...data]);
    //       setPage(prevPage => prevPage + 1);
    //     } catch (error) {
    //       setError(error);
    //     } finally {
    //       setIsLoading(false);
    //     }
    //   };

    const advanceSearch = (e) => {
        // console.log('e', e);
        setOffset(0)
        setHasMore(false)
        setStores([])
        setOriginalStores([])
        setServices([])
        setSelectedTab(0)
        setOpen(false)
        setSearchData((prev) => ({ ...prev, ...e, selectedSearchOption: { id: 3, icon: IconImage6, bottomText: 'Extended search' }, timestamp: Date.now(), callApi: true }))
    }

    if (loading) return <SplashScreen />;

    return (
        <Container maxWidth="100%" disableGutters style={{ height: '100vh' }}>
            <Navbar />

            <Box display="flex" width="100%" height="100%" flexDirection="column" sx={{ mt: 6.9 }}>

                {loading ?
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                    :
                    <>
                        <Box display="flex" width="100%" flexDirection="row">
                            {isLeftBoxVisible && (
                                <Stack
                                    flex={1}
                                    flexDirection={'column'}
                                    justifyContent={'flex-start'}
                                    // display="flex"
                                    // flexDirection="column"
                                    height={isSmallScreen ? "500px" : "450px"}
                                    width={isMdScreen ? '25%' : '100%'}
                                    pl={{ xs: 4, md: 2 }}
                                    pr={{ xs: 4, md: 2 }}
                                    bgcolor="#f9f8f9"
                                    borderRight={isMdScreen ? `3px solid ${theme.palette.primary.main}` : "none"}
                                    sx={{ pl: 2, pr: 2 }}
                                >

                                    <Stack >
                                        <Typography variant={isSmallScreen ? "h5" : "h5"} textAlign="left" sx={{ fontWeight: 700, mt: 3, color: '#000000' }}>
                                            {/* {stores.length === 0
                                            ? t('NoclinicsfoundPleasetryadifferentsearch')
                                            : stores.length === 1
                                                ? `${t('Found1clinic')} ${stores[0].name}`
                                                : `${t('Searchamong')} ${stores.length} ${t('clinics')}`} */}
                                            {t('searchAmongTitle')}
                                        </Typography>

                                        <Typography variant="body1" textAlign="left" sx={{ color: '#000000', fontWeight: 400 }}>
                                            {/* {services.length === 0
                                            ? t('Noservicesfound')
                                            : services.length === 1
                                                ? `${t('Found1service')} ${services[0].name}`
                                                : `${t('and')}${services.length} ${t('services')}`} */}

                                            {t('searchAmoungSubTitle')}
                                        </Typography>

                                        <TextField
                                            inputRef={inputRef}
                                            // ref={inputRef}
                                            // inputRef={ref => inputRef.current = ref}
                                            variant="outlined"
                                            // value={searched}
                                            onChange={fetchSuggestions}
                                            placeholder={t('Searchforaclinicorservice')}
                                            fullWidth
                                            size="small"
                                            sx={{
                                                width: '100%',
                                                backgroundColor: 'white',
                                                marginTop: 1,
                                                alignSelf: 'center',
                                                borderRadius: '10px',
                                                border: `2px solid ${theme.palette.primary.main}`,
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '10px',
                                                    '& fieldset': {
                                                        borderColor: `2px solid ${theme.palette.primary.main}`,
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: `2px solid ${theme.palette.primary.main}`,
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: `2px solid ${theme.palette.primary.main}`,
                                                    },
                                                },
                                                '& .MuiInputLabel-root': {
                                                    fontSize: '0.85rem',
                                                    color: 'inherit',
                                                    '&.Mui-focused': {
                                                        color: 'inherit',
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                endAdornment: (inputRef && inputRef.current !== null) ? (
                                                    <InputAdornment position="end">
                                                        {isLoading ?

                                                            <CircularProgress size='20px' />
                                                            :
                                                            <IconButton
                                                                onClick={handleClearSearch}
                                                                edge="end"
                                                                disableRipple
                                                                disableFocusRipple
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        }
                                                    </InputAdornment>
                                                ) : null
                                            }}
                                        />

                                        {/* {isLoading &&
                                        <ListItem sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography>{t('Loading')}</Typography>
                                        </ListItem>
                                    } */}

                                        {(showSuggestion && inputRef.current !== null) && (
                                            <Box sx={{
                                                marginTop: '5px',
                                                // width: {xs: '90%',sm:'20%', md:'22%', lg:'23%'},
                                                width: '100%',
                                                backgroundColor: 'white',
                                                borderRadius: '10px',
                                                border: `1px solid ${theme.palette.primary.main}`,
                                                boxShadow: 2,
                                                height: 300,
                                                overflowY: 'auto',
                                                alignSelf: 'center',
                                                // position:'absolute',
                                                // top:190,
                                                // left:20,
                                                zIndex: 8
                                            }}>
                                                <List>
                                                    {suggestions.services.length === 0 && suggestions.outlets.length === 0 && (
                                                        <ListItem sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography>{t('Nosuggestionsfound')}</Typography>
                                                        </ListItem>
                                                    )}
                                                    {suggestions.services.length > 0 && (
                                                        <Stack sx={{ mb: 2 }}>
                                                            <ListItem sx={{ mb: 0, pb: 0 }}>
                                                                <Typography variant="subtitle1" sx={{ fontWeight: 700, color: "#1f1f1f" }}>{t('Services')}</Typography>
                                                            </ListItem>

                                                            {suggestions.services.map(service => (
                                                                <ListItem
                                                                    key={service.id}
                                                                    button
                                                                    onClick={() => handleSelectChange(service, 'ServiceType')}
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        mt: 0, pt: 0,
                                                                        mb: 0, pb: 0,
                                                                        // backgroundColor: searchData?.selectedSearchedItem.id == service.id && searchData?.selectedSearchedItem.source == 'ServiceType' ? theme.palette.primary.main : 'white',
                                                                        backgroundColor: 'white',
                                                                    }}
                                                                >

                                                                    <ListItemText
                                                                        onClick={() => handleSelectChange(service, 'ServiceType')}
                                                                        sx={{
                                                                            color: '#1f1f1f',
                                                                            // color:  searchData?.selectedSearchedItem.id === service.id &&  searchData?.selectedSearchedItem.source == 'ServiceType' ? 'white' : 'black',
                                                                            '&:focus': {
                                                                                color: 'black',
                                                                            },
                                                                        }}
                                                                        primary={service.service_type}
                                                                    />
                                                                </ListItem>
                                                            ))}
                                                        </Stack>
                                                    )}
                                                    {suggestions.outlets.length > 0 && (
                                                        <>
                                                            <ListItem sx={{ mb: 0, pb: 0 }}>
                                                                <Typography variant="subtitle1" sx={{ fontWeight: 700, color: "#1f1f1f" }}>{t('Outlets')}</Typography>
                                                            </ListItem>

                                                            {suggestions.outlets.map(outlet => (
                                                                <ListItem
                                                                    key={outlet.id}
                                                                    button
                                                                    onClick={() => handleSelectChange(outlet, 'Outlet')}
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        mt: 0, pt: 0,
                                                                        mb: 0, pb: 0,
                                                                        // backgroundColor:  searchData?.selectedSearchedItem.id == outlet.id &&  searchData?.selectedSearchedItem.source == 'Outlet' ? theme.palette.primary.main : 'white',
                                                                        backgroundColor: 'white',
                                                                    }}
                                                                >
                                                                    <ListItemText
                                                                        sx={{
                                                                            // color:  (searchData?.selectedSearchedItem.id === outlet.id &&  searchData?.selectedSearchedItem.source == 'Outlet') ? 'white' : 'black',
                                                                            color: '#1f1f1f',
                                                                            '&:focus': {
                                                                                color: 'black',
                                                                            },
                                                                        }}
                                                                        primary={outlet.name}
                                                                    />
                                                                </ListItem>
                                                            ))}
                                                        </>
                                                    )}
                                                </List>
                                            </Box>
                                        )}

                                    </Stack>

                                    <Stack flex={1} flexDirection={'column'} alignItems={'center'} justifyContent={'space-between'}>

                                        <Stack width={'100%'} flexDirection={'row'} flex={1} justifyContent={'center'} alignItems={'flex-start'} sx={{ mt: 6 }} display={(showSuggestion && inputRef.current !== null) ? 'none' : 'flex'}>
                                            {categories.slice(0, 3).map((item, index) => (
                                                <Stack flex={1} display="flex" flexDirection="column" alignItems="center" onClick={() => handleBoxClick(item)} width={70} sx={{ mb: 1 }} >
                                                    <Paper
                                                        elevation={6}
                                                        sx={(theme) => ({
                                                            height: isSmallScreen ? 50 : 55,
                                                            width: isSmallScreen ? 50 : 55,
                                                            borderRadius: 2,
                                                            backgroundColor: '#fff',
                                                            cursor: 'pointer', backgroundColor: 'white',
                                                            position: 'relative', justifyContent: 'center',
                                                            alignItems: 'center',
                                                            border: searchData?.category_ids?.id === item.id ? `2px solid ${theme.palette.primary.main}` : 'none',
                                                            ...theme.applyStyles('dark', {
                                                                backgroundColor: '#1A2027',
                                                            }),
                                                        })}>

                                                        <img
                                                            src={`https://fiind-dev.s3.amazonaws.com/${item.image}`}
                                                            alt="IconOne"
                                                            style={{
                                                                width: '60%',
                                                                height: '60%',
                                                                objectFit: 'contain',
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)'
                                                            }}
                                                        />
                                                    </Paper>

                                                    <Typography
                                                        variant="body1"
                                                        color="textSecondary"
                                                        textAlign="center"
                                                        mt={1.5}
                                                        sx={{
                                                            fontSize: '0.65rem',
                                                            minHeight: '20px',
                                                            maxWidth: 70,
                                                            lineHeight: '1.2',

                                                            color: '#1f1f1f'
                                                        }}
                                                    >
                                                        {item.category}
                                                    </Typography>
                                                </Stack>

                                            ))}

                                        </Stack>

                                        <Stack width={'100%'} flexDirection={'row'} flex={1} justifyContent={'center'} alignItems={'flex-start'} sx={{ mt: 2 }} display={(showSuggestion && inputRef.current !== null) ? 'none' : 'flex'}>
                                            {boxContent.map((item, index) => (
                                                // <Grid key={index} item lg={4} md={4} sm={4} xs={4} sx={{backgroundColor:'pink'}} flexDirection='row' alignItems={'center'} justifyContent={'center'}>
                                                <Stack flex={1} display="flex" flexDirection="column" alignItems="center" onClick={() => selectedOption(item)} width={70} >
                                                    <Paper
                                                        elevation={6}
                                                        sx={(theme) => ({
                                                            // height: isSmallScreen ? 70 : 75,
                                                            // width: isSmallScreen ? 70 : 75,
                                                            height: isSmallScreen ? 50 : 55,
                                                            width: isSmallScreen ? 50 : 55,
                                                            borderRadius: 3,
                                                            backgroundColor: '#fff',
                                                            cursor: 'pointer', backgroundColor: 'white',
                                                            position: 'relative', justifyContent: 'center',
                                                            alignItems: 'center',
                                                            border: searchData?.selectedSearchOption?.id === item.id ? `2px solid ${theme.palette.primary.main}` : 'none',
                                                            ...theme.applyStyles('dark', {
                                                                backgroundColor: '#1A2027',
                                                            }),
                                                        })}
                                                    >
                                                        <img
                                                            src={item.icon}
                                                            alt="IconOne"
                                                            style={{
                                                                width: '45%',
                                                                height: '45%',
                                                                objectFit: 'contain',
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)'
                                                            }}
                                                        />

                                                        {(index === boxContent.length - 1 && searchData?.selectedServiceIds.length > 0 && searchData.selectedSearchOption?.id == 3) && (

                                                            <Box
                                                                position="absolute"
                                                                top={5}
                                                                right={5}
                                                                bgcolor="primary.main"
                                                                color="white"
                                                                borderRadius="50%"
                                                                width="24px"
                                                                height="24px"
                                                                display="flex"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                sx={{
                                                                    fontSize: '0.75rem',
                                                                    transform: 'translate(50%, -50%)',
                                                                }}
                                                            >
                                                                <Typography variant='body2' sx={{ color: 'white' }}>
                                                                    {searchData?.selectedServiceIds.length}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Paper>

                                                    <Typography
                                                        variant="body1"
                                                        color="textSecondary"
                                                        textAlign="center"
                                                        mt={1.5}
                                                        sx={{
                                                            fontSize: '0.65rem',
                                                            minHeight: '20px',
                                                            maxWidth: 70,
                                                            lineHeight: '1.2',
                                                            color: '#1f1f1f'
                                                        }}
                                                    >
                                                        {item.bottomText}
                                                    </Typography>
                                                </Stack>
                                                // </Grid>
                                            ))}
                                        </Stack>


                                    </Stack>

                                    {!isMdScreen &&
                                        <Box sx={{ alignItems: 'flex-end', display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'flex-end', height: 50, mt: 0, mb: 2 }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    borderRadius: '50%',
                                                    minWidth: 0,
                                                    width: '50px',
                                                    height: '50px',
                                                    bgcolor: theme.palette.primary.main,
                                                    '&:hover': {
                                                        bgcolor: theme.palette.primary.main,
                                                    },
                                                }}
                                                onClick={handleToggle}
                                            >
                                                <img
                                                    src={mapSpanIconForMobile}
                                                    alt="IconOne"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'contain',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)'
                                                    }}
                                                />
                                            </Button>

                                        </Box>
                                    }

                                </Stack>
                            )}

                            {isMdScreen ?
                                <Box display="flex" width={isLeftBoxVisible ? '75%' : '100%'}>
                                    <MapComponent allStore={allOutlets} searchedData={searchData} store={originalStores} apiKey={apiKey} leftBoxVisible={isLeftBoxVisible} handleClick={handleToggle} onUserLocationChange={(location) => {
                                        setMapComponentLoaded(true),
                                            setStores([]),
                                            setOffset(0),
                                            setHasMore(false),
                                            setServices([]),
                                            setOriginalStores([]),
                                            setSearchData((prev) => ({ ...prev, ...location, callApi: true }))
                                    }} />
                                </Box>
                                :
                                (isLeftBoxVisible == false && (
                                    <Box display="flex" width={isLeftBoxVisible ? '70%' : '100%'}>
                                        <MapComponent allStore={allOutlets} searchedData={searchData} store={originalStores} leftBoxVisible={isLeftBoxVisible} apiKey={apiKey} handleClick={handleToggle} onUserLocationChange={(location) => {
                                            // setMapComponentLoaded(true),setUserLocation(location)
                                            setMapComponentLoaded(true),
                                                setStores([]),
                                                setOffset(0),
                                                setHasMore(false),
                                                setServices([]),
                                                setOriginalStores([]),
                                                setSearchData((prev) => ({ ...prev, ...location, callApi: true }))
                                        }
                                        } />
                                    </Box>
                                ))
                            }

                        </Box>

                        {/* {!isSmallScreen ? */}
                        <ClinicServiceTabs
                            title={
                                (searchData?.category_ids !== '-1' && searchData?.category_ids !== null)
                                    ? `${searchData?.category_ids.category ?? ''}${searchData?.selectedSearchOption?.id <= 2 ? ` - ${searchData?.selectedSearchOption?.bottomText}` : ''}`
                                    : `All clinics${searchData?.selectedSearchOption?.id <= 2 ? ` - ${searchData?.selectedSearchOption?.bottomText}` : ''}`
                            }
                            // count={selectedTab === 0 ? (stores?.length || 0) : (services?.length || 0)}
                            count={totalCount}
                            isExtendedSearch={searchData?.selectedSearchOption?.id == 3 || searchData?.selectedSearchedItem?.source == 'ServiceType' || searchData?.selectedSearchOption?.bottomText == 'Special offers'}
                            onTabChange={handleTabChange}
                            // stores={stores}
                            // services={services}
                            // onSort={handleSort}
                            setSorting={(e) => { handleSort(e) }}
                            setDistanceSorting={(e) => { handleDistanceSort(e) }}
                            sorting={searchData?.sorting}
                            distanceSorting={searchData?.kilometer}
                            selectedTab={selectedTab}
                            tabLoading={tabLoading}
                        />
                        {/* :
                            null
                        } */}

                    </>
                }

                <InfiniteScroll
                    dataLength={dataToRender.length} //This is important field to render the next data
                    next={() => { selectedTab === 0 ? getStores(allData) : fetchAllServices(null) }}
                    hasMore={hasMore}
                    loader={
                        <Grid
                            container
                            spacing={3}
                            sx={{ justifyContent: "flex-start", width: isSmallScreen ? '100%' : 'auto', alignSelf: 'center', paddingBottom: 5, mt: 0 }}
                        >
                            {Array.from(new Array(5)).map((_, index) => (
                                <Grid item key={index} xs={12} sm={12} md={4} lg={2.4}>
                                    <SkeletonCard />
                                </Grid>
                            ))}
                        </Grid>
                    }

                    style={{ paddingLeft: 20, paddingRight: 20, marginTop: 0, paddingTop: 0 }}
                // endMessage={
                //     <p style={{ textAlign: 'center' }}>
                //     <b>Yay! You have seen it all</b>
                //     </p>
                // }

                // style={{paddingInline:20}}
                // below props only if you need pull down functionality
                // refreshFunction={this.refresh}
                // pullDownToRefresh
                // pullDownToRefreshThreshold={50}
                // pullDownToRefreshContent={
                //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                // }
                // releaseToRefreshContent={
                //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                // }
                >

                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 0, md: 3 }}
                        sx={{ justifyContent: "flex-start", width: isSmallScreen ? '100%' : 'auto', alignSelf: 'center', paddingBottom: 5, mt: -2 }}
                    >
                        {tabLoading ? (
                            Array.from(new Array(5)).map((_, index) => (
                                <Grid item key={index} xs={12} sm={12} md={4} lg={2.4}>
                                    <SkeletonCard />
                                </Grid>
                            ))
                        ) :
                            (dataToRender.length === 0 ? (
                                <Grid item xs={12}>
                                    <Typography variant='h6' sx={{ marginLeft: 2, marginTop: isSmallScreen ? 5 : 10, flex: 1, textAlign: 'center', color: '#545454' }}>
                                        {selectedTab === 0 ? 'No stores found' : 'No services found'}
                                    </Typography>
                                </Grid>
                            ) : (
                                dataToRender.map((item, index) => {
                                    return (
                                        <Grid item key={item.id} xs={12} sm={12} md={4} lg={2.4} position="relative" >
                                            {renderItem({ item, searchData })}
                                        </Grid>
                                    )
                                })
                            ))}
                    </Grid>

                </InfiniteScroll>

            </Box>

            {open &&
                <AdvanceFilter open={open}
                    handleClose={handleClose}
                    selectedData={searchData}
                    categoryList={categories}
                    // selectedCategoryData={searchData?.category_ids || categories}
                    selectedCategoryData={searchData?.category_ids === '-1' ? categories : searchData?.category_ids}
                    search={advanceSearch}
                />
            }
        </Container >
    );
}

export default StoreMap;
