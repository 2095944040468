import { Avatar, Box, Card, Icon, Radio, Typography, Stack } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import React from 'react'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

function EmployeeCard({ value, checked, employee, }) {
    const empImg = employee?.image;
    const imgg = `https://fiind-dev.s3.amazonaws.com/${empImg}`

    return (
        <div>
            <Card
                sx={{
                    // paddingTop: '10px',
                    border: '2px solid #f7bd98',
                    borderRadius: '15px',
                    textAlign: 'center',
                    width: '100%',
                    minWidth: { xs: '100px', sm: '109px' ,lg:'109px', xl:'109px'},
                    minHeight: { xs: '140px', sm: '140px' },
                    maxHeight: { xs: '155px', sm: '140px' }
                }}
            >
                <Stack
                    // gap={1}
                    flex={1} flexDirection='column' alignItems='center'     justifyContent= 'space-between'
                    sx={{
                        // display: 'flex',
                        // flexDirection: 'column',
                        // justifyContent: 'space-between',
                        // alignItems: 'center',
                        height: 140,
                        pt:0,
                        mt:0,
                        minWidth: { xs: '100px', sm: '109px' ,lg:'109px', xl:'109px'},
                        maxWidth: { xs: '100px', sm: '109px' ,lg:'109px', xl:'109px'},
                     
                    }}
                >
                    <Stack flex={1} flexDirection='column' alignItems='center' sx={{mt:1}}>
                    {empImg != null ? (
                        <Avatar
                            sx={{
                                border: '1px solid #ffffff',
                                width: 50,
                                height: 50,
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={imgg}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                }}
                            />
                        </Avatar>
                    ) :
                    (employee?.id == 0 ?
                        <Avatar
                            sx={{
                                border: '1px solid #545454',
                                width: 50,
                                height: 50,
                                backgroundColor:'white',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                          <Typography variant="h4" sx={{color:'#545454'}}>?</Typography>
                        </Avatar>
                        :
                        <Box
                        sx={{
                            width: 50,
                            height: 50,
                            border: '1px solid #545454',
                            backgroundColor:'white',
                            borderRadius:'50%'
                        }}
                        />
                    )
                    }
                    <Typography 
                     variant="subtitle2"
               component="div"
                    sx={{
                        // width: 100,
                        mb:0,
                        pb:0,
                WebkitLineClamp:2,
                WebkitBoxOrient: 'vertical',
                fontSize:'0.85rem',
                // overflow: 'hidden',
                textOverflow: 'ellipsis', 
                }} > {employee?.name}</Typography>
                </Stack>
                    <Radio
                        sx={{ color: '#f7bd98', mt:0, pt:0}}
                        name="service-radio"
                        value={value}
                        checked={checked}
                    />
                </Stack>
            </Card>
        </div >
    )
}

export default EmployeeCard
