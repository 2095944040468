import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // Login
      auth: {
        shared: {
          emailAddress: 'Email Address',
          mustBeAValidEmail: 'Must be a valid email',
          emailIsRequired: 'Email is required',
          passwordIsRequired: 'Password is required',
          password: 'Password',
          confirmPassword: 'Confirm Password',
          passwordsMustPatch: 'Passwords must match',
          passwordValidation: 'Must be at least 8 characters long',
          verificationCodeIsRequired: 'Verification code is required',
          emailVerification: 'Code verification has been sent to your email',
        },
        login: {
          head: 'Fiind | Login',
          loginMessage: 'Sign in on the internal platform',
          createNewAccount: 'Create new account',
          login: 'Log In',
          signInWithXero: 'Sign in with Xero',
          forgotPassword: 'Forgot password',
        },
        // Registration
        registration: {
          head: 'Fiind | Register',
          register: "Register",
          registerHeader: "Let's create your account",
          registerMessage: "Signing up for Fiind is fast and free.\nNo commitments or long-term contracts.",
          iHaveAnAccount: 'I have an account',
          passwordMustMatch: 'Password must match',
          tncCheckboxRequired: 'This field must be checked',
          iHaveRead: 'I have read the',
          tnc: 'Terms and Conditions',
          signUpWithXero: 'Sign up with Xero',
        },
        // Forgot Password
        forgotPassword: {
          head: 'Fiind | Password Recovery',
          passwordRecovery: 'Password Recovery',
          tellusYourEmail:
            'Enter your email to receive password reset instruction.',
          forgotPasswordSuccessMessage:
            'Please check your email for the password reset instructions.',
          forgotPasswordFailedMessage:
            'Email not found. Please verify your email is correct.',
          recoverPassword: 'Recover Password',
        },

        // Reset Password
        resetPassword: {
          head: 'Fiind | Password Reset',
          passwordReset: 'Password Reset',
          resetYourAccountPassword:
            'Reset your account password using your code',
          resetPasswordSuccessMessage:
            'Successfully reset password. You will be redirected to sign in.',
          resetPasswordFailedMessage:
            'Oops. Something went wrong. Please try again later.',
        },

        verifyEmail: {
          head: 'Fiind | Verify Email',
          verifyCode: 'Verify Your Email',
          verifyCodeMessage:
            'A verification code has been sent to your registered email',
          verify: 'Verify',
          resendVerificationCode: 'Resend verification code',
          skipForNow: 'Skip for now',
          verificationCode: 'Verification code',
          resendOTPSuccessMessage:
            'New verification code sent to registered email',
          verifyReminder: {
            subtitle: 'Verify your email and enable 2FA to secure your account',
            title: 'Verify your email',
            buttonText: 'Verify email',
          },
        },
        _2FA: {
          head: 'Fiind | Two-Factor Authentication',
          verifyCode: 'Two-Factor Authentication',
          verifyCodeMessage:
            "Your account is protected with two-factor authentication. We've sent you an email with an one-time-password. Please enter the code below",
          verify: 'Continue',
        },
      },

      // Account Popover
      accountPopover: {
        settings: 'Settings',
        changeOrganization: 'Change organization',
        logout: 'Logout',
        addCompany: 'Add new company',
      },
      organizationPopover: {
        search: 'Search company',
      },

      // Accept team invitation
      teamInvitation: {
        head: 'Accept Invitation',
        acceptInvitation: 'Accept Invitation',
        acceptInvitationMessage: 'Create A New Fiind Account',
        acceptInvitationMessage2: 'Enter your email and password',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phoneNo: 'Phone Number',
        countryCode: 'Country Code',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        iHaveRead: 'I have read the',
        tnc: 'Terms and Conditions',
        firstNameIsRequired: 'First Name is required',
        lastNameIsRequired: 'LastName is required',
        emailIsRequired: 'email is required',
        phoneNoIsRequired: 'Phone Number is required',
        countryCodeIsRequired: 'Country Code is required',
        passwordIsRequired: 'Password is required',
        passwordMustMatch: 'Passwords must match',
        tncCheckboxRequired: 'This field must be checked',
        submitSuccessMessage: 'invitation has been accepted succecfully',
      },

      // Server Error - 500
      errorServerError: 'Error: Server Error',
      internalServerError: '500: Internal Server Error',
      tryUsingTheNavigation:
        'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
      backToDashboard: 'Back to Dashboard',

      // Reports
      reports: {
        welcome: 'Welcome to Fiind!',
        welcomeDescription:
          'Tell us a little more about your business to activate your account.',
        startOnboardingButton: 'Get Started',
        hello: 'Hello',
        financialMissionControl: 'Financial Mission Control',
        period: 'Period',
        last3Months: 'Last 3 Months',
        last6Months: 'Last 6 Months',
        thisYear: 'This Year',
        lastYear: 'Last Year',
        revenueProfitChart: {
          tooltip: 'These are your revenue and profits',
          title: 'Income',
        },
        generationPage: {
          date: 'Date of issue',
          perview: 'Preview',
          download: 'Download',
          compareWith: 'Compare with',
          comparePeriod: 'Compare period',
          dateRange: 'Date range',
          startDate: 'Start date',
          endDate: 'End Date',
          generateButton: 'Generate',
          duration: 'Days',
          repetitionNumber: 'Days Frequency',
          selectUsers: 'Select users',
          days: 'Days',
          durationMessage: 'This report will be generated automatically every',
          reminderPreference: 'Reminder Preference',
          reportGeneration: 'Report Generation',
          skip: 'Only generate',
          save: 'Save',
          additionalConfig: 'Additional Config',
          once: 'Once',
          twice: 'Twice',
          for: 'For',
          times: 'Times',

        },
        pastReports: {
          type: 'Type',
          period: 'Period',
          lastDownloadAt: 'Last Download At',
        },
        reports: 'Reports',
        myReports: 'My Reports',
        generate: 'Generate',
        revenue: 'Revenue',
        expenses: 'Expenses',
        expensesTitle: 'Total Expenses',
        messages: 'Messages',
        costOfSales: 'Cost Of Sales',
        grossProfit: 'Gross Profit',
        grossProfitMargin: 'Gross Profit Margin',
        netProfit: 'Net Profit',
        netProfitMargin: 'Net Profit Margin',
        receivables: 'Receivables',
        payables: 'Payables',
        cashInflow: 'Cash Inflow',
        cashOutflow: 'Cash Outflow',
        currentAssets: 'Current Assets',
        currentLiabilities: 'Current Liabilities',
        hashIndex: '#',
        name: 'Name',
        amountData: 'Amount',
        bankBalances: 'Bank Balances',
        creditCardsBalances: 'Credit Cards Balances',
        sinceLastMonth: 'Since Last Month',
        ratioData: ' 10%',

        companyDetails: 'Company Details',
        billing: 'Billing',
        changePassword: 'Change Password',
        connections: 'Connections',
        termsOfService: 'Terms of Service',
        privacyPolicy: 'Privacy Policy',
        //Banner
        titleCompletePayment: 'Complete your payment now',
        subtextCompletePayment:
          'Provide your credit card billing information to continue using Fiind.',
        buttonTextCompletePayment: 'Add billing info',
        titleOnboardingCall: 'Book your onboarding call now!',
        subtextOnboardingCall:
          "Get introduced to your accountant. They'll get to know your business, show you how Fiind works, and gather everything they need from you.",
        buttonTextOnboardingCall: 'Book call',
        titleConnectBooks: 'Connect your books',
        subtextConnectBooks:
          "If you don't want to see dummy data for life, connect your accounting software or upload your excel sheets (any format). We will take your transactions and ledgers in any condition and make it awesome.",
        buttonTextConnectBooks: 'Connect Accounting Software',
        secondButtonTextConnectBooks: 'Upload Excel file(s)',
        titleAccountingInformation:
          'Your accounting information is being reviewed.',
        subtextAccountingInformation:
          'Thanks for sending us the documents. We will get back to you within 2 business days.',
        bankComingSoon:
          'Your bank and credit card balances will be available soon.',
        businessDocumentUploaded: {
          title: 'Your onboarding is not completed!',
          subtitle:
            'Upload your BR and Certification of Incorporation to complete your onboarding process',
          buttonText: 'Upload documents',
        },


        salesSummary: 'Sales summary',
        salesTrends: 'Sales trends',
        paymentMethods: 'Payment methods',
        itemSales: 'Item sales',
        categorySales: 'Category sales',
        discounts: 'Discounts',
        modifierSales: 'Modifier sales',
        comps: 'Comps',
        voids: 'Voids',
        taxes: 'Taxes',
        giftCards: 'Gift cards',
        cashDrawers: 'Cash drawers',
        activityLog: 'Activity Log'
      },

      // messageList
      messageList: {
        welcome: 'Hello there!',
        welcomeInfo1: 'You have',
        welcomeInfo2: 'pending items',
        noPendingItems: 'You have no pending items!',
        expenseMessage: 'Expenses that require your action',
        expenseSecondaryMessage: 'Please provide the missing information',
        reimbursementMessage: 'Expense claims that require your action',
        reimbursementSecondaryMessage: 'Please review these items',
      },

      // Onboarding
      onboarding: {
        head: 'Fiind | Onboarding',
        companyName: 'Your company name',
        companyNamePlaceholder: 'Fiind',
        companyNameErrorMessage: 'Company name cannot be blank.',

        country: 'Where are you incorporated?',
        countryPlaceholder: 'Select country',
        countryErrorMessage: 'Please select a country',

        currency: 'Default currency',
        currencyPlaceholder: 'Select currency',
        currencyErrorMessage: 'Please select a currency.',

        industry: 'What is your industry',
        industryPlaceholder: 'Select industry',
        industryErrorMessage: 'Please select an industry',

        businessRegistration: 'Business Registration',
        incorporationCertificate: 'Certificate of Incorporation',

        continue: 'Continue',
        skip: 'I’ll complete this later',

        // Personal Info
        personalInfo: 'Personal Informations',
        personalInfoHeading: 'Tell us a little about you:',
        personalInfoSubHeading:
          'Create a free SwiftBill account to get a better view of your financials.',
        yourName: 'Your name',
        firstName: 'First name',
        firstNameErrorMessage: 'First name cannot be blank.',
        lastName: 'Last name',
        lastNameErrorMessage: 'Last name cannot be blank.',
        mobileNumber: 'Mobile number',
        mobileNumberErrorMessage: 'Mobile number cannot be blank.',
        countryCode: 'Country code',
        countryCodeErrorMessage: 'Country code cannot be blank.',

        // Business Info
        businessStatus: 'Business Details',
        existingBusinessDescription:
          'You will need your Business registration and Certificate of incorporation to proceed',
        existingBusinessTitle: 'I have a business',
        newBusinessDescription: 'Incorporate your company with SwiftBill',
        newBusinessTitle: 'I don’t have a business',
        businessInfo: 'Business Informations',
        businessInfoHeading: "What's your business status:",
        businessInfoSubHeading:
          'Let us know if your company is formed already.',
        businessInfoHeading2: 'Tell us about your business:',
        businessInfoSubHeading2:
          'We would love to learn more about your business so we could support you better.',

        // Documents
        uploadDocument: 'Upload Documents',
        uploadDocumentHeading: 'Business Documents:',
        uploadDocumentSubHeading:
          'Upload official documents to verify your business.',
        uploadDocumentBusinessReg: 'Business Registration',
        uploadDocumentIncCertificate: 'Certificate of Incorporation',

        // Partner
        partnerStatus: 'Partner Status',
        partnerStatusErrorMessage: 'This filed must be selected',
        haveAccountant: 'Are you currently working with an accountant?',
        haveAuditor: 'Are you currently working with an auditor?',
        haveTaxAgent: 'Are you currently working with a tax agent?',

        // Onboarding completed
        onboardingCompletedHeading: 'All done!',
        accountIsReady: 'Your account is ready',
        backToReports: 'Back to Reports',

        // Complete
        complete: 'Complete',
      },

      expenses: {
        head: 'SwiftBill | Expenses',
        yourExpenses: 'Expenses',
        searchExpenses: "Type a merchant's name...",
        uploadNew: 'New Expense',
        validInvoiceNumber: 'Invoice number is available',
        invalidInvoiceNumber: 'Invoice number is in use',
        all: 'All',
        waitMessage: 'Please wait...',
        processing: 'Processing',
        pendingReview: 'Pending Review',
        pendingApproval: 'Pending Approval',
        paid: 'Paid',
        awaitingPayment: 'Awaiting Payment',
        approved: 'Approved',
        split: 'Split',
        failed: 'Failed',
        sortBy: 'Sort By',
        merchant: 'Merchant',
        amount: 'Amount',
        uploadedBy: 'Uploaded By',
        amountHelper: 'Edit the total amount by updating the line items',
        status: 'Status',
        delete: 'Delete',
        invoiceNumber: 'Invoice Number',
        dateCreated: 'Date Created',
        dateCreatedNewest: 'Date Created (newest)',
        dateCreatedOldest: 'Date Created (oldest)',
        date: 'Date',
        currency: 'Currency',
        deleteExpense: 'Delete expense',
        editExpense: 'Edit expense',
        cancel: 'Cancel',
        update: 'Update',
        duplicate: 'Duplicate',
        download: 'Download',
        addLineItem: 'Add line item',
        quantity: 'Quantity',
        total: 'Total',
        description: 'Description',
        reference: 'Reference',
        referenceInfo:
          "A Reference is used to identify a specific transaction in your books. It is an optional field so don't worry if you are unsuare about what you should put",
        lineItems: 'Line items',
        expenseUpdating: 'Updating expense...',
        expenseDuplicating: 'Duplicating expense...',
        expenseUpdated: 'Expense updated',
        expenseDuplicated: 'Expense duplicated',
        expenseUpdateFail: 'Expense cannot be updated',
        expenseFileTypeError: 'Only JPEG, PNG, HEIC or PDF files are accepted',
        expenseDeleteFail: 'Expense cannot be deleted',
        deleteExpenseSuccess: 'Deleted expense',
        deleteExpenseFailed: 'Unable to delete expense',
        vendorRequired: 'Vendor cannot be blank.',
        dateRequired: 'Date cannot be blank.',
        currencyRequired: 'Currency cannot be blank.',
        amountRequired: 'Amount cannot be blank.',
        spentOn: 'Spent On',
        descriptionMessage: 'you have no old descriptions, please type new one',
        xero: 'XERO',
        approve: 'Approve',
        account: 'Account',
        invoiceId: 'Xero Invoice ID',
        commentPlaceholder: 'Leave a comment',
        resolveThread: 'Resolve Thread',
        sendComment: 'Comment',
        actionNeeded: 'Action Needed',
        expenseResolved: 'Expense has resolved successfully',
        expenseResolvedFail: 'Faild to resolve the thread',
        expenseAddCommentFail: 'Faild to add the comment',
        expenseLineItemsMissed: 'Invoices must contain at least one item',
        quantityRequired: 'Quantity cannot be blank.',
        totalRequired: 'Total cannot be blank.',
        descriptionRequired: 'Description cannot be blank.',
        accountRequired: 'account cannot be blank.',
        downloadExpenseErrorMessage: "File doesn't exist",
        comment: 'Comments',
        thisExpenseWasAClaim: 'This expense was a claim.',
        view: 'View',
        // claimDetails:
        //   'Originally submitted by <1>{{submitter}}</1> on <2>{{createdAt}}</2>. Paid via <3>{{paymentMethod}}</3> for an amount of <4>{{currency}}</4> <5>{{claimAmount}}</5>. Approved on <6>{{approvedDate}}</6> by <7>{{approvers}}</7>.',
        claimDetails:
          'Originally submitted by {{submitter}} on {{createdAt}}. Paid via {{paymentMethod}} for an amount of {{currency}} {{claimAmount}}. Approved on {{approvedDate}} by {{approvers}}.',
      },

      // Reimbursements
      reimbursements: {
        head: 'SwiftBill | Reimbursements',
        title: 'Reimbursements',
        name: 'Name',
        amount: 'Amount',
        user: 'Submitted by',
        approver: 'Approver(s)',
        expenseDate: 'Expense Date',
        submittedDate: 'Submitted Date',
        approvedOn: 'Approved on',
        rejectedOn: 'Rejected on',
        pendingApprovalfrom: 'Pending approval from',
        createdOn: 'Created on',
        updatedOn: 'Updated on',
        receipt: 'Receipt',
        memo: 'Memo',
        paymentType: 'Payment Type',
        paymentTypePlaceholder: 'How was this paid?',
        approverPlaceholder: 'Assign an approver(s)',
        createReimbursement: 'New Reimbursement',
        paymentTypeRequired: 'payment type is required',
        approverRequired: 'you must assign an approver',
        details: 'Details',
        history: 'History',
        comments: 'Comments',
        uploadFile: 'Upload file',
        action: 'Action',
        approvalStatus: 'Approval Status',
        delete: 'Delete',
        create: 'Create',
        cancel: 'Cancel',
        submit: 'Submit',
        close: 'Closed',
        reasonInputLabel: 'Your reason for rejection',
        rejectionReason: 'What is your reason for rejection?',
        reasonRequiredMessage: 'Please type a reason',
        edit: 'Edit',
        spendDate: 'Spend Date',
        marchantName: 'Merchant Name',
        paidVia: 'Paid via',
        totalAmount: 'Total Amount',
        claimAmount: 'Claim Amount',
        uploader: 'Uploader',
        uploadDate: 'Upload Date',
        saveChanges: 'Save Changes',
        editing: 'Editing',
        id: 'ID',
        customerName: 'Customer Namer',
        change: 'change',
        searchReimbursements: 'Start typing to search',
        uploadNew: 'New Reimbursement',
        sync: 'Sync',
        validInvoiceNumber: 'Invoice number is available',
        invalidInvoiceNumber: 'Invoice number is in use',
        all: 'All',
        processing: 'Processing',
        pendingReview: 'Pending Review',
        completed: 'Completed',
        approved: 'Approved',
        rejected: 'Rejected',
        pendingApproval: 'Pending Approval',
        reject: 'Reject',
        failed: 'Failed',
        sortBy: 'Sort By',
        merchant: 'Merchant',
        uploadedBy: 'Uploaded By',
        amountHelper: 'Edit the total amount by updating the line items',
        status: 'Status',
        invoiceNumber: 'Invoice Number',
        dateCreated: 'Date Created',
        dateCreatedNewest: 'Date Created (newest)',
        dateCreatedOldest: 'Date Created (oldest)',
        date: 'Date',
        currency: 'Currency',
        deleteReimbursement: 'Delete Reimbursement',
        editReimbursement: 'Edit Reimbursement',
        update: 'Update',
        addLineItem: 'Add line item',
        quantity: 'Quantity',
        total: 'Total',
        description: 'Description',
        reference: 'Reference',
        referenceInfo:
          "A Reference is used to identify a specific transaction in your books. It is an optional field so don't worry if you are unsuare about what you should put",
        lineItems: 'Line items',
        reimbursementUpdating: 'Updating reimbursement...',
        reimbursementSyncing: 'Syncing reimbursement...',
        reimbursementUpdated: 'Reimbursement updated',
        reimbursementSynced: 'Reimbursement synced',
        reimbursementUpdateFail: 'Reimbursement cannot be updated',
        reimbursementFileTypeError:
          'Only JPEG, PNG, HEIC or PDF files are accepted',
        reimbursementDeleteFail: 'Reimbursement cannot be deleted',
        deleteReimbursementSuccess: 'Deleted reimbursement',
        deleteReimbursementFailed: 'Unable to delete reimbursement',
        syncReimbursementFailed: 'Unable to sync reimbursement',
        vendorRequired: 'Vendor cannot be blank.',
        dateRequired: 'Date cannot be blank.',
        currencyRequired: 'Currency cannot be blank.',
        amountRequired: 'Amount cannot be blank.',
        spentOn: 'Spent On',
        descriptionMessage: 'you have no old descriptions, please type new one',
        xero: 'XERO',
        approve: 'Approve',
        ready: 'Ready',
        makeReady: 'Make Ready',
        account: 'Account',
        invoiceId: 'Xero Invoice ID',
        processingLoading: 'Processing...',
        processed: 'Processed!',
        processeFaild: 'Faild To Process',
        approvingLoading: 'Approving Expense...',
        approveFaild: 'Faild To Approve',
        rejectingLoading: 'Rejecting Expense...',
        rejectFaild: 'Faild To Reject',
        reimbursementLineItemsMissed: 'Invoices must contain at least one item',
        quantityRequired: 'Quantity cannot be blank.',
        totalRequired: 'Total cannot be blank.',
        descriptionRequired: 'Description cannot be blank.',
        accountRequired: 'account cannot be blank.',
        detailsPage: {
          head: 'Reimbursement Details',
        },
        rejectedBy: 'Rejected by {{name}} on {{date}}',
        reason: 'Reason: ',
      },

      //settings
      settings: {
        save: 'Save',
        accountDetails: 'Account Details',
        companyDetails: 'Company Details',
        billing: 'Billing',
        team: 'Team',
        inviteTeam: 'Invite Members',
        member: 'Member',
        inviteMembersTypography: 'You currently pay for 2 Editor Seats.',
        inviteUserEmailLabel: 'Email Address',
        inviteUserEmailPlaceholder: 'Add Email Address To Invite To Your Team',
        roleLabel: 'Role',
        role: 'Role',
        changeRole: 'Change Role',
        roleOptionsOwner: 'Owner',
        roleOptionsManger: 'Manager',
        roleOptionsTeamMember: 'Team Member',
        roleOptionsRemoveMember: 'Remove',
        updateMemberRole: 'Member role has been updated successfully',
        updateMemberRoleFaild: 'can not update member role',
        deleteMember: 'Member has been deleted successfully',
        deleteMemberFaild: 'can not remove member',
        roleIsRequired: 'must provide a role',
        sendInvite: 'Send Invite',
        changePasswordTab: 'Change Password',
        connections: 'Connections',
        termsOfService: 'Terms of Service',
        privacyPolicy: 'Privacy Policy',
        myAccount: 'My Account',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        phoneNumber: 'Phone Number',
        oldPassword: 'Old Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        companyName: 'Your company name',
        manageYourConnections: 'Manage Your Connections',
        fieldRequired: 'This is a required field',
        //settings
        accountDetailsUpdated: 'Account details updated',
        accountDetailsUpdateFailed: 'Can not update Account details',
        invitationSent: 'Invitation sent successfully',
        invitationSentFailed: 'Can not send invitation',
        changePassword: {
          passwordMustMatch: 'Passwords must match',
          must8characterLong: 'Must be at least 7 characters',
          oldPasswordRequired: 'Old password required',
          newPasswordRequired: 'New password required',
          confirmPasswordRequired: 'Confirm password required',
          passwordUpdated: 'Password updated',
          passwordUpdateFailed: 'Password update failed',
        },
      },

      // Documents
      documents: {
        head: 'SwiftBill | Documents',
        heading: 'Documents',
        addFiles: 'Add Files',
        deleteDocumentSuccess: 'Deleted document',
        deleteDocumentFailed: 'Unable to delete document',
        searchDocuments: "Type a document's name...",

        noFiltersApplied: 'No filters applied',
        fileName: 'File Name',
        uploadedAt: 'Uploaded At',
        actions: 'Actions',
        download: 'Download',
        tags: 'Tags',
        drawer: {
          selectingStep: {
            title: 'What are you uploading?',
            limitError: '"Please select at least one file type"',
            actions: {
              cancel: 'Cancel',
              next: 'Next',
            },
          },
          uploadStep: {
            title: 'Upload your files',
            limitError: 'Please upload at least one document',
            actions: {
              back: 'Back',
              upload: 'Upload',
            },
          },
        },
      },

      // Documents
      devices: {
        head: 'Fiind | Devices',
        heading: 'Devices',
        subHeading: 'Devices help you to track all your devices.',
        createDevice: 'Create a device',
        deleteDeviceSuccess: 'Deleted device',
        deleteDeviceFailed: 'Unable to delete device',
        assignItems: 'Assign Items',
        rename: 'Rename',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteDevice: 'Delete device',
        createDeviceSuccess: 'device created Successfully',
        deviceName: "device Name",
        deviceNameRequired: 'device Name is required',

      },

      // Documents
      transactions: {
        head: 'SwiftBill | Transactions',
        heading: 'Transactions',
        subHeading: 'Devices help you to track all your devices.',
        createDevice: 'Create a device',
        deleteDeviceSuccess: 'Deleted device',
        deleteDeviceFailed: 'Unable to delete device',
        assignItems: 'Assign Items',
        rename: 'Rename',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteDevice: 'Delete device',
        createDeviceSuccess: 'device created Successfully',
        deviceName: "device Name",
        deviceNameRequired: 'device Name is required',

      },
      // Documents
      categories: {
        head: 'Fiind | Categories',
        heading: 'Categories',
        subHeading: 'Categories help you arrange and organise your items, report on item sales and route items to specific printers.',
        createCategory: 'Create a Category',
        deleteCategorySuccess: 'Deleted category',
        deleteCategoryFailed: 'Unable to delete category',
        assignItems: 'Assign Items',
        rename: 'Rename',
        edit: 'Edit',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteCategory: 'Delete Category',
        createCategorySuccess: 'Category created Successfully',
        createCategoryFail: 'Failed to create Category',

        updateCategorySuccess: 'Category updated Successfully',
        updateCategoryFail: 'Failed to update Category',

        categoryName: "Category Name",
        categoryTitle: "Category Title",
        categoryNameRequired: 'Category Name is required',
        categoryTitleRequired: 'Category Title is required',

      },

      itemLibrary: {
        head: 'SwiftBill | Categories',
        heading: 'Categories',
        subHeading: 'Categories help you arrange and organise your items, report on item sales and route items to specific printers.',
        createItem: 'Create an Item',
        deleteCategorySuccess: 'Deleted category',
        deleteCategoryFailed: 'Unable to delete category',
        assignItems: 'Assign Items',
        rename: 'Rename',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteCategory: 'Delete Category',
        createCategorySuccess: 'Category created Successfully',
        categoryName: "Category Name",
        categoryNameRequired: 'Category Name is required',

      },
      discounts: {
        head: 'SwiftBill | Discounts',
        createDiscount: 'Create a Discount',
        deleteDiscountSuccess: 'Deleted Discount',
        deleteDiscountFailed: 'Unable to delete Discount',
        rename: 'Rename',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteDiscount: 'Delete Discount',
        createDiscountSuccess: 'Discount created Successfully',
        discountName: "Discount Name",
        dategoryNameRequired: 'Discount Name is required',

      },

      units: {
        head: 'SwiftBill | Units',
        heading: 'Units',
        subHeading: 'Units are used to specify how you want to measure your items.',
        createUnit: 'Add a Unit',
        editUnit: 'Edit Unit',
        deleteUnitSuccess: 'Unit Deleted',
        deleteUnitFailed: 'Unable to delete unit',
        assignItems: 'Assign Items',
        rename: 'Rename',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteUnit: 'Delete Unit',
        createUnitSuccess: 'Unit created Successfully',
        createUnitFailed: 'Failed to create Unit!',
        updateUnitSuccess: 'Unit updated Successfully',
        updateUnitFailed: 'Failed to update Unit!',
        unitName: 'Unit Name',
        abbreviation: 'Abbreviation',
        precision: 'Precision',
        unitNameRequired: 'Unit Name is required',
        abbreviationRequired: 'Abbreviation is required',

      },

      settingCompReason: {
        createCompReason: 'Create Comp Reason',
        compReasonCreateSuccess: 'Comp Reason Createted Successfully',
        compReasonUnableToCreate: 'Unable To Create Comp Reason',
        compReasonUpdateSuccess: 'Comp Reason Updated Successfully',
        compReasonUnableToUpdate: 'Unable To Update Comp Reason',
        compReasonDeleteSuccess: 'Comp Reason Deleted Successfully',
        compReasonUnableToDelete: 'Unable To Delete Comp Reason',
      },

      settingVoidReason: {
        createVoidReason: 'Create Void Reason',
        voidReasonCreateSuccess: 'Void Reason Createted Successfully',
        voidReasonUnableToCreate: 'Unable To Create Void Reason',
        voidReasonUpdateSuccess: 'Void Reason Updated Successfully',
        voidReasonUnableToUpdate: 'Unable To Update Void Reason',
        voidReasonDeleteSuccess: 'Void Reason Deleted Successfully',
        voidReasonUnableToDelete: 'Unable To Delete Void Reason',
      },

      chartOfAccounts: {
        head: 'Chart of Accounts',
        searchChartOfAccounts: 'Search by accounts name',
        noFiltersApplied: 'No filters applied',
        types: 'Types',
        actions: 'Actions',
        code: 'Code',
        name: 'Name',
        type: 'Type',
        class: 'Class',
        heading: 'Chart of Accounts',
        refresh: 'Refresh',
        createAccount: 'Create account',
      },

      diningOption: {
        createDiningOptions: 'Create Dining Options',
        createDiningOptionSuccess: 'Dining Option Created Successfully',
        createDiningOptionFailed: 'Unable To Create Dining Option',
        diningOptionUpdateSuccess: 'Dining Option Updated Successfully',
        diningOptionUpdateFiled: 'Failed To Update Dining Option',
        diningOptionDeleteSuccess: 'Dining Option Deleted Successfully',
        diningOptionUnableToDelete: 'Unable To Delete Dining Option',
        selectInputLabel: 'Location'
      },

      // Sales
      sales: {
        head: 'SwiftBill | Sales',
        heading: 'Sales',
        addFiles: 'Add Files',
        name: 'Name',
        type: 'Type',
        status: 'Status',
        uploadedOn: 'Uploaded On',
        action: 'Action',
        postSelected: 'Post Selected',
        cancelButton: 'Cancel',
        uploadButton: 'Upload',
        details: {
          head: 'Sales Report Details',
          title: 'Sales Reports',
          details: 'Details',
          uploadedOn: 'Uploaded on',
          postedOn: 'Posted on',
          approveAndPost: 'Approve & Post',
          alreadyPosted: 'Already Posted',
          contactName: 'Contact Name',
          invoiceNumber: 'Invoice Number',
          reference: 'Reference',
          invoiceDate: 'Invoice Date',
          dueDate: 'Due Date',
          description: 'Description',
          quantity: 'Quantity',
          unitAmount: 'Unit Amount',
          aaccountCode: 'Account Code',
          taxType: 'Tax Type',
          currency: 'Currency',
          postReportLoading: 'Posting report',
          postReportSuccess: 'Posted report',
        },
      },

      // Pricing
      pricing: {
        monthly: '/mo',
        yearly: '/yr',
        talkToUs: 'Talk To Us',
        heading: 'Packages that grow with you',
        subheading:
          'No onboarding fees, no hidden fees. What you see is what you get.',
        discount: '10% OFF',
        yearlyPayment: 'Annual Billing',
        monthlyPayment: 'Monthly Payment',
        redirecting: 'Redirecting you to payments...',
        starter: {
          name: 'Starter',
          subheading: 'Monthly Revenues under HK$80k',
        },
        growth: {
          name: 'Growth',
          subheading: 'Monthly Revenues over HK$80k',
        },
        venture: {
          name: 'Venture',
          price: 'Custom',
          subheading: 'Digitise your finance department',
        },
        cta: 'Get This Plan',
        features: {
          accountantAccess: '24/7 accountant access',
          monthlySubscriptionToXero: 'Monthly subscription to Xero™',
          unlimitedTransactions: 'Unlimited transactions',
          aiProcessingInvoices: 'AI processing for invoices',
          aiProcessingReceipts: 'AI processing for receipts',
          airWallex: 'Airwallex™ account included',
          monthlyFinancialReports: 'Monthly financial reports',
          monthlyCFOReviews: 'Monthly CFO reviews',
          governmentFormsHandling: 'Government forms handling',
          prioritySupport: 'Priority Support',
          annualAudit: 'Annual Audit',
          includesEverythingInGrowth: 'Includes everything in Growth',
          customApiSetup: 'Custom API setup',
          programmeRollout: 'Custom programme rollout',
          customBusinessMetrics: 'Custom business metrics',
          billingManagement: 'Billing management',
          apManagement: 'AP management',
          arManagement: 'AR management',
          dedicatedAccount: 'Dedicated SwiftBill accountant',
          customerSuccessManager: 'Customer Success Manager',
        },
        couponDialogue: {
          title: 'Purchase Plan',
          couponCodePlaceholder: 'Enter coupon code',
          couponApplySuccess: 'Coupon Applied.',
          couponApplyError: 'Coupon not found.',
          couponApplyFaile: 'Price is less than the discount',
        },
      },

      '404': {
        title: 'Error: Not Found',
        heading: '404: The page you are looking for isn’t here',
        subheading:
          'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
        backToHome: 'Back to Home',
      },

      '401': {
        title: 'Error: Authorization Required',
        heading: '401: Authorization required',
        subheading:
          'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
        backToHome: 'Back to Home',
      },

      nav: {
        overview: 'Overview',
        home: 'Home',
        documents: 'Documents',
        // expenses: 'Expenses',
        itemsAndOrders: 'Items & Orders',
        dashboard: 'Dashboard',
        devices: 'Devices',
        online: 'Online',
        transaction: 'Transactions',
        sales: 'Sales',
        management: 'Management',
        chartOfAccounts: 'Chart of Accounts',
        // allExpenses: 'All expenses',
        // reimbursements: 'Reimbursements',
        menus: 'Menus',
        items: 'Items',
        orders: 'Orders',
      },

      itemsNav: {
        itemLibrary: 'Item Library',
        channelListing: 'Channel Listings',
        modifiers: 'Modifiers',
        categories: 'Categories',
        discounts: 'Discounts',
        salesTax: 'Sales Tax',
        options: 'Options',
        units: 'Units',
        customAttributes: 'Custom Attributes',
        settings: 'Settings',
      },

      // Utils
      shared: {
        continue: 'Continue',
        uploadFilesHeading: 'Click or Drag file to this area to upload',
        uploadFilesSubHeading:
          'Supported file types: \n PDF, JPG, JPEG, PNG, HEIC, DOCX, XLSX, CSV',
        maxFileSize: 'Max file size: 5MB',
        fileCannotBlank: 'Please select a file',
        language: 'Language',
        download: 'Download',
        delete: 'Delete',
        rowsPerPage: 'Rows per page:',
      },



      BusinessNotFound: 'Business not found!',
      LookForOtherBusiness: 'Look for other business',
      SelectService: 'Select service',
      ServiceSelected: 'Service selected',
      Next: 'Next',
      BackToService: 'Back to service',
      SelectStaffMember: 'Select staff member',
      NoStaffFound: 'No staff found',
      SelectTimeDate: 'Select time & date',
      AvailableTimes: 'Available times',
      Information: 'Information',
      Verified: 'Verified',
      YourDetails: 'Your details',
      Name: 'Name',
      EmailAddress: 'Email address',
      Address: 'Address',
      IHaveReadAndAcceptedThe: 'I have read and accepted the',
      conditions: 'conditions',
      IWishToReceiveRelevantInformationViaEMailAndSMS: 'I wish to receive relevant information via e-mail and SMS',
      CancellationPolicy: 'Cancellation policy',
      SeeCondition: 'See condition',
      WeHaveSentYourActivationCodeTo: 'We have sent your activation code to',
      pleaseInsertItBelow: ', please insert it below',
      GoBack: 'Go back',
      CompleteBooking: 'Complete booking',
      Service: 'Service',
      Staff: 'Staff',
      CustomerName: 'Customer name',
      TotalAmount: 'Total amount',
      ConfirmBooking: 'Confirm booking',
      WelcomeTo: 'Welcome to',
      IfThereAreAnyQuestionsPleaseContactMeAt: 'If there are any questions, please contact me at:',
      Terms: 'Terms',
      IAcceptConditions: 'I accept conditions',
      AppointmentConfirmedWith: 'Appointment confirmed with',
      BookAnotherApointment: 'Book another appointment',
      BookingFailed: 'Booking failed',
      BookingConfirmed: 'Booking confirmed',
      SelectedSlotDoesNotMatchAnyAvailableTimeSlots: 'Selected slot does not match any available time slots',
      PleaseSelectAValidTimeSlot: 'Please select a valid time slot',
      FailedToVerifyOTP: 'Failed to verify OTP',
      OTPVerified: 'OTP verified',
      FailedToSentOTP: 'Failed to sent OTP',
      OTPSentToYourMobileNumber: 'OTP sent to your mobile number',
      EnterAVlidEmailAddress: 'Enter a valid email address',
      Select: 'Select',


      // stores page
      Standard: 'Standard',
      offertonewclints: 'offer to new clints',
      Consulation: 'Consultation',
      Bundleoffers: 'Bundle offers',
      NoclinicsfoundPleasetryadifferentsearch: 'No clinics found. Please try a different search.',
      Found1clinic: 'Found 1 clinic,',
      Searchamong: 'Search among',
      clinics: 'clinics',
      Noservicesfound: 'No services found',
      Found1service: 'Found 1 service,',
      and: 'and + ',
      services: 'services',
      Searchforaclinicorservice: 'Search for a clinic or service...',
      Loading: 'Loading...',
      Nosuggestionsfound: 'No suggestions found.',
      Services: 'Services:',
      Outlets: 'Clinics:',
      Nostoresfound: 'No stores found',
      Extendedsearch: 'Extended search',
      Filtercategories: ' Filter categories',
      Search: ' Search',
      Searchforservice: 'Search for service...',
      AllServices: 'All Services',
      Filterservices: 'Filter services',
      Beautycosmetics: 'Beauty & cosmetics',
      Price: ' Price',
      From: 'From',
      To: ' To',
      Within: 'Within',
      SelectDistance: 'Select Distance',
      Searchtype: 'Search type',
      Showclinics: 'Show clinics',
      searchAmongTitle: 'Search among 573 clinics',
      searchAmoungSubTitle: 'and +30.000 services',

      //clinic services  tab (tabPanel)
      Clinic: 'Clinic',
      Servicees: 'Service',
      Yoursearchfound49places: 'Your search found 49 places',
      Allshownplacesofferfortreatmentyousearchfor: 'All shown places offer for treatment you search for',
      Choosesearch: 'Choose search',
      Distance: 'Distance',
      Pricelowtohigh: 'Price (low to high)',
      pricehightolow: 'price (high to low)',

      // storeInfo 
      Close: 'Closed',
      Open: 'Open',

      // storeCard
      NoCategories: 'No Categories',
      Visitclinic: 'Visit clinic',
      upto: 'Up to',

      // stepper
      Serviceee: 'Service',
      Employees: 'Employee',
      Time: 'Time',
      Information: 'Information',
      Finished: 'Finished',

      // Services
      Backtocategories: 'Back to categories',
      SelectService: 'Select Service',

      // serviceCardAni
      at: 'At',
      storeName: 'Store Name',
      storeaddresswillsethere: 'store address will set here',
      Bookservice: 'Book service',

      // ServiceCard
      Expireson: 'Expires in',
      ShowLess: 'Show Less',
      ReadMore: 'Read More',

      // otpverification
      Backtoinfo: 'Back to info',
      Approve: 'Approve',

      // Navigator
      Booking: 'Booking',
      AboutUs: 'About Us',
      Pictures: 'Pictures',
      Reviews: 'Reviews',

      // maps
      Allowlocationservices: 'Allow location services',

      // Information
      Backtotime: 'Back to time',
      Information: 'Information',
      Firstname: 'First name',
      Lastname: 'Last name',
      Address: 'Address',
      ZipCode: 'Zip Code',
      City: 'City',
      Phonenr: 'Phone nr',
      Email: 'Email',

      // hours
      OpeningHours: 'Opening hours',
      Closed: 'Closed',

      // header
      Reviews: 'Reviews',
      Review: 'Review',

      // employees
      Backtoservices: 'Back to services',
      SelectEmployee: 'Select Employee',
      Noemployeesavailablefortheselectedservice: 'No employees available for the selected service',

      // Policy
      Terms: 'Terms',

      // completeBooking
      Bookingdetails: ' Booking details',
      Service: 'Service:',
      Employee: 'Employee:',
      Bookingtime: 'Booking time:',
      Totalamount: 'Total amount:',
      Confirmbooking: 'Confirm booking',

      // category
      SelectCategory: 'Select Category',
      SpecialOffer: 'Special Offer',
      CancellationOffers: 'Cancellation Offers',

      //calendar
      Backtoemployee: 'Back to employee',
      Selectdateandtime: 'Select date and time',
      clicnic_is_closed: "Clinic is closed on this day",

      // about us
      ContactInformation: 'Contact Information',
      AboutUs: 'About Us',

      //bookingconfirmed
      thankyouforyourbooking: 'Thank you for booking!',

      // Home screen
      // tab panel component
      heading1: 'All shown places offer for treatment you search for',
      chooseSearch: 'Choose search',
      heading2: 'Your search found',
      heading2LastPart: 'places',
      distance: 'Distance',
      priceLtH: 'Price (low to high)',
      priceHtL: 'Price (high to low)',
      services: 'services',
      searchAmongTitle: 'Search among 573 clinics',
      searchAmoungSubTitle: 'and +30.000 services',
      // cards
      upto: 'Up to',
      discount: 'discount',
      visitClinic: 'Visit clinic',
      offers: 'offers',
      offer: 'offer',

      at: 'at ',
      bookService: 'Book service',
      viewCancellationOffer: 'View cancellation offer',

      // moadal
      showClinics: 'Show clinics',

      otherBookingSystem: 'Prices and services may not be correct, since the clinic isn’t a Fiind Partner',
      otherBookingSystempopup: 'Sorry, this clinic is not a partner of Fiind!',
      otherBookingSystempopupMessage: 'To make a booking at this clinic, please click the button below',
      redirectMessage: 'Redirect me to their booking page',
      policies: 'Policies',
      iAcceptPolicies: 'I accept policies',
      bookAnother: 'Book another appointment',

      chooseSort: 'Choose sort',
      searchWithin: 'Search within',
      sortBy: 'Sort by'

    },
  },

  'den': {
    translation: {
      // Login
      auth: {
        shared: {
          emailAddress: 'Email Address',
          mustBeAValidEmail: 'Must be a valid email',
          emailIsRequired: 'Email is required',
          passwordIsRequired: 'Password is required',
          password: 'Password',
          confirmPassword: 'Confirm Password',
          passwordsMustPatch: 'Passwords must match',
          passwordValidation: 'Must be at least 8 characters long',
          verificationCodeIsRequired: 'Verification code is required',
          emailVerification: 'Code verification has been sent to your email',
        },
        login: {
          head: 'Fiind | Login',
          loginMessage: 'Sign in on the internal platform',
          createNewAccount: 'Create new account',
          login: 'Log In',
          signInWithXero: 'Sign in with Xero',
          forgotPassword: 'Forgot password',
        },
        // Registration
        registration: {
          head: 'Fiind | Register',
          register: "Register",
          registerHeader: "Let's create your account",
          registerMessage: "Signing up for Fiind is fast and free.\nNo commitments or long-term contracts.",
          iHaveAnAccount: 'I have an account',
          passwordMustMatch: 'Password must match',
          tncCheckboxRequired: 'This field must be checked',
          iHaveRead: 'I have read the',
          tnc: 'Terms and Conditions',
          signUpWithXero: 'Sign up with Xero',
        },
        // Forgot Password
        forgotPassword: {
          head: 'Fiind | Password Recovery',
          passwordRecovery: 'Password Recovery',
          tellusYourEmail:
            'Enter your email to receive password reset instruction.',
          forgotPasswordSuccessMessage:
            'Please check your email for the password reset instructions.',
          forgotPasswordFailedMessage:
            'Email not found. Please verify your email is correct.',
          recoverPassword: 'Recover Password',
        },

        // Reset Password
        resetPassword: {
          head: 'Fiind | Password Reset',
          passwordReset: 'Password Reset',
          resetYourAccountPassword:
            'Reset your account password using your code',
          resetPasswordSuccessMessage:
            'Successfully reset password. You will be redirected to sign in.',
          resetPasswordFailedMessage:
            'Oops. Something went wrong. Please try again later.',
        },

        verifyEmail: {
          head: 'Fiind | Verify Email',
          verifyCode: 'Verify Your Email',
          verifyCodeMessage:
            'A verification code has been sent to your registered email',
          verify: 'Verify',
          resendVerificationCode: 'Resend verification code',
          skipForNow: 'Skip for now',
          verificationCode: 'Verification code',
          resendOTPSuccessMessage:
            'New verification code sent to registered email',
          verifyReminder: {
            subtitle: 'Verify your email and enable 2FA to secure your account',
            title: 'Verify your email',
            buttonText: 'Verify email',
          },
        },
        _2FA: {
          head: 'Fiind | Two-Factor Authentication',
          verifyCode: 'Two-Factor Authentication',
          verifyCodeMessage:
            "Your account is protected with two-factor authentication. We've sent you an email with an one-time-password. Please enter the code below",
          verify: 'Continue',
        },
      },

      // Account Popover
      accountPopover: {
        settings: 'Settings',
        changeOrganization: 'Change organization',
        logout: 'Logout',
        addCompany: 'Add new company',
      },
      organizationPopover: {
        search: 'Search company',
      },

      // Accept team invitation
      teamInvitation: {
        head: 'Accept Invitation',
        acceptInvitation: 'Accept Invitation',
        acceptInvitationMessage: 'Create A New Fiind Account',
        acceptInvitationMessage2: 'Enter your email and password',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phoneNo: 'Phone Number',
        countryCode: 'Country Code',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        iHaveRead: 'I have read the',
        tnc: 'Terms and Conditions',
        firstNameIsRequired: 'First Name is required',
        lastNameIsRequired: 'LastName is required',
        emailIsRequired: 'email is required',
        phoneNoIsRequired: 'Phone Number is required',
        countryCodeIsRequired: 'Country Code is required',
        passwordIsRequired: 'Password is required',
        passwordMustMatch: 'Passwords must match',
        tncCheckboxRequired: 'This field must be checked',
        submitSuccessMessage: 'invitation has been accepted succecfully',
      },

      // Server Error - 500
      errorServerError: 'Error: Server Error',
      internalServerError: '500: Internal Server Error',
      tryUsingTheNavigation:
        'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
      backToDashboard: 'Back to Dashboard',

      // Reports
      reports: {
        welcome: 'Welcome to Fiind!',
        welcomeDescription:
          'Tell us a little more about your business to activate your account.',
        startOnboardingButton: 'Get Started',
        hello: 'Hello',
        financialMissionControl: 'Financial Mission Control',
        period: 'Period',
        last3Months: 'Last 3 Months',
        last6Months: 'Last 6 Months',
        thisYear: 'This Year',
        lastYear: 'Last Year',
        revenueProfitChart: {
          tooltip: 'These are your revenue and profits',
          title: 'Income',
        },
        generationPage: {
          date: 'Date of issue',
          perview: 'Preview',
          download: 'Download',
          compareWith: 'Compare with',
          comparePeriod: 'Compare period',
          dateRange: 'Date range',
          startDate: 'Start date',
          endDate: 'End Date',
          generateButton: 'Generate',
          duration: 'Days',
          repetitionNumber: 'Days Frequency',
          selectUsers: 'Select users',
          days: 'Days',
          durationMessage: 'This report will be generated automatically every',
          reminderPreference: 'Reminder Preference',
          reportGeneration: 'Report Generation',
          skip: 'Only generate',
          save: 'Save',
          additionalConfig: 'Additional Config',
          once: 'Once',
          twice: 'Twice',
          for: 'For',
          times: 'Times',

        },
        pastReports: {
          type: 'Type',
          period: 'Period',
          lastDownloadAt: 'Last Download At',
        },
        reports: 'Reports',
        myReports: 'My Reports',
        generate: 'Generate',
        revenue: 'Revenue',
        expenses: 'Expenses',
        expensesTitle: 'Total Expenses',
        messages: 'Messages',
        costOfSales: 'Cost Of Sales',
        grossProfit: 'Gross Profit',
        grossProfitMargin: 'Gross Profit Margin',
        netProfit: 'Net Profit',
        netProfitMargin: 'Net Profit Margin',
        receivables: 'Receivables',
        payables: 'Payables',
        cashInflow: 'Cash Inflow',
        cashOutflow: 'Cash Outflow',
        currentAssets: 'Current Assets',
        currentLiabilities: 'Current Liabilities',
        hashIndex: '#',
        name: 'Name',
        amountData: 'Amount',
        bankBalances: 'Bank Balances',
        creditCardsBalances: 'Credit Cards Balances',
        sinceLastMonth: 'Since Last Month',
        ratioData: ' 10%',

        companyDetails: 'Company Details',
        billing: 'Billing',
        changePassword: 'Change Password',
        connections: 'Connections',
        termsOfService: 'Terms of Service',
        privacyPolicy: 'Privacy Policy',
        //Banner
        titleCompletePayment: 'Complete your payment now',
        subtextCompletePayment:
          'Provide your credit card billing information to continue using Fiind.',
        buttonTextCompletePayment: 'Add billing info',
        titleOnboardingCall: 'Book your onboarding call now!',
        subtextOnboardingCall:
          "Get introduced to your accountant. They'll get to know your business, show you how Fiind works, and gather everything they need from you.",
        buttonTextOnboardingCall: 'Book call',
        titleConnectBooks: 'Connect your books',
        subtextConnectBooks:
          "If you don't want to see dummy data for life, connect your accounting software or upload your excel sheets (any format). We will take your transactions and ledgers in any condition and make it awesome.",
        buttonTextConnectBooks: 'Connect Accounting Software',
        secondButtonTextConnectBooks: 'Upload Excel file(s)',
        titleAccountingInformation:
          'Your accounting information is being reviewed.',
        subtextAccountingInformation:
          'Thanks for sending us the documents. We will get back to you within 2 business days.',
        bankComingSoon:
          'Your bank and credit card balances will be available soon.',
        businessDocumentUploaded: {
          title: 'Your onboarding is not completed!',
          subtitle:
            'Upload your BR and Certification of Incorporation to complete your onboarding process',
          buttonText: 'Upload documents',
        },


        salesSummary: 'Sales summary',
        salesTrends: 'Sales trends',
        paymentMethods: 'Payment methods',
        itemSales: 'Item sales',
        categorySales: 'Category sales',
        discounts: 'Discounts',
        modifierSales: 'Modifier sales',
        comps: 'Comps',
        voids: 'Voids',
        taxes: 'Taxes',
        giftCards: 'Gift cards',
        cashDrawers: 'Cash drawers',
        activityLog: 'Activity Log'
      },

      // messageList
      messageList: {
        welcome: 'Hello there!',
        welcomeInfo1: 'You have',
        welcomeInfo2: 'pending items',
        noPendingItems: 'You have no pending items!',
        expenseMessage: 'Expenses that require your action',
        expenseSecondaryMessage: 'Please provide the missing information',
        reimbursementMessage: 'Expense claims that require your action',
        reimbursementSecondaryMessage: 'Please review these items',
      },

      // Onboarding
      onboarding: {
        head: 'Fiind | Onboarding',
        companyName: 'Your company name',
        companyNamePlaceholder: 'Fiind',
        companyNameErrorMessage: 'Company name cannot be blank.',

        country: 'Where are you incorporated?',
        countryPlaceholder: 'Select country',
        countryErrorMessage: 'Please select a country',

        currency: 'Default currency',
        currencyPlaceholder: 'Select currency',
        currencyErrorMessage: 'Please select a currency.',

        industry: 'What is your industry',
        industryPlaceholder: 'Select industry',
        industryErrorMessage: 'Please select an industry',

        businessRegistration: 'Business Registration',
        incorporationCertificate: 'Certificate of Incorporation',

        continue: 'Continue',
        skip: 'I’ll complete this later',

        // Personal Info
        personalInfo: 'Personal Informations',
        personalInfoHeading: 'Tell us a little about you:',
        personalInfoSubHeading:
          'Create a free SwiftBill account to get a better view of your financials.',
        yourName: 'Your name',
        firstName: 'First name',
        firstNameErrorMessage: 'First name cannot be blank.',
        lastName: 'Last name',
        lastNameErrorMessage: 'Last name cannot be blank.',
        mobileNumber: 'Mobile number',
        mobileNumberErrorMessage: 'Mobile number cannot be blank.',
        countryCode: 'Country code',
        countryCodeErrorMessage: 'Country code cannot be blank.',

        // Business Info
        businessStatus: 'Business Details',
        existingBusinessDescription:
          'You will need your Business registration and Certificate of incorporation to proceed',
        existingBusinessTitle: 'I have a business',
        newBusinessDescription: 'Incorporate your company with SwiftBill',
        newBusinessTitle: 'I don’t have a business',
        businessInfo: 'Business Informations',
        businessInfoHeading: "What's your business status:",
        businessInfoSubHeading:
          'Let us know if your company is formed already.',
        businessInfoHeading2: 'Tell us about your business:',
        businessInfoSubHeading2:
          'We would love to learn more about your business so we could support you better.',

        // Documents
        uploadDocument: 'Upload Documents',
        uploadDocumentHeading: 'Business Documents:',
        uploadDocumentSubHeading:
          'Upload official documents to verify your business.',
        uploadDocumentBusinessReg: 'Business Registration',
        uploadDocumentIncCertificate: 'Certificate of Incorporation',

        // Partner
        partnerStatus: 'Partner Status',
        partnerStatusErrorMessage: 'This filed must be selected',
        haveAccountant: 'Are you currently working with an accountant?',
        haveAuditor: 'Are you currently working with an auditor?',
        haveTaxAgent: 'Are you currently working with a tax agent?',

        // Onboarding completed
        onboardingCompletedHeading: 'All done!',
        accountIsReady: 'Your account is ready',
        backToReports: 'Back to Reports',

        // Complete
        complete: 'Complete',
      },

      expenses: {
        head: 'SwiftBill | Expenses',
        yourExpenses: 'Expenses',
        searchExpenses: "Type a merchant's name...",
        uploadNew: 'New Expense',
        validInvoiceNumber: 'Invoice number is available',
        invalidInvoiceNumber: 'Invoice number is in use',
        all: 'All',
        waitMessage: 'Please wait...',
        processing: 'Processing',
        pendingReview: 'Pending Review',
        pendingApproval: 'Pending Approval',
        paid: 'Paid',
        awaitingPayment: 'Awaiting Payment',
        approved: 'Approved',
        split: 'Split',
        failed: 'Failed',
        sortBy: 'Sort By',
        merchant: 'Merchant',
        amount: 'Amount',
        uploadedBy: 'Uploaded By',
        amountHelper: 'Edit the total amount by updating the line items',
        status: 'Status',
        delete: 'Delete',
        invoiceNumber: 'Invoice Number',
        dateCreated: 'Date Created',
        dateCreatedNewest: 'Date Created (newest)',
        dateCreatedOldest: 'Date Created (oldest)',
        date: 'Date',
        currency: 'Currency',
        deleteExpense: 'Delete expense',
        editExpense: 'Edit expense',
        cancel: 'Cancel',
        update: 'Update',
        duplicate: 'Duplicate',
        download: 'Download',
        addLineItem: 'Add line item',
        quantity: 'Quantity',
        total: 'Total',
        description: 'Description',
        reference: 'Reference',
        referenceInfo:
          "A Reference is used to identify a specific transaction in your books. It is an optional field so don't worry if you are unsuare about what you should put",
        lineItems: 'Line items',
        expenseUpdating: 'Updating expense...',
        expenseDuplicating: 'Duplicating expense...',
        expenseUpdated: 'Expense updated',
        expenseDuplicated: 'Expense duplicated',
        expenseUpdateFail: 'Expense cannot be updated',
        expenseFileTypeError: 'Only JPEG, PNG, HEIC or PDF files are accepted',
        expenseDeleteFail: 'Expense cannot be deleted',
        deleteExpenseSuccess: 'Deleted expense',
        deleteExpenseFailed: 'Unable to delete expense',
        vendorRequired: 'Vendor cannot be blank.',
        dateRequired: 'Date cannot be blank.',
        currencyRequired: 'Currency cannot be blank.',
        amountRequired: 'Amount cannot be blank.',
        spentOn: 'Spent On',
        descriptionMessage: 'you have no old descriptions, please type new one',
        xero: 'XERO',
        approve: 'Approve',
        account: 'Account',
        invoiceId: 'Xero Invoice ID',
        commentPlaceholder: 'Leave a comment',
        resolveThread: 'Resolve Thread',
        sendComment: 'Comment',
        actionNeeded: 'Action Needed',
        expenseResolved: 'Expense has resolved successfully',
        expenseResolvedFail: 'Faild to resolve the thread',
        expenseAddCommentFail: 'Faild to add the comment',
        expenseLineItemsMissed: 'Invoices must contain at least one item',
        quantityRequired: 'Quantity cannot be blank.',
        totalRequired: 'Total cannot be blank.',
        descriptionRequired: 'Description cannot be blank.',
        accountRequired: 'account cannot be blank.',
        downloadExpenseErrorMessage: "File doesn't exist",
        comment: 'Comments',
        thisExpenseWasAClaim: 'This expense was a claim.',
        view: 'View',
        // claimDetails:
        //   'Originally submitted by <1>{{submitter}}</1> on <2>{{createdAt}}</2>. Paid via <3>{{paymentMethod}}</3> for an amount of <4>{{currency}}</4> <5>{{claimAmount}}</5>. Approved on <6>{{approvedDate}}</6> by <7>{{approvers}}</7>.',
        claimDetails:
          'Originally submitted by {{submitter}} on {{createdAt}}. Paid via {{paymentMethod}} for an amount of {{currency}} {{claimAmount}}. Approved on {{approvedDate}} by {{approvers}}.',
      },

      // Reimbursements
      reimbursements: {
        head: 'SwiftBill | Reimbursements',
        title: 'Reimbursements',
        name: 'Name',
        amount: 'Amount',
        user: 'Submitted by',
        approver: 'Approver(s)',
        expenseDate: 'Expense Date',
        submittedDate: 'Submitted Date',
        approvedOn: 'Approved on',
        rejectedOn: 'Rejected on',
        pendingApprovalfrom: 'Pending approval from',
        createdOn: 'Created on',
        updatedOn: 'Updated on',
        receipt: 'Receipt',
        memo: 'Memo',
        paymentType: 'Payment Type',
        paymentTypePlaceholder: 'How was this paid?',
        approverPlaceholder: 'Assign an approver(s)',
        createReimbursement: 'New Reimbursement',
        paymentTypeRequired: 'payment type is required',
        approverRequired: 'you must assign an approver',
        details: 'Details',
        history: 'History',
        comments: 'Comments',
        uploadFile: 'Upload file',
        action: 'Action',
        approvalStatus: 'Approval Status',
        delete: 'Delete',
        create: 'Create',
        cancel: 'Cancel',
        submit: 'Submit',
        close: 'Closed',
        reasonInputLabel: 'Your reason for rejection',
        rejectionReason: 'What is your reason for rejection?',
        reasonRequiredMessage: 'Please type a reason',
        edit: 'Edit',
        spendDate: 'Spend Date',
        marchantName: 'Merchant Name',
        paidVia: 'Paid via',
        totalAmount: 'Total Amount',
        claimAmount: 'Claim Amount',
        uploader: 'Uploader',
        uploadDate: 'Upload Date',
        saveChanges: 'Save Changes',
        editing: 'Editing',
        id: 'ID',
        customerName: 'Customer Namer',
        change: 'change',
        searchReimbursements: 'Start typing to search',
        uploadNew: 'New Reimbursement',
        sync: 'Sync',
        validInvoiceNumber: 'Invoice number is available',
        invalidInvoiceNumber: 'Invoice number is in use',
        all: 'All',
        processing: 'Processing',
        pendingReview: 'Pending Review',
        completed: 'Completed',
        approved: 'Approved',
        rejected: 'Rejected',
        pendingApproval: 'Pending Approval',
        reject: 'Reject',
        failed: 'Failed',
        sortBy: 'Sort By',
        merchant: 'Merchant',
        uploadedBy: 'Uploaded By',
        amountHelper: 'Edit the total amount by updating the line items',
        status: 'Status',
        invoiceNumber: 'Invoice Number',
        dateCreated: 'Date Created',
        dateCreatedNewest: 'Date Created (newest)',
        dateCreatedOldest: 'Date Created (oldest)',
        date: 'Date',
        currency: 'Currency',
        deleteReimbursement: 'Delete Reimbursement',
        editReimbursement: 'Edit Reimbursement',
        update: 'Update',
        addLineItem: 'Add line item',
        quantity: 'Quantity',
        total: 'Total',
        description: 'Description',
        reference: 'Reference',
        referenceInfo:
          "A Reference is used to identify a specific transaction in your books. It is an optional field so don't worry if you are unsuare about what you should put",
        lineItems: 'Line items',
        reimbursementUpdating: 'Updating reimbursement...',
        reimbursementSyncing: 'Syncing reimbursement...',
        reimbursementUpdated: 'Reimbursement updated',
        reimbursementSynced: 'Reimbursement synced',
        reimbursementUpdateFail: 'Reimbursement cannot be updated',
        reimbursementFileTypeError:
          'Only JPEG, PNG, HEIC or PDF files are accepted',
        reimbursementDeleteFail: 'Reimbursement cannot be deleted',
        deleteReimbursementSuccess: 'Deleted reimbursement',
        deleteReimbursementFailed: 'Unable to delete reimbursement',
        syncReimbursementFailed: 'Unable to sync reimbursement',
        vendorRequired: 'Vendor cannot be blank.',
        dateRequired: 'Date cannot be blank.',
        currencyRequired: 'Currency cannot be blank.',
        amountRequired: 'Amount cannot be blank.',
        spentOn: 'Spent On',
        descriptionMessage: 'you have no old descriptions, please type new one',
        xero: 'XERO',
        approve: 'Approve',
        ready: 'Ready',
        makeReady: 'Make Ready',
        account: 'Account',
        invoiceId: 'Xero Invoice ID',
        processingLoading: 'Processing...',
        processed: 'Processed!',
        processeFaild: 'Faild To Process',
        approvingLoading: 'Approving Expense...',
        approveFaild: 'Faild To Approve',
        rejectingLoading: 'Rejecting Expense...',
        rejectFaild: 'Faild To Reject',
        reimbursementLineItemsMissed: 'Invoices must contain at least one item',
        quantityRequired: 'Quantity cannot be blank.',
        totalRequired: 'Total cannot be blank.',
        descriptionRequired: 'Description cannot be blank.',
        accountRequired: 'account cannot be blank.',
        detailsPage: {
          head: 'Reimbursement Details',
        },
        rejectedBy: 'Rejected by {{name}} on {{date}}',
        reason: 'Reason: ',
      },

      //settings
      settings: {
        save: 'Save',
        accountDetails: 'Account Details',
        companyDetails: 'Company Details',
        billing: 'Billing',
        team: 'Team',
        inviteTeam: 'Invite Members',
        member: 'Member',
        inviteMembersTypography: 'You currently pay for 2 Editor Seats.',
        inviteUserEmailLabel: 'Email Address',
        inviteUserEmailPlaceholder: 'Add Email Address To Invite To Your Team',
        roleLabel: 'Role',
        role: 'Role',
        changeRole: 'Change Role',
        roleOptionsOwner: 'Owner',
        roleOptionsManger: 'Manager',
        roleOptionsTeamMember: 'Team Member',
        roleOptionsRemoveMember: 'Remove',
        updateMemberRole: 'Member role has been updated successfully',
        updateMemberRoleFaild: 'can not update member role',
        deleteMember: 'Member has been deleted successfully',
        deleteMemberFaild: 'can not remove member',
        roleIsRequired: 'must provide a role',
        sendInvite: 'Send Invite',
        changePasswordTab: 'Change Password',
        connections: 'Connections',
        termsOfService: 'Terms of Service',
        privacyPolicy: 'Privacy Policy',
        myAccount: 'My Account',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        phoneNumber: 'Phone Number',
        oldPassword: 'Old Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        companyName: 'Your company name',
        manageYourConnections: 'Manage Your Connections',
        fieldRequired: 'This is a required field',
        //settings
        accountDetailsUpdated: 'Account details updated',
        accountDetailsUpdateFailed: 'Can not update Account details',
        invitationSent: 'Invitation sent successfully',
        invitationSentFailed: 'Can not send invitation',
        changePassword: {
          passwordMustMatch: 'Passwords must match',
          must8characterLong: 'Must be at least 7 characters',
          oldPasswordRequired: 'Old password required',
          newPasswordRequired: 'New password required',
          confirmPasswordRequired: 'Confirm password required',
          passwordUpdated: 'Password updated',
          passwordUpdateFailed: 'Password update failed',
        },
      },

      // Documents
      documents: {
        head: 'SwiftBill | Documents',
        heading: 'Documents',
        addFiles: 'Add Files',
        deleteDocumentSuccess: 'Deleted document',
        deleteDocumentFailed: 'Unable to delete document',
        searchDocuments: "Type a document's name...",

        noFiltersApplied: 'No filters applied',
        fileName: 'File Name',
        uploadedAt: 'Uploaded At',
        actions: 'Actions',
        download: 'Download',
        tags: 'Tags',
        drawer: {
          selectingStep: {
            title: 'What are you uploading?',
            limitError: '"Please select at least one file type"',
            actions: {
              cancel: 'Cancel',
              next: 'Next',
            },
          },
          uploadStep: {
            title: 'Upload your files',
            limitError: 'Please upload at least one document',
            actions: {
              back: 'Back',
              upload: 'Upload',
            },
          },
        },
      },

      // Documents
      devices: {
        head: 'Fiind | Devices',
        heading: 'Devices',
        subHeading: 'Devices help you to track all your devices.',
        createDevice: 'Create a device',
        deleteDeviceSuccess: 'Deleted device',
        deleteDeviceFailed: 'Unable to delete device',
        assignItems: 'Assign Items',
        rename: 'Rename',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteDevice: 'Delete device',
        createDeviceSuccess: 'device created Successfully',
        deviceName: "device Name",
        deviceNameRequired: 'device Name is required',

      },

      // Documents
      transactions: {
        head: 'SwiftBill | Transactions',
        heading: 'Transactions',
        subHeading: 'Devices help you to track all your devices.',
        createDevice: 'Create a device',
        deleteDeviceSuccess: 'Deleted device',
        deleteDeviceFailed: 'Unable to delete device',
        assignItems: 'Assign Items',
        rename: 'Rename',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteDevice: 'Delete device',
        createDeviceSuccess: 'device created Successfully',
        deviceName: "device Name",
        deviceNameRequired: 'device Name is required',

      },
      // Documents
      categories: {
        head: 'Fiind | Categories',
        heading: 'Categories',
        subHeading: 'Categories help you arrange and organise your items, report on item sales and route items to specific printers.',
        createCategory: 'Create a Category',
        deleteCategorySuccess: 'Deleted category',
        deleteCategoryFailed: 'Unable to delete category',
        assignItems: 'Assign Items',
        rename: 'Rename',
        edit: 'Edit',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteCategory: 'Delete Category',
        createCategorySuccess: 'Category created Successfully',
        createCategoryFail: 'Failed to create Category',

        updateCategorySuccess: 'Category updated Successfully',
        updateCategoryFail: 'Failed to update Category',

        categoryName: "Category Name",
        categoryTitle: "Category Title",
        categoryNameRequired: 'Category Name is required',
        categoryTitleRequired: 'Category Title is required',

      },

      itemLibrary: {
        head: 'SwiftBill | Categories',
        heading: 'Categories',
        subHeading: 'Categories help you arrange and organise your items, report on item sales and route items to specific printers.',
        createItem: 'Create an Item',
        deleteCategorySuccess: 'Deleted category',
        deleteCategoryFailed: 'Unable to delete category',
        assignItems: 'Assign Items',
        rename: 'Rename',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteCategory: 'Delete Category',
        createCategorySuccess: 'Category created Successfully',
        categoryName: "Category Name",
        categoryNameRequired: 'Category Name is required',

      },
      discounts: {
        head: 'SwiftBill | Discounts',
        createDiscount: 'Create a Discount',
        deleteDiscountSuccess: 'Deleted Discount',
        deleteDiscountFailed: 'Unable to delete Discount',
        rename: 'Rename',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteDiscount: 'Delete Discount',
        createDiscountSuccess: 'Discount created Successfully',
        discountName: "Discount Name",
        dategoryNameRequired: 'Discount Name is required',

      },

      units: {
        head: 'SwiftBill | Units',
        heading: 'Units',
        subHeading: 'Units are used to specify how you want to measure your items.',
        createUnit: 'Add a Unit',
        editUnit: 'Edit Unit',
        deleteUnitSuccess: 'Unit Deleted',
        deleteUnitFailed: 'Unable to delete unit',
        assignItems: 'Assign Items',
        rename: 'Rename',
        delete: 'Delete',
        cancel: 'Cancel',
        deleteUnit: 'Delete Unit',
        createUnitSuccess: 'Unit created Successfully',
        createUnitFailed: 'Failed to create Unit!',
        updateUnitSuccess: 'Unit updated Successfully',
        updateUnitFailed: 'Failed to update Unit!',
        unitName: 'Unit Name',
        abbreviation: 'Abbreviation',
        precision: 'Precision',
        unitNameRequired: 'Unit Name is required',
        abbreviationRequired: 'Abbreviation is required',

      },

      settingCompReason: {
        createCompReason: 'Create Comp Reason',
        compReasonCreateSuccess: 'Comp Reason Createted Successfully',
        compReasonUnableToCreate: 'Unable To Create Comp Reason',
        compReasonUpdateSuccess: 'Comp Reason Updated Successfully',
        compReasonUnableToUpdate: 'Unable To Update Comp Reason',
        compReasonDeleteSuccess: 'Comp Reason Deleted Successfully',
        compReasonUnableToDelete: 'Unable To Delete Comp Reason',
      },

      settingVoidReason: {
        createVoidReason: 'Create Void Reason',
        voidReasonCreateSuccess: 'Void Reason Createted Successfully',
        voidReasonUnableToCreate: 'Unable To Create Void Reason',
        voidReasonUpdateSuccess: 'Void Reason Updated Successfully',
        voidReasonUnableToUpdate: 'Unable To Update Void Reason',
        voidReasonDeleteSuccess: 'Void Reason Deleted Successfully',
        voidReasonUnableToDelete: 'Unable To Delete Void Reason',
      },

      chartOfAccounts: {
        head: 'Chart of Accounts',
        searchChartOfAccounts: 'Search by accounts name',
        noFiltersApplied: 'No filters applied',
        types: 'Types',
        actions: 'Actions',
        code: 'Code',
        name: 'Name',
        type: 'Type',
        class: 'Class',
        heading: 'Chart of Accounts',
        refresh: 'Refresh',
        createAccount: 'Create account',
      },

      diningOption: {
        createDiningOptions: 'Create Dining Options',
        createDiningOptionSuccess: 'Dining Option Created Successfully',
        createDiningOptionFailed: 'Unable To Create Dining Option',
        diningOptionUpdateSuccess: 'Dining Option Updated Successfully',
        diningOptionUpdateFiled: 'Failed To Update Dining Option',
        diningOptionDeleteSuccess: 'Dining Option Deleted Successfully',
        diningOptionUnableToDelete: 'Unable To Delete Dining Option',
        selectInputLabel: 'Location'
      },

      // Sales
      sales: {
        head: 'SwiftBill | Sales',
        heading: 'Sales',
        addFiles: 'Add Files',
        name: 'Name',
        type: 'Type',
        status: 'Status',
        uploadedOn: 'Uploaded On',
        action: 'Action',
        postSelected: 'Post Selected',
        cancelButton: 'Cancel',
        uploadButton: 'Upload',
        details: {
          head: 'Sales Report Details',
          title: 'Sales Reports',
          details: 'Details',
          uploadedOn: 'Uploaded on',
          postedOn: 'Posted on',
          approveAndPost: 'Approve & Post',
          alreadyPosted: 'Already Posted',
          contactName: 'Contact Name',
          invoiceNumber: 'Invoice Number',
          reference: 'Reference',
          invoiceDate: 'Invoice Date',
          dueDate: 'Due Date',
          description: 'Description',
          quantity: 'Quantity',
          unitAmount: 'Unit Amount',
          aaccountCode: 'Account Code',
          taxType: 'Tax Type',
          currency: 'Currency',
          postReportLoading: 'Posting report',
          postReportSuccess: 'Posted report',
        },
      },

      // Pricing
      pricing: {
        monthly: '/mo',
        yearly: '/yr',
        talkToUs: 'Talk To Us',
        heading: 'Packages that grow with you',
        subheading:
          'No onboarding fees, no hidden fees. What you see is what you get.',
        discount: '10% OFF',
        yearlyPayment: 'Annual Billing',
        monthlyPayment: 'Monthly Payment',
        redirecting: 'Redirecting you to payments...',
        starter: {
          name: 'Starter',
          subheading: 'Monthly Revenues under HK$80k',
        },
        growth: {
          name: 'Growth',
          subheading: 'Monthly Revenues over HK$80k',
        },
        venture: {
          name: 'Venture',
          price: 'Custom',
          subheading: 'Digitise your finance department',
        },
        cta: 'Get This Plan',
        features: {
          accountantAccess: '24/7 accountant access',
          monthlySubscriptionToXero: 'Monthly subscription to Xero™',
          unlimitedTransactions: 'Unlimited transactions',
          aiProcessingInvoices: 'AI processing for invoices',
          aiProcessingReceipts: 'AI processing for receipts',
          airWallex: 'Airwallex™ account included',
          monthlyFinancialReports: 'Monthly financial reports',
          monthlyCFOReviews: 'Monthly CFO reviews',
          governmentFormsHandling: 'Government forms handling',
          prioritySupport: 'Priority Support',
          annualAudit: 'Annual Audit',
          includesEverythingInGrowth: 'Includes everything in Growth',
          customApiSetup: 'Custom API setup',
          programmeRollout: 'Custom programme rollout',
          customBusinessMetrics: 'Custom business metrics',
          billingManagement: 'Billing management',
          apManagement: 'AP management',
          arManagement: 'AR management',
          dedicatedAccount: 'Dedicated SwiftBill accountant',
          customerSuccessManager: 'Customer Success Manager',
        },
        couponDialogue: {
          title: 'Purchase Plan',
          couponCodePlaceholder: 'Enter coupon code',
          couponApplySuccess: 'Coupon Applied.',
          couponApplyError: 'Coupon not found.',
          couponApplyFaile: 'Price is less than the discount',
        },
      },

      '404': {
        title: 'Error: Not Found',
        heading: '404: The page you are looking for isn’t here',
        subheading:
          'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
        backToHome: 'Back to Home',
      },

      '401': {
        title: 'Error: Authorization Required',
        heading: '401: Authorization required',
        subheading:
          'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
        backToHome: 'Back to Home',
      },

      nav: {
        overview: 'Overview',
        home: 'Home',
        documents: 'Documents',
        // expenses: 'Expenses',
        itemsAndOrders: 'Items & Orders',
        dashboard: 'Dashboard',
        devices: 'Devices',
        online: 'Online',
        transaction: 'Transactions',
        sales: 'Sales',
        management: 'Management',
        chartOfAccounts: 'Chart of Accounts',
        // allExpenses: 'All expenses',
        // reimbursements: 'Reimbursements',
        menus: 'Menus',
        items: 'Items',
        orders: 'Orders',
      },

      itemsNav: {
        itemLibrary: 'Item Library',
        channelListing: 'Channel Listings',
        modifiers: 'Modifiers',
        categories: 'Categories',
        discounts: 'Discounts',
        salesTax: 'Sales Tax',
        options: 'Options',
        units: 'Units',
        customAttributes: 'Custom Attributes',
        settings: 'Settings',
      },

      // Utils
      shared: {
        continue: 'Continue',
        uploadFilesHeading: 'Click or Drag file to this area to upload',
        uploadFilesSubHeading:
          'Supported file types: \n PDF, JPG, JPEG, PNG, HEIC, DOCX, XLSX, CSV',
        maxFileSize: 'Max file size: 5MB',
        fileCannotBlank: 'Please select a file',
        language: 'Language',
        download: 'Download',
        delete: 'Delete',
        rowsPerPage: 'Rows per page:',
      },



      BusinessNotFound: 'Business not found!',
      LookForOtherBusiness: 'Look for other business',
      SelectService: 'Select service',
      ServiceSelected: 'Service selected',
      Next: 'Next',
      BackToService: 'Back to service',
      SelectStaffMember: 'Select staff member',
      NoStaffFound: 'No staff found',
      SelectTimeDate: 'Select time & date',
      AvailableTimes: 'Available times',
      Information: 'Information',
      Verified: 'Verified',
      YourDetails: 'Your details',
      Name: 'Name',
      EmailAddress: 'Email address',
      Address: 'Address',
      IHaveReadAndAcceptedThe: 'I have read and accepted the',
      conditions: 'conditions',
      IWishToReceiveRelevantInformationViaEMailAndSMS: 'I wish to receive relevant information via e-mail and SMS',
      CancellationPolicy: 'Cancellation policy',
      SeeCondition: 'See condition',
      WeHaveSentYourActivationCodeTo: 'We have sent your activation code to',
      pleaseInsertItBelow: ', please insert it below',
      GoBack: 'Go back',
      CompleteBooking: 'Complete booking',
      Service: 'Service',
      Staff: 'Staff',
      CustomerName: 'Customer name',
      TotalAmount: 'Total amount',
      ConfirmBooking: 'Confirm booking',
      WelcomeTo: 'Welcome to',
      IfThereAreAnyQuestionsPleaseContactMeAt: 'If there are any questions, please contact me at:',
      Terms: 'Terms',
      IAcceptConditions: 'I accept conditions',
      AppointmentConfirmedWith: 'Appointment confirmed with',
      BookAnotherApointment: 'Book another appointment',
      BookingFailed: 'Booking failed',
      BookingConfirmed: 'Booking confirmed',
      SelectedSlotDoesNotMatchAnyAvailableTimeSlots: 'Selected slot does not match any available time slots',
      PleaseSelectAValidTimeSlot: 'Please select a valid time slot',
      FailedToVerifyOTP: 'Failed to verify OTP',
      OTPVerified: 'OTP verified',
      FailedToSentOTP: 'Failed to sent OTP',
      OTPSentToYourMobileNumber: 'OTP sent to your mobile number',
      EnterAVlidEmailAddress: 'Enter a valid email address',
      Select: 'Select',


      // stores page
      Standard: 'Standard',
      offertonewclints: 'offer to new clints',
      Consulation: 'Consultation',
      Bundleoffers: 'Bundle offers',
      NoclinicsfoundPleasetryadifferentsearch: 'No clinics found. Please try a different search.',
      Found1clinic: 'Found 1 clinic,',
      Searchamong: 'Search among',
      clinics: 'clinics',
      Noservicesfound: 'No services found',
      Found1service: 'Found 1 service,',
      and: 'and + ',
      services: 'services',
      Searchforaclinicorservice: 'Search for a clinic or service...',
      Loading: 'Loading...',
      Nosuggestionsfound: 'No suggestions found.',
      Services: 'Services:',
      Outlets: 'Clinics:',
      Nostoresfound: 'No stores found',
      Extendedsearch: 'Extended search',
      Filtercategories: ' Filter categories',
      Search: ' Search',
      Searchforservice: 'Search for service...',
      AllServices: 'All Services',
      Filterservices: 'Filter services',
      Beautycosmetics: 'Beauty & cosmetics',
      Price: ' Price',
      From: 'From',
      To: ' To',
      Within: 'Within',
      SelectDistance: 'Select Distance',
      Searchtype: 'Search type',
      Showclinics: 'Show clinics',
      searchAmongTitle: 'Search among 573 clinics',
      searchAmoungSubTitle: 'and +30.000 services',

      //clinic services  tab (tabPanel)
      Clinic: 'Clinic',
      Servicees: 'Service',
      Yoursearchfound49places: 'Your search found 49 places',
      Allshownplacesofferfortreatmentyousearchfor: 'All shown places offer for treatment you search for',
      Choosesearch: 'Choose search',
      Distance: 'Distance',
      Pricelowtohigh: 'Price (low to high)',
      pricehightolow: 'price (high to low)',

      // storeInfo 
      Close: 'Closed',
      Open: 'Open',

      // storeCard
      NoCategories: 'No Categories',
      Visitclinic: 'Visit clinic',
      upto: 'Up to',

      // stepper
      Serviceee: 'Service',
      Employees: 'Employee',
      Time: 'Time',
      Information: 'Information',
      Finished: 'Finished',

      // Services
      Backtocategories: 'Back to categories',
      SelectService: 'Select Service',

      // serviceCardAni
      at: 'At',
      storeName: 'Store Name',
      storeaddresswillsethere: 'store address will set here',
      Bookservice: 'Book service',

      // ServiceCard
      Expireson: 'Expires in',
      ShowLess: 'Show Less',
      ReadMore: 'Read More',

      // otpverification
      Backtoinfo: 'Back to info',
      Approve: 'Approve',

      // Navigator
      Booking: 'Booking',
      AboutUs: 'About Us',
      Pictures: 'Pictures',
      Reviews: 'Reviews',

      // maps
      Allowlocationservices: 'Allow location services',

      // Information
      Backtotime: 'Back to time',
      Information: 'Information',
      Firstname: 'First name',
      Lastname: 'Last name',
      Address: 'Address',
      ZipCode: 'Zip Code',
      City: 'City',
      Phonenr: 'Phone nr',
      Email: 'Email',

      // hours
      OpeningHours: 'Opening hours',
      Closed: 'Closed',

      // header
      Reviews: 'Reviews',
      Review: 'Review',

      // employees
      Backtoservices: 'Back to services',
      SelectEmployee: 'Select Employee',
      Noemployeesavailablefortheselectedservice: 'No employees available for the selected service',

      // Policy
      Terms: 'Terms',

      // completeBooking
      Bookingdetails: ' Booking details',
      Service: 'Service:',
      Employee: 'Employee:',
      Bookingtime: 'Booking time:',
      Totalamount: 'Total amount:',
      Confirmbooking: 'Confirm booking',

      // category
      SelectCategory: 'Select Category',
      SpecialOffer: 'Special Offer',
      CancellationOffers: 'Cancellation Offers',

      //calendar
      Backtoemployee: 'Back to employee',
      Selectdateandtime: 'Select date and time',
      clicnic_is_closed: "Clinic is closed on this day",

      // about us
      ContactInformation: 'Contact Information',
      AboutUs: 'About Us',

      //bookingconfirmed
      thankyouforyourbooking: 'Thank you for booking!',

      // Home screen
      // tab panel component
      heading1: 'All shown places offer for treatment you search for',
      chooseSearch: 'Choose search',
      heading2: 'Your search found',
      heading2LastPart: 'places',
      distance: 'Distance',
      priceLtH: 'Price (low to high)',
      priceHtL: 'Price (high to low)',
      services: 'services',
      searchAmongTitle: 'Search among 573 clinics',
      searchAmoungSubTitle: 'and +30.000 services',
      // cards
      upto: 'Up to',
      discount: 'discount',
      visitClinic: 'Visit clinic',
      offers: 'offers',
      offer: 'offer',

      at: 'at ',
      bookService: 'Book service',
      viewCancellationOffer: 'View cancellation offer',

      // moadal
      showClinics: 'Show clinics',

      otherBookingSystem: 'Prices and services may not be correct, since the clinic isn’t a Fiind Partner',
      otherBookingSystempopup: 'Sorry, this clinic is not a partner of Fiind!',
      otherBookingSystempopupMessage: 'To make a booking at this clinic, please click the button below',
      redirectMessage: 'Redirect me to their booking page',
      policies: 'Policies',
      iAcceptPolicies: 'I accept policies',
      bookAnother: 'Book another appointment',

      chooseSort: 'Choose sort',
      searchWithin: 'Search within',
      sortBy: 'Sort by'

    },
  },


};

const getLanguage = () => {
  // return localStorage.getItem('webbooking-lang') || 'en';
  return 'en';
};

i18n.use(initReactI18next).init({
  resources,
  lng: getLanguage(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
