import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Grid2 } from '@mui/material';
import SkeletonCard from '../components/skeletonCard';
import theme from '../theme';


const TimeSlotsComponent = ({ schedules,availableSlotTimeList, handleSlotSelect, isLoading ,bookingDetails}) => {
  const [scheduleData, setScheduleData] = useState([]);
  const [selectedAvailableSlot, setSelectedAvailableSlot] = useState(null);

  useEffect(()=>{
    setSelectedAvailableSlot(bookingDetails?.selectedSlot)
  },[bookingDetails])
  
  const parseSchedules = (schedules) => {
    const timeSlots = [];

    schedules.forEach((timeRange) => {
      const [startTime] = timeRange.split(' - ');
      const hour = parseInt(startTime.split(':')[0], 10);
      // let slotTitle = hour < 6 ? 'Night' : hour < 12 ? 'Morning' : hour < 16 ? 'Afternoon' : 'Evening';
      let slotTitle = hour;

      let slot = timeSlots.find((slot) => slot.title === slotTitle);
      if (!slot) {
        slot = { title: slotTitle, data: [] };
        timeSlots.push(slot);
      }
      slot.data.push(startTime);
    });

    setScheduleData(timeSlots);
  };


  

  useEffect(() => {
    if (schedules) {

      setScheduleData(schedules)
      // parseSchedules(schedules);
    }
  }, [schedules]);


  const TimeSlotsGrid=({data})=>{
    const renderTimeSlot = ({ item, index }) => {
      if (!item) return null;
      let isAvailable = true
      let isFifteenMinutes = false

      let isSelected = false
      
      
      
      if(!availableSlotTimeList.includes(item)){
        isAvailable = false
      }

      if(item.split(':')[1]%15 == 0){
        isFifteenMinutes = true
      }

      if(selectedAvailableSlot){
        // console.log('selectedAvailableSlot',selectedAvailableSlot);
        // console.log('item',item);
        
        if(selectedAvailableSlot.split(' - ')[0] == `${item}`){
          isSelected = true
        }
      }

      // console.log('availableSlotList',availableSlotList);
      

      
      return (

        <Grid2 item size = {{xs:2, md:2}} key={index} sx={{ display: 'flex', justifyContent: 'center',  fontWeight: 'bold',backgroundColor: selectedAvailableSlot === item ? '#F7BD98' : (  isAvailable ? ( isFifteenMinutes ? '#f2f2f2': 'white'): '#C7414180'), fontSize: '7px', height: {xs:50, md: 50}, borderRadius: '15px', border:`1px solid ${theme.palette.primary.divider}` }}>
        <Button

        disabled = {!isAvailable}
        onClick={() => {
          setSelectedAvailableSlot(item);
          handleSlotSelect(item);
        }}
        sx={{ color: (selectedAvailableSlot === item || !isAvailable) ? 'white' : 'black',
          "&:disabled": {
            color: 'white'
          },
          fontSize:'0.75rem'

        }}
      >
        {item}
      </Button>
      </Grid2>
        // <TouchableOpacity onPress={() => {if(isAvailable){
        //   const slotIndex = availableSlotTimeList.findIndex((slotObj)=>slotObj == item)
        //   // console.log('slotIndex',slotIndex);
        //   const selectedSlot = availableSlotList[slotIndex]


        //   // console.log('availableSlotList selected',availableSlotList[slotIndex]);
          
        //   setSelectedAvailableSlot(selectedSlot)
        // }}}
        //   style={[styles.timeSlotContainer,{backgroundColor :  isSelected ? colors.theme_blue  :(  isAvailable ? ( isFifteenMinutes ? '#f2f2f2': 'white'): '#C7414180')}  ]}>
        //   <Text style={[styles.timeSlot, { paddingVertical: 10, fontFamily: 'DMSans-Bold', color: isSelected ? 'white' : colors.black4 , fontSize:10 }]}>
        //     {item}
        //   </Text>
        // </TouchableOpacity>
      );
    };

    return (
      <Grid2 container spacing={{xs: 1, md:2 } } size={{md:12,xs:12}}>

        {
          data.map((item,index)=>renderTimeSlot({item, index}))
        }
        
   
       
      </Grid2>
    );
  }

  return (
    <Box sx={{ width: '100%', height: '300px', overflowY: 'auto',  pl:2, pr:2 , pt:{xs:1}, pb:{xs:2}, minWidth:{xs:300, md:400}}} justifyContent={'center'} alignItems={'center'}>
      {isLoading === true ? (
       <SkeletonCard />
      ) : (
        scheduleData.map((scheduleDataObj, index) => (
          <React.Fragment key={index}>
            <Typography style={{ color: 'black', marginBottom: 5, padding:10, pl : {xs:0,md:10} }}>{scheduleDataObj.title}</Typography>
            <TimeSlotsGrid data={scheduleDataObj?.data} title={scheduleDataObj?.title}/>       
            {/*  <Grid2 container spacing={1}>
             {scheduleDataObj.data.map((slot, slotIndex) => (
                <Grid2  size = {{xs:2, md:2}} width={90} key={slotIndex} sx={{ display: 'flex', justifyContent: 'center',  fontWeight: 'bold',backgroundColor: selectedAvailableSlot === slot ? '#F7BD98' : '#f2f2f2', fontSize: '7px', height: {xs:50, md: 60},  borderRadius: '15px' }}>
                  <Button
                    onClick={() => {
                      setSelectedAvailableSlot(slot);
                      handleSlotSelect(slot);
                    }}
                    sx={{ color: selectedAvailableSlot === slot ? 'white' : 'black'}}
                  >
                    {slot}
                  </Button>
                </Grid2>
              ))} 
            </Grid2>*/}
          </React.Fragment>
        ))
      )}
    </Box>
  );
};

export default TimeSlotsComponent;
