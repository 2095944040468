const config = {
  all: {
    env: process.env.REACT_APP_ENV || 'local',
    isBrowser: typeof window !== 'undefined',
    isServer: typeof window === 'undefined',
    defaultTimeZone: 'Asia/Hong_Kong',
  },
};

const parsedConfigs = {
  ...config.all,
  // apiUrl: `https://api.fiind.app/api/v1`,
  apiUrl: `https://api-pdn.fiind.app/api/v1`,
  imageBaseUrl: `https://fiind-dev.s3.amazonaws.com/`,
  calendlyConfig: {
    url: 'https://calendly.com/kenny-binery/45-minute-onboarding-call-with-kenny',
    text: 'Schedule time with me',
    color: '#00a2ff',
    textColor: '#ffffff',
    branding: true,
  },
};

export default parsedConfigs;
