import { Box, Grid2, Stack, Typography } from '@mui/material'
import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Buttons from './button';
import { formatAmount } from '../utils/format-amount';
import dayjs from 'dayjs';
import { t } from 'i18next';
import img1 from '../assets/images/Group.png'
import moment from 'moment';

function BookingConfirmed({ bookingDetails, bookAnother }) {

    console.log("booking data", bookingDetails);

    // const Date = bookingDetails?.selectedDate;
    // const selectedDate = dayjs(Date).format('DD/MM-YY');
    // const time = bookingDetails?.selectedSlot;

    // console.log('date', Date);
    // console.log('time', time);
    

    const Date = bookingDetails?.selectedDate;
    const selectedDate = dayjs(Date).format('DD/MM-YY');
    const time = bookingDetails?.selectedSlot.replace(':','.');
  

    // return (
    //     <Box sx={{ marginLeft: { xs: '-10px' }, minWidth: { xs: '100px' }, maxWidth: { md: '800px' } }}>
    //         <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center', flexDirection: 'column' }}>
    //             <Typography
    //                 sx={{ fontWeight: 'bold', color: 'primary.text', marginLeft: { xs: '-5px', md: '25px' }, textAlign: 'left', fontSize: { xs: '18px', md: '20px' } }}>
    //                 {t('thankyouforyourbooking')}
    //             </Typography>
    //             {img1 === null ? null : <Box sx={{ paddingBottom: '50px', marginLeft: { xs: '-5px', md: '25px' } }}>
    //                 <img src={img1} />
    //             </Box>}
    //         </Box>

    //         <Box sx={{
    //             display: 'flex',
    //             justifyContent: 'start',
    //             alignItems: 'center',
    //             backgroundColor: 'white',
    //             minWidth: { xs: '400px', md: '700px' },
    //             marginLeft: { xs: '-5px', md: '25px' },
    //         }}>

    //             <Grid2 gap={1} color='#545454' sx={{ display: 'flex', flexDirection: 'column' }}>
    //                 <Grid2 xs={12}>
    //                     <Typography ><strong>{t('Service')} </strong>{bookingDetails?.selectedService?.name} </Typography>
    //                 </Grid2>
    //                 <Grid2 xs={12}>
    //                     <Typography ><strong>{t('Employee')} </strong>{bookingDetails?.selectedEmployee?.name} </Typography>
    //                 </Grid2>
    //                 <Grid2 xs={12}>
    //                     <Typography ><strong>{t('Booking time')} </strong> {selectedDate} ${moment(booking_datetime_start).format('HH:mm')} - ${moment(booking_datetime_end).format('HH:mm')}</Typography>
    //                 </Grid2>
    //                 <Grid2 xs={12} sx={{ marginTop: '20px', marginBottom: '20px' }}>
    //                     <Typography ><strong>{t('Total amount')} </strong>{bookingDetails?.selectedEmployee?.id === 0 ? formatAmount(bookingDetails?.selectedService?.price, false) : formatAmount(bookingDetails?.selectedEmployee?.price, false)} </Typography>
    //                 </Grid2>

    //             </Grid2>

    //         </Box>
    //     </Box>
    // )

    return (
        <Box flex={1} sx={{pl:0, pr:0, ml:0, mr:0,mt:0}}>
      
          {/* <Typography
                    sx={{ fontWeight: 'bold', color: 'primary.text', paddingBottom: '30px',  textAlign: 'left', fontSize: { xs: '18px', md: '20px' } }}>
                    {t('Bookingdetails')}
                </Typography> */}
        <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center', flexDirection: 'column' }}>
            <Typography
                sx={{ fontWeight: 'bold', color: 'primary.text', textAlign: 'left',  fontSize:{xs:'1.1rem'} }}>
                {t('thankyouforyourbooking')}
            </Typography>
            {img1 === null ? null : <Box sx={{ paddingBottom: '50px'}}>
                <img src={img1} />
        </Box>}
        </Box>
        <Stack flex={1} flexDirection={'column'} justifyContent={'flex-start'} >
    
        
        <Typography
                sx={{ fontWeight: 'bold', color: 'primary.text', paddingBottom: '30px',  textAlign: 'left',  fontSize:{xs:'1.1rem'} }}>
                {t('Bookingdetails')}:
            </Typography>

            <Typography ><strong>{t('Service')} </strong>{bookingDetails?.serviceName} </Typography>
          
            <Typography sx={{mt:1}}><strong>{t('Employee')} </strong>{bookingDetails?.employee_name} </Typography>
          
            <Typography sx={{mt:1}}><strong>{t('Booking time')} </strong>{selectedDate} (kl. {time}) </Typography>
            <Typography sx={{mt:4, mb:4}}><strong>{t('Total amount')} </strong>{ formatAmount(bookingDetails?.amount, false)} </Typography>
            
            <Buttons variant={'contained'}  children={t('bookAnother')} onclick={() => bookAnother()} borderRadius={'12px'} fontWeight={'bold'}  width={'auto'} endIcon={<ArrowForwardIosIcon />} />
         
    
        </Stack>
      </Box>
      )
}

export default BookingConfirmed
