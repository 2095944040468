import { Box, Typography } from '@mui/material'
import React from 'react'
import EmployeeHorizontalList from './employeeHorizontalList'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Buttons from './button';
import { t } from 'i18next';

function Employee({selectedService, handleEmployeeSelect,handleBackPress ,selectedEmployeeValue}) {
  const employees = selectedService?.employees;
  

  return (
    <Box sx={{pl:0, pr:0, ml:0, mr:0,mt:0, flex:1}}>
      <Buttons variant={'outlined'} borderRadius={6} borderWidth={3} startIcon={<ArrowBackIosIcon />} onclick={handleBackPress} children={t('Backtoservices')} />
      <Typography
      variant='h6'
        sx={{ fontWeight: 'bold',  paddingBottom: '20px', mt:4, textAlign: 'left',  fontSize:{xs:'1.1rem'} }}>
        {t('SelectEmployee')}
      </Typography>

      <Box sx={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        backgroundColor: 'white',
        marginLeft: 0
      }}>
        {employees ? (
          <EmployeeHorizontalList employees={employees} onEmployeeSelect={handleEmployeeSelect} selectedEmployeeValue={selectedEmployeeValue} /> 
        ) : (
          <Typography>{t('Noemployeesavailablefortheselectedservice')}</Typography>
        )}
      </Box>
    </Box>
  )
}

export default Employee
