import { apiService } from '../services/apiServices';

class WebHomeScreenApi {

    async storeList(lat, lng, search, source, selectedCategoryId, serviceIds,
        special_offers, cancellation_offers, new_client_offer,
        consultation, bundle_offers, fromValue, toValue, kilometer) {
        return new Promise((resolve, reject) => {
            // console.log('INSIDE API ', lat, lng, search, source, selectedCategoryId, serviceIds,
            //     special_offers, cancellation_offers, new_client_offer,
            //     consultation, bundle_offers, fromValue, toValue, kilometer)
            try {
                // const resp = apiService.post(`/web/marketplace/list?lat=${22.7278848}&lng=${88.3392512}&search=${search}&search_type=${source}&category_ids=${selectedCategoryId}&service_type_ids=${serviceIds}&special_offers=${special_offers}&cancellation_offers=${cancellation_offers}&new_client_offer=${new_client_offer}&consultation=${consultation}&bundle_offers=${bundle_offers}&min_price=${fromValue}&max_price=${toValue}&distance_in_km=${kilometer}`, {});
                const resp = apiService.post(`/web/marketplace/list?lat=${lat}&lng=${lng}&search=${search}&search_type=${source}&category_ids=${selectedCategoryId}&service_type_ids=${serviceIds}&special_offers=${special_offers}&cancellation_offers=${cancellation_offers}&new_client_offer=${new_client_offer}&consultation=${consultation}&bundle_offers=${bundle_offers}&min_price=${fromValue}&max_price=${toValue}&distance_in_km=${kilometer}`, {});
                resolve(resp);
            } catch (err) {
                console.log('[Store list error]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    };

    async categoryGeneralData() {
        return new Promise((resolve, reject) => {
            try {
                const resp = apiService.get(`/web/marketplace/generalDatas`);
                resolve(resp);
            } catch (err) {
                console.log('[Confirm Booking]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    };

    async searchSuggestion(query) {
        return new Promise((resolve, reject) => {
            try {
                const resp = apiService.get(`/web/marketplace/searchSuggestions?search=${query}`);
                resolve(resp);
            } catch (err) {
                console.log('[Search suggestion]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    };
}

export const webHomeScreenApi = new WebHomeScreenApi();

// const resp = apiService.post(`/store/list?lat=23.0385473925052&lng=72.51173973083498`, {});

